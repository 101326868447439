import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from 'store/configureStore';
import {
  selectDemId,
  selectLicenseAreaId,
  selectTaskCenter,
  selectTaskId,
} from 'store/sidebarSlice';

import BuildingsConnect from './BuildingsConnect';
import BuildingsHandlers from './BuildingsHandlers';
import ControlButtons from './ControlButtons';
import DrawArea from './DrawArea';
import ExternalPoints from './ExternalPoints';
import MapContainer from './MapContainer';
import Patterns from './Patterns';
import PopupComponent from './PopupComponent';
import Ruler from './Ruler';
import ScaleButtons from './ScaleButtons';
import { IMapboxMapProps } from './types';
import useHover from './useHover';

import './style.scss';

const MapboxMap: React.FC<IMapboxMapProps> = () => {
  const onMouseMove = useHover();

  return (
    <MapContainer mapProps={{ onMouseMove }}>
      <Ruler />
      <DrawArea />
      <PopupComponent />
      <ControlButtons />
      <BuildingsHandlers />
      <ScaleButtons />
      <ExternalPoints />
      <Patterns />
      <BuildingsConnect />
    </MapContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  demId: selectDemId(state),
  taskId: selectTaskId(state),
  center: selectTaskCenter(state),
  licenseAreaId: selectLicenseAreaId(state),
});

export default connect(mapStateToProps)(MapboxMap);
