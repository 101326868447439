import React, { useEffect, useRef } from 'react';

interface Props {
  actions: { label: string; action: (e: any) => void }[];
  handleOpen: any;
}

function EditDropdown(props: Props) {
  const { actions, handleOpen } = props;
  const wrapperRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      handleOpen();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={wrapperRef}
      style={{
        position: 'absolute',
        top: 30,
        right: 10,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'rgb(29, 29, 31)',
        padding: '2px 7px 9px 7px',
        borderRadius: '4px',
        zIndex: 12,
      }}
    >
      {actions.map((item, i) => (
        <button
          key={i}
          type="button"
          onClick={item.action}
          style={{ padding: '7px 0 0 0' }}
        >
          <p style={{ color: 'rgb(204, 205, 205)', fontSize: '14px' }}>
            {item.label}
          </p>
        </button>
      ))}
      <p />
    </div>
  );
}

export default EditDropdown;
