import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IControlSlice } from './types';

const initialState: IControlSlice = {
  selectedBuildingUid: null,
  deleteTaskData: null,
  scenario: null,
  calculationErrors: null,
};

export const controlSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {
    setCalculationErrors: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        calculationErrors: action.payload,
      };
    },
    clearCalculationErrors: state => {
      return {
        ...state,
        calculationErrors: null,
      };
    },
    updateControl: (state, action: PayloadAction<IControlSlice>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearControl: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  setCalculationErrors,
  clearCalculationErrors,
  updateControl,
  clearControl,
} = controlSlice.actions;

export default controlSlice.reducer;
