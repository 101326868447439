import gql from 'graphql-tag';

export default gql`
  mutation updateCoordinates($input: UpdateExternalPointCoordinatesInput!) {
    externalPointMutation {
      updateCoordinates(input: $input) {
        recordId
      }
    }
  }
`;
