import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/configureStore';

import { IMapSlice } from './types';

export const initialState: IMapSlice = {
  projectArea: 0,
  createArea: false,
  showRuler: false,
  showGrid: false,
  showAffectedAreas: false,
  showThermalAreas: false,
  geometry: null,
  editExternalPoints: false,
  activeBuilding: null,
};

export const mapSlice = createSlice({
  initialState,
  reducers: {
    setCreateArea: (state, action: PayloadAction<boolean | null | string>) => {
      return {
        ...state,
        createArea:
          action.payload !== 'toggle' ? action.payload : !state.createArea,
      };
    },
    setGeometry: (state, action: PayloadAction<IMapSlice['geometry']>) => {
      return {
        ...state,
        geometry: action.payload,
      };
    },
    setProjectArea: (
      state,
      action: PayloadAction<IMapSlice['projectArea']>,
    ) => {
      return {
        ...state,
        projectArea: action.payload,
      };
    },
    setShowRuler: (state, action: PayloadAction<IMapSlice['showRuler']>) => {
      return {
        ...state,
        showRuler: action.payload,
      };
    },
    setEditExternalPoints: (
      state,
      action: PayloadAction<IMapSlice['editExternalPoints']>,
    ) => {
      return {
        ...state,
        editExternalPoints: action.payload,
      };
    },
    setShowGrid: (state, action: PayloadAction<IMapSlice['showGrid']>) => {
      return {
        ...state,
        showGrid: action.payload,
      };
    },
    setAffectedAreas: (
      state,
      action: PayloadAction<IMapSlice['showAffectedAreas']>,
    ) => {
      return {
        ...state,
        showAffectedAreas: action.payload,
      };
    },
    setShowThermalAreas: (
      state,
      action: PayloadAction<IMapSlice['showThermalAreas']>,
    ) => {
      return {
        ...state,
        showThermalAreas: action.payload,
      };
    },
    setActiveBuilding: (
      state,
      action: PayloadAction<IMapSlice['activeBuilding']>,
    ) => {
      return {
        ...state,
        activeBuilding: action.payload,
      };
    },
    setMapInitial: () => {
      return initialState;
    },
  },
  name: 'map',
});

export const {
  setCreateArea,
  setAffectedAreas,
  setShowGrid,
  setMapInitial,
  setProjectArea,
  setGeometry,
  setShowRuler,
  setEditExternalPoints,
  setActiveBuilding,
  setShowThermalAreas,
} = mapSlice.actions;

export const selectCreateArea = (state: RootState) => state.map.createArea;

export const selectProjectArea = (state: RootState) => state.map.projectArea;

export const selectProjectGeometry = (state: RootState) => state.map.geometry;

export const selectEditExternalPoints = (state: RootState) =>
  state.map.editExternalPoints;

export const selectShowGrid = (state: RootState) => state.map.showGrid;

export const selectShowAffectedAreas = (state: RootState) =>
  state.map.showAffectedAreas;

export const selectActiveBuilding = (state: RootState) =>
  state.map.activeBuilding;

export const selectShowThermalAreas = (state: RootState) =>
  state.map.showThermalAreas;

export default mapSlice.reducer;
