import gql from 'graphql-tag';

import { BUILDING_FRAGMENT } from './fragments/buildings';
import {
  CONNECTION_POINT_FRAGMENT,
  CONNECTION_TYPE_FRAGMENT,
} from './fragments/connections';
import { UTILITY_LINE_FRAGMENT } from './fragments/lines';

export default gql`
  mutation createConnectionBetweenPoints(
    $input: CreateConnectionBetweenConnectionPointsInput!
  ) {
    connection {
      createBetweenConnectionPoints(input: $input) {
        ok
        connection {
          id
          sourceConnectionPoint {
            ...ConnectionPointFragment
          }
          targetConnectionPoint {
            ...ConnectionPointFragment
          }
          sourceBuilding {
            ...BuildingFragment
          }
          targetBuilding {
            ...BuildingFragment
          }
          connectionType {
            ...ConnectionTypeFragment
          }
          utilityLine {
            ...UtilityLineFragment
          }
        }
      }
    }
  }
  ${BUILDING_FRAGMENT}
  ${CONNECTION_POINT_FRAGMENT}
  ${CONNECTION_TYPE_FRAGMENT}
  ${UTILITY_LINE_FRAGMENT}
`;
