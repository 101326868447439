import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as ExternalPointsIcon } from 'assets/images/connection-points.svg';
import classnames from 'classnames';

import {
  selectEditExternalPoints,
  setEditExternalPoints,
} from '../../../store/mapSlice';

const ExternalPointsButton: React.FC = () => {
  const editExternalPoints = useSelector(selectEditExternalPoints);
  const dispatch = useDispatch();

  const onClickHandler = () =>
    dispatch(setEditExternalPoints(!editExternalPoints));

  return (
    <button
      type="button"
      onClick={onClickHandler}
      className={classnames('control-button control-button_external-points', {
        active: editExternalPoints,
      })}
    >
      <ExternalPointsIcon className="control-button__icon" />
      <span className="control-button__text">Точки подключения</span>
    </button>
  );
};

export default ExternalPointsButton;
