import gql from 'graphql-tag';

export default gql`
  mutation deleteTaskCalculation($taskCalculationId: Uuid!) {
    taskCalculationMutation {
      deleteTaskCalculation(taskCalculationId: $taskCalculationId) {
        ok
      }
    }
  }
`;
