import React from 'react';

interface Props {
  disabled?: boolean;
  width?: string;
  value: number | string;
  mathValue?: any;
  mathSymbol?: any;
  handleFocus?: (e?: any) => void;
  handleBlur?: (e?: any) => void;
  handleChange: (e: any) => void;
}

function SimpleInput(props: Props) {
  const {
    disabled,
    width,
    value,
    handleChange,
    mathValue,
    mathSymbol,
    handleFocus,
    handleBlur,
  } = props;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: width || '100%',
        backgroundColor: disabled ? '#2F2F2F' : '#242425',
        padding: '6px 12px',
        border: '1px solid #4A4A4A',
        borderRadius: '2px',
      }}
    >
      <input
        value={value}
        onChange={handleChange}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{
          minWidth: '20px',
          backgroundColor: disabled ? '#2F2F2F' : '#242425',
          border: 'none',
          fontSize: '14px',
          color: '#CCCDCD',
        }}
      />
      <p style={{ color: disabled ? '#999999' : '#f0f0f0' }}>
        {mathValue || ''}
        <sup>{mathSymbol}</sup>
      </p>
    </div>
  );
}

export default SimpleInput;
