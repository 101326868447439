import React, { useContext, useEffect, useRef, useState } from 'react';
import { MapContext } from 'react-mapbox-gl';
import { connect, useDispatch } from 'react-redux';

import { polygon, Position } from '@turf/helpers';
import { errorHandler } from 'containers/Map/utils';
import update from 'immutability-helper';
import { GeoJSONSource, MapLayerMouseEvent } from 'mapbox-gl';
import { RootState } from 'store/configureStore';

import { calculateArea } from 'utils';
import {
  setCreateArea,
  setGeometry,
  setProjectArea,
} from '../../../store/mapSlice';
import { IMapSlice, ITaskSlice } from '../../../store/types';
import AreaPopup from './AreaPopup';
import DashedLine from './DashedLine';
import OverlayPolygon from './OverlayPolygon';
import { HandleFeatureState } from './types';
import {
  composeFeature,
  drawablePolygonLineLayer,
  drawablePolygonPointsLayer,
  handleClassName,
} from './utils';

interface Props {
  map: IMapSlice;
}

interface StateRef {
  activePointId: string | number | undefined | null;
  isMouseDown: boolean;
  showDashedLine: boolean;
  coordinates: number[][] | [];
  zoom: any;
}
/**
 * Компонент для рисования области проекта.
 */
function DrawArea(props: Props) {
  const { map } = props;

  const createArea = map.createArea;
  const projectGeometry = map.geometry;

  const dispatch = useDispatch();
  const mapContext = useContext(MapContext);

  const stateRef = useRef<StateRef>({
    activePointId: null,
    isMouseDown: false,
    showDashedLine: false,
    coordinates: [],
    zoom: null,
  });

  const [stateCoords, setStateCoords] = useState<number[][] | []>([]);

  useEffect(() => {
    if (createArea) {
      handleDraw();
      stateRef.current.showDashedLine = true;
      stateRef.current.coordinates =
        projectGeometry?.coordinates[0].slice(0, -1) || [];
    } else {
      clearState();
    }

    // return () => {
    //   unbindHandlers();
    // };
  }, [map.createArea]);

  useEffect(() => {
    handlePoints();
  }, [stateRef.current.coordinates]);

  const handleProjectArea = (coords: number[][]) => {
    let areaInKm = 0;
    if (coords.length > 2) {
      const closedPoly = ([...coords, coords[0]] as unknown) as Position[];
      areaInKm = calculateArea(closedPoly);
      dispatch(setProjectArea(areaInKm));
    }
    const error = errorHandler(areaInKm / 1000000);
    const layer = mapContext?.getLayer('drawable-polygon-line');
    if (layer) {
      const color = error ? '#F15050' : '#5F9BFA';
      const lastColor = mapContext?.getPaintProperty(
        'drawable-polygon-line',
        'line-color',
      );
      if (color !== lastColor) {
        mapContext?.setPaintProperty(
          'drawable-polygon-line',
          'line-color',
          color,
        );
      }
    }
  };

  const handlePoints = () => {
    if (stateRef.current.coordinates.length) {
      const pointFeature = composeFeature('Point');
      const polygonFeatures = composeFeature('Polygon')([
        [...stateRef.current.coordinates, stateRef.current.coordinates[0]],
      ]);
      polygonFeatures.properties.type = 'polygon';
      const pointFeatures = stateRef.current.coordinates
        .map(pointFeature)
        .map((feature, index) => ({
          ...feature,
          properties: {
            index,
            order: handleClassName(index),
            drawType: 'draw-point',
          },
        })) as any;
      const data = {
        type: 'FeatureCollection',
        features: [...pointFeatures, polygonFeatures],
      } as any;

      const source = mapContext?.getSource('drawable-polygon') as GeoJSONSource;

      handleProjectArea(stateRef.current.coordinates);
      if (source) {
        source.setData(data);
      } else if (pointFeatures?.length) {
        mapContext?.addSource('drawable-polygon', {
          type: 'geojson',
          generateId: true,
          data: {
            type: 'FeatureCollection',
            features: [...pointFeatures, polygonFeatures],
          },
        });
        mapContext?.addLayer(drawablePolygonLineLayer);
        mapContext?.addLayer(drawablePolygonPointsLayer);
      }
    }
  };

  const onClickHandler = (ev: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const { lat, lng } = ev.lngLat;
    const newCoords: number[][] = [...stateRef.current.coordinates];
    newCoords.push([lng, lat]);
    stateRef.current.coordinates = newCoords;

    const polyCoords = [
      ...stateRef.current.coordinates,
      stateRef.current.coordinates[0],
    ];

    if (polyCoords.length > 3) {
      const { geometry } = polygon([polyCoords]);
      dispatch(setGeometry(geometry));
    }

    // Use only after all
    setStateCoords(newCoords);
  };

  const handleZoom = () => {
    const zoom = mapContext?.getZoom();
    stateRef.current.zoom = zoom;
  };

  const bindHandlers = () => {
    mapContext?.on('click', onClickHandler);
    mapContext?.on('dblclick', keysHandler);
    mapContext?.on('mouseenter', 'point', mouseEnter);
    mapContext?.on('mouseleave', 'point', mouseLeave);
    mapContext?.on('mousedown', 'point', mouseDown);
    mapContext?.on('mousemove', onMove);
    mapContext?.on('zoom', handleZoom);
    document.addEventListener('keyup', keysHandler);
    const container = mapContext?.getCanvas();
    const style = container?.style;
    if (style) {
      style.cursor = 'pointer';
    }
    if (stateRef.current.zoom) {
      mapContext?.setZoom(stateRef.current.zoom);
    }
  };

  const unbindHandlers = () => {
    mapContext?.off('click', onClickHandler);
    mapContext?.off('dblclick', keysHandler);
    mapContext?.off('mouseenter', 'point', mouseEnter);
    mapContext?.off('mouseleave', 'point', mouseLeave);
    mapContext?.off('mousedown', 'point', mouseDown);
    mapContext?.off('mousemove', onMove);
    mapContext?.off('zoom', handleZoom);
    document.removeEventListener('keyup', keysHandler);
    if (stateRef.current.zoom) {
      mapContext?.setZoom(stateRef.current.zoom);
    }
  };

  const clearState = () => {
    const polyCoords = [
      ...stateRef.current.coordinates,
      stateRef.current.coordinates[0],
    ];
    const container = mapContext?.getCanvas();
    const style = container?.style;

    if (style) {
      style.cursor = 'grab';
    }

    if (polyCoords.length > 3) {
      const { geometry } = polygon([polyCoords]);
      dispatch(setGeometry(geometry));
    }

    if (mapContext?.getSource('drawable-polygon')) {
      mapContext?.removeLayer('point');
      mapContext?.removeLayer('drawable-polygon-line');
      mapContext?.removeSource('drawable-polygon');
    }

    stateRef.current = {
      activePointId: null,
      isMouseDown: false,
      showDashedLine: false,
      coordinates: [],
      zoom: null,
    };
    // setStateCoords([]);
    handleDraw();
  };

  const keysHandler = (event: KeyboardEvent) => {
    if (event.type === 'dblclick') {
      dispatch(setCreateArea(false));
      clearState();
      document.removeEventListener('keyup', keysHandler);
      return;
    }
    switch (event.code) {
      case 'Enter':
        dispatch(setCreateArea(false));
        clearState();
        document.removeEventListener('keyup', keysHandler);
        break;
      case 'Escape':
        dispatch(setCreateArea(false));
        clearState();
        document.removeEventListener('keyup', keysHandler);
        break;
      default:
        break;
    }
  };

  const handleDraw = () => {
    if (createArea) {
      bindHandlers();
    } else {
      unbindHandlers();
    }
  };

  const mouseEnter = (e: MapLayerMouseEvent) => {
    const feature = e.features?.[0];

    if (feature) {
      const { id } = feature;
      if (
        stateRef.current.activePointId !== null &&
        stateRef.current.activePointId !== undefined
      ) {
        handleFeatureState(
          stateRef.current.activePointId,
          { hover: false },
          'drawable-polygon',
        );
      }
      if (id !== undefined) {
        stateRef.current.activePointId = id;
        handleFeatureState(id, { hover: true }, 'drawable-polygon');
      }
    }
  };

  const mouseLeave = () => {
    if (stateRef.current.isMouseDown) return;

    const id = stateRef.current.activePointId;
    if (id !== null && id !== undefined) {
      handleFeatureState(id, { hover: false }, 'drawable-polygon');
    }
    stateRef.current.activePointId = null;
    setStateCoords([]);
  };

  const mouseDown = (e: MapLayerMouseEvent) => {
    e.preventDefault();
    stateRef.current.isMouseDown = true;
    mapContext?.once('mouseup', onUp);
  };

  const onMove = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const { lng, lat } = e.lngLat;
    // console.log('STATE_REF', stateRef.current);
    if (!stateRef.current.isMouseDown) return;
    if (
      stateRef.current.activePointId !== null &&
      typeof stateRef.current.activePointId === 'number'
    ) {
      const newCoords = update(stateRef.current.coordinates, {
        [stateRef.current.activePointId]: { $set: [lng, lat] },
      });
      stateRef.current.coordinates = newCoords;
      setStateCoords(newCoords);
      const polyCoords = [
        ...stateRef.current.coordinates,
        stateRef.current.coordinates[0],
      ];
      if (polyCoords.length > 3) {
        const { geometry } = polygon([polyCoords]);
        dispatch(setGeometry(geometry));
      }
    }
  };

  const onUp = () => {
    stateRef.current.isMouseDown = false;
  };

  const handleFeatureState: HandleFeatureState = (id, states, source) =>
    mapContext?.setFeatureState({ id, source }, states);

  const dashedLinePoints = [stateRef.current.coordinates[0]];

  dashedLinePoints[2] =
    stateRef.current.coordinates[stateRef.current.coordinates.length - 1];

  return (
    <>
      <OverlayPolygon
        coordinates={stateCoords}
        refCoords={stateRef.current.coordinates}
      />
      {stateRef.current.showDashedLine && !stateRef.current.isMouseDown && (
        <>
          <AreaPopup coords={stateCoords} />
          <DashedLine dashedLinePoints={dashedLinePoints} />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  task: state.task,
  map: state.map,
});

export default connect(mapStateToProps)(DrawArea);
