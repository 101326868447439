import gql from 'graphql-tag';

export default gql`
  query getBuildingCenter($buildingId: Uuid!, $taskId: Uuid!) {
    buildingCenterByTaskId(buildingId: $buildingId, taskId: $taskId) {
      center
      buildingId
      taskId
    }
  }
`;
