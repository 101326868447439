import * as React from 'react';
import { MapContext } from 'react-mapbox-gl';
import { connect } from 'react-redux';

import { ReactComponent as ZoomIn } from 'assets/images/zoom-in.svg';
import { ReactComponent as ZoomOut } from 'assets/images/zoom-out.svg';

import { RootState } from '../../../store/configureStore';
import { ITaskSlice } from '../../../store/types';
import LayersToolbarButton from '../LayersToolbar/LayersToolbarButton';
import FlyToCenterButton from './FlyToCenterButton';
import { ZoomButtonPropsType } from './types';
import ZoomButton from './ZoomButton';

import './style.scss';

interface Props {
  task: ITaskSlice;
}

function ControlButtons(props: Props) {
  const { task } = props;

  const map = React.useContext(MapContext);

  const onZoomHandler: ZoomButtonPropsType['onZoomHandler'] = type => {
    const zoom = map?.getZoom();
    const center = map?.getCenter();

    if (zoom === undefined || center === undefined) return;

    const newZoom = type === 'zoomIn' ? zoom + 1 : zoom - 1;
    map?.flyTo({ center, zoom: newZoom });
  };

  const flyTo = () => {
    if (task.center) {
      const { coordinates } = JSON.parse(task.center);
      map?.flyTo({ center: coordinates, zoom: 16, essential: true });
    }
  };

  return (
    <div className="scale-buttons">
      <div className="scale-buttons__zoom button-container">
        <ZoomButton type="zoomIn" onZoomHandler={onZoomHandler}>
          <ZoomIn />
        </ZoomButton>
        <ZoomButton type="zoomOut" onZoomHandler={onZoomHandler}>
          <ZoomOut />
        </ZoomButton>
      </div>
      <FlyToCenterButton flyTo={flyTo} />
      <LayersToolbarButton />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  task: state.task,
});

export default connect(mapStateToProps)(ControlButtons);
