import gql from 'graphql-tag';

export const CALCULATION_METHOD_FRAGMENT = gql`
  fragment CalculationMethodFragment on Method {
    id
    name
    code
    programImpl
    isRunDefault
  }
`;

export const CALCULATION_STAGE_FRAGMENT = gql`
  fragment CalculationStageFragment on Stage {
    id
    name
    code
    visualView
    methods {
      ...CalculationMethodFragment
    }
    allowMultipleMethods
    order
    scenarioId
  }
  ${CALCULATION_METHOD_FRAGMENT}
`;
