import * as React from 'react';
import { MapContext } from 'react-mapbox-gl';

import { calculateArea } from 'utils';
import PopupText from '../OverlayPoly/PopupText';
import { IAreaPopupProps } from './types';

/**
 * Popup с размерами области.
 */
const AreaPopup: React.FC<IAreaPopupProps> = props => {
  const [point, setPoint] = React.useState<mapboxgl.Point | null>(null);
  const [updatedArea, setUpdatedArea] = React.useState<number>(0);
  const coords = React.useRef<number[][]>([]);

  const map = React.useContext(MapContext);
  /**
   *
   * @param {mapboxgl.MapMouseEvent & mapboxgl.EventData} e
   * Т.к. функция байндится к карте,
   * возможно использовать только мутированные координаты.
   * Через useState не получится.
   */
  const onMove = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const { lngLat } = e;
    const closedPoly = [
      ...coords.current,
      [lngLat.lng, lngLat.lat],
      coords.current[0],
    ];

    setPoint(e.point);
    setUpdatedArea(calculateArea(closedPoly));
  };

  const bindHandlers = () => map?.on('mousemove', onMove);

  const unbindHandlers = () => map?.off('mousemove', onMove);

  React.useEffect(() => {
    bindHandlers();

    return () => {
      unbindHandlers();
    };
  }, []);

  React.useEffect(() => {
    coords.current = props.coords;
  }, [props.coords]);

  if (!point) return null;

  return (
    <div
      className="area-info"
      style={{
        top: `${point.y}px`,
        left: `${point.x}px`,
      }}
    >
      <PopupText error={false} updatedArea={updatedArea} />
    </div>
  );
};

export default AreaPopup;
