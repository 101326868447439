import * as React from 'react';

function FavoriteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.145 16.006a.3.3 0 00-.29 0L4.978 18.7a.3.3 0 01-.44-.31l.938-5.754a.3.3 0 00-.082-.258L1.408 8.293a.3.3 0 01.17-.506L7.06 6.95a.3.3 0 00.226-.169L9.728 1.58a.3.3 0 01.544 0l2.44 5.201a.3.3 0 00.227.17l5.484.837a.3.3 0 01.17.506l-3.987 4.086a.3.3 0 00-.082.258l.939 5.753a.3.3 0 01-.441.311l-4.877-2.695z"
        fill="#F0F0F0"
        stroke="#F0F0F0"
        strokeLinecap="square"
      />
    </svg>
  );
}

export default FavoriteIcon;
