import * as React from 'react';

import { ReactComponent as CancelIcon } from 'assets/images/cancel.svg';
import dayjs from 'dayjs';

import { ITaskListModal } from './types';

const TaskListModal: React.FC<ITaskListModal> = ({
  onCloseHandler,
  onClickHandler,
  name,
  created,
}) => (
  <>
    <div
      aria-label="close"
      onClick={onCloseHandler}
      className="error-modal"
      role="button"
      tabIndex={0}
      onKeyPress={onCloseHandler}
    />
    <div className="task-list-modal__container">
      <div className="error-modal__sub-container">
        <button
          type="button"
          onClick={onCloseHandler}
          className="task-list-modal__cancel-button"
        >
          <CancelIcon />
        </button>
        <div>
          <div className="error-modal__title-text">
            <div>
              <p className="task-list-modal__text">
                Вы уверены, что хотите удалить <br />
                <span className="task-list-modal__project-name">
                  «{name}&nbsp;
                  {created && `[${dayjs(created).format('DD.MM.YYYY hh:mm')}]`}
                  »?
                </span>
              </p>
            </div>
          </div>
          <div className="task-list-modal__button">
            <div className="task-list__remove-button-container">
              <button
                type="button"
                onClick={onClickHandler}
                className="primary-button task-list__remove-button"
              >
                Удалить
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={onCloseHandler}
                className="secondary-button"
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default TaskListModal;
