import tilesUrl from 'constants/tilesUrl';
import { TaskStatus } from 'generated/graphql';
import { AnyLayer, Sources } from 'mapbox-gl';

import { overlayInitData } from './DrawArea/utils';

export const sources = (
  licenseAreaId: string,
  demId: string,
  taskId?: string,
  status?: TaskStatus | null,
): Sources => {
  const sourceTiles: Sources = {
    'raster-tiles': {
      type: 'raster',
      tiles: [
        `${tilesUrl}/tiles?license_area_id=${licenseAreaId}&dem_id=${demId}&z={z}&x={x}&y={y}`,
      ],
      tileSize: 256,
    },
    geojson: {
      type: 'geojson',
      generateId: true,
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    },
    'overlay-polygon': {
      type: 'geojson',
      generateId: true,
      data: overlayInitData,
    },
    'ruler-source': {
      type: 'geojson',
      generateId: true,
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    },
  };

  if (taskId && status === 'SOLVED') {
    sourceTiles['vector-tiles'] = {
      type: 'vector',
      tiles: [`${tilesUrl}/vector?task_id=${taskId}&z={z}&x={x}&y={y}`],
      promoteId: 'id',
    };
  }

  return sourceTiles;
};

export const rasterLayers: AnyLayer = {
  id: 'raster-tiles',
  type: 'raster',
  source: 'raster-tiles',
};

export const vectorLayers: AnyLayer[] = [
  // Область проекта
  {
    id: 'development_area',
    type: 'line',
    source: 'overlay-polygon',
    layout: {
      visibility: 'visible',
      'line-join': 'miter',
      'line-cap': 'butt',
    },
    paint: {
      'line-opacity': 1,
      'line-color': '#FFF',
      'line-width': 1,
      'line-dasharray': [3, 3],
    },
  },
  // Границы проекта
  {
    id: 'site_plan',
    type: 'fill',
    source: 'vector-tiles',
    'source-layer': 'site_plan',
    layout: {
      visibility: 'visible',
    },
    paint: {
      'fill-outline-color': '#FBFBFB',
      'fill-color': '#FBFBFB',
      'fill-opacity': 1,
    },
  },
  // Здания
  {
    id: 'buildings',
    type: 'fill',
    source: 'vector-tiles',
    'source-layer': 'buildings',
    minzoom: 14,
    layout: {
      visibility: 'visible',
    },
    paint: {
      'fill-color': [
        'match',
        ['get', 'functional_area'],
        'ONE',
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#F7E5A5',
          [
            'case',
            ['boolean', ['feature-state', 'active'], false],
            '#EFCA45',
            '#F8EAB7',
          ],
        ],
        'TWO',
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#CDE4C4',
          [
            'case',
            ['boolean', ['feature-state', 'active'], false],
            '#B5D5A5',
            '#D1E5C9',
          ],
        ],
        'THREE',
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#F2C9AB',
          [
            'case',
            ['boolean', ['feature-state', 'active'], false],
            '#E49358',
            '#F4D3BB',
          ],
        ],
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#F9B5B5',
          [
            'case',
            ['boolean', ['feature-state', 'active'], false],
            '#F37A7A',
            '#F9C9C9',
          ],
        ],
      ],
    },
  },
  // Дороги
  {
    id: 'roads',
    type: 'fill',
    source: 'vector-tiles',
    'source-layer': 'roads',
    minzoom: 14,
    layout: {
      visibility: 'visible',
    },
    paint: {
      'fill-outline-color': '#F7C877',
      'fill-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        'rgba(247, 200, 119, 0.24)',
        'rgba(247, 200, 119, 0.14)',
      ],
      'fill-opacity': 1,
    },
  },
  // Границы дорог
  {
    id: 'road_borders',
    type: 'line',
    source: 'vector-tiles',
    'source-layer': 'roads',
    minzoom: 14,
    layout: {
      visibility: 'visible',
    },
    paint: {
      'line-opacity': 1,
      'line-color': '#F7C877',
      'line-width': 1,
    },
  },
  // Линии в центре дорог
  {
    id: 'road_lines',
    type: 'line',
    source: 'vector-tiles',
    'source-layer': 'road_lines',
    minzoom: 14,
    layout: {
      visibility: 'visible',
      'line-join': 'miter',
      'line-cap': 'butt',
    },
    paint: {
      'line-opacity': 1,
      'line-color': '#F7C877',
      'line-width': 1,
      'line-dasharray': [4, 2, 1, 2],
    },
  },
  // Инженерные и технологические коммуникации
  {
    id: 'utility_lines',
    type: 'line',
    source: 'vector-tiles',
    'source-layer': 'utility_lines',
    minzoom: 14,
    layout: {
      visibility: 'visible',
      'line-join': 'miter',
      'line-cap': 'butt',
    },
    paint: {
      'line-opacity': 1,
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#B1B1B1',
        '#999999',
      ],
      'line-width': 2,
    },
  },
  // // Эстакада инженерных коммуникаций
  // {
  //   id: 'corridors',
  //   type: 'fill',
  //   source: 'vector-tiles',
  //   'source-layer': 'corridors',
  //   minzoom: 14,
  //   layout: {
  //     visibility: 'visible',
  //   },
  //   paint: {
  //     'fill-outline-color': '#C88FF5',
  //     'fill-color': 'transparent',
  //     'fill-opacity': 1,
  //   },
  // },
  // Эстакада инженерных коммуникаций надземных
  {
    id: 'corridors_overground',
    type: 'fill',
    source: 'vector-tiles',
    'source-layer': 'corridors_overground',
    minzoom: 14,
    layout: {
      visibility: 'visible',
    },
    paint: {
      'fill-outline-color': '#C88FF5',
      'fill-color': 'transparent',
      'fill-opacity': 1,
    },
  },
  // Эстакада инженерных коммуникаций подземных
  {
    id: 'corridors_underground',
    type: 'fill',
    source: 'vector-tiles',
    'source-layer': 'corridors_underground',
    minzoom: 14,
    layout: {
      visibility: 'visible',
    },
    paint: {
      'fill-outline-color': '#a33f36',
      'fill-color': 'transparent',
      'fill-opacity': 1,
    },
  },
  // Ограждение
  {
    id: 'barriers',
    type: 'line',
    source: 'vector-tiles',
    'source-layer': 'barriers',
    minzoom: 14,
    layout: {
      visibility: 'none',
      'line-join': 'miter',
      'line-cap': 'butt',
    },
    paint: {
      'line-opacity': 1,
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#fff',
        '#2f2f2f',
      ],
      'line-width': 1,
    },
  },
  {
    id: 'barriers-2',
    type: 'line',
    source: 'vector-tiles',
    'source-layer': 'barriers',
    minzoom: 14,
    layout: {
      visibility: 'none',
      'line-join': 'miter',
      'line-cap': 'butt',
    },
    paint: {
      'line-opacity': 1,
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#fff',
        '#2f2f2f',
      ],
      'line-dasharray': [1, 2],
      'line-width': 1,
      'line-offset': -1,
    },
  },
  {
    id: 'nodes',
    type: 'circle',
    source: 'vector-tiles',
    'source-layer': 'nodes',
    minzoom: 16,
    layout: {
      visibility: 'none',
    },
    paint: {
      'circle-color': '#fff',
    },
  },
  // Центры зданий
  {
    id: 'buildings-centers',
    type: 'symbol',
    source: 'vector-tiles',
    'source-layer': 'building_centers',
    minzoom: 16,
    layout: {
      visibility: 'visible',
      'text-field': ['get', 'label'],
      'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
      'text-size': 10,
      'text-allow-overlap': true,
    },
    paint: {
      'text-color': '#8d95a4',
    },
  },
  // Постоянное присутствие людей зум меньше 14
  // нет возможности сделать conditionals для icon-offset \ icon-translate
  {
    id: 'buildings-permanent-staff',
    type: 'symbol',
    source: 'vector-tiles',
    'source-layer': 'building_centers',
    minzoom: 16,
    layout: {
      visibility: 'visible',
      'icon-image': 'staffPattern',
      'icon-size': 0.4,
      'icon-offset': [0, -30],
    },
    paint: {
      'icon-opacity': [
        'case',
        ['boolean', ['get', 'is_permanent_staff_presence'], false],
        1,
        0,
      ],
    },
  },
  // Постоянное присутствие людей зум больше 14
  {
    id: 'buildings-permanent-staff-2',
    type: 'symbol',
    source: 'vector-tiles',
    'source-layer': 'building_centers',
    minzoom: 16,
    layout: {
      visibility: 'visible',
      'icon-image': 'staffPattern',
      'icon-size': 0.4,
      'icon-offset': [0, 0],
    },
    paint: {
      'icon-opacity': [
        'case',
        ['boolean', ['get', 'is_permanent_staff_presence'], false],
        1,
        0,
      ],
    },
  },
  // Строительная Сетка
  {
    id: 'grid_lines',
    type: 'line',
    source: 'vector-tiles',
    'source-layer': 'grid_lines',
    layout: {
      visibility: 'none',
      'line-join': 'miter',
      'line-cap': 'butt',
    },
    paint: {
      'line-opacity': 1,
      'line-color': '#2F2F2F',
      'line-width': 1,
    },
  },
  // Строительная Сетка
  {
    id: 'grid_lines_ends',
    type: 'symbol',
    source: 'vector-tiles',
    'source-layer': 'grid_lines_ends',
    layout: {
      visibility: 'none',
      'text-field': [
        'format',
        ['get', 'index'],
        ['match', ['get', 'axis'], 'HORIZONTAL', 'А', 'Б'],
      ],
      'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
      'text-size': 10,
      'text-offset': [
        'match',
        ['get', 'type'],
        'end',
        [
          'match',
          ['get', 'axis'],
          'HORIZONTAL',
          ['literal', [0.8, 0]],
          ['literal', [0, -0.8]],
        ],
        [
          'match',
          ['get', 'axis'],
          'HORIZONTAL',
          ['literal', [-0.8, 0]],
          ['literal', [0, 0.8]],
        ],
      ],
    },
    paint: {
      'text-color': '#2F2F2F',
    },
  },
  // Зоны поражения
  {
    id: 'affected_areas',
    type: 'line',
    source: 'vector-tiles',
    'source-layer': 'affected_areas',
    layout: {
      visibility: 'none',
      'line-join': 'miter',
      'line-cap': 'butt',
    },
    paint: {
      'line-opacity': 1,
      'line-color': [
        'match',
        ['get', 'damage_level'],
        'VERY_LOW',
        '#45E47B',
        'LOW',
        '#5EE9F2',
        'MEDIUM',
        '#F2E800',
        'HIGH',
        '#FF8833',
        '#F84A4A',
      ],
      'line-width': 1,
    },
  },
  // Зоны теплового излучения
  {
    id: 'thermal_radiation_areas',
    type: 'line',
    source: 'vector-tiles',
    'source-layer': 'thermal_radiation_areas',
    layout: {
      visibility: 'none',
      'line-join': 'miter',
      'line-cap': 'butt',
    },
    paint: {
      'line-pattern': [
        'match',
        ['get', 'thermal_radiation_level'],
        'SAFE',
        'safePattern',
        'DANGEROUS',
        'dangerousPattern',
        'deadlyPattern',
      ],
      'line-opacity': 1,
      'line-width': 3,
    },
  },
];

export const drawPoints: AnyLayer = {
  id: 'draw-points',
  type: 'circle',
  source: 'geojson',
  paint: {
    'circle-radius': 4,
    'circle-color': '#999',
    'circle-stroke-color': '#fff',
    'circle-stroke-width': 4,
  },
  filter: ['in', 'drawType', 'draw-point'],
};

export const externalPoints: AnyLayer = {
  id: 'external-points',
  type: 'symbol',
  source: 'geojson',
  layout: {
    'icon-size': 0.25,
    'icon-image': ['get', 'type'],
  },
  filter: ['in', 'drawType', 'external-point'],
};

export const drawLines: AnyLayer = {
  id: 'draw-lines',
  type: 'line',
  source: 'geojson',
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  paint: {
    'line-color': '#F0F0F0',
    'line-width': 4,
  },
  filter: ['in', 'type', 'external-line'],
};

export const drawInnerLines: AnyLayer = {
  id: 'inner-lines',
  type: 'line',
  source: 'geojson',
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  paint: {
    'line-color': '#999',
    'line-width': 2,
    'line-dasharray': [4, 4],
  },
  filter: ['in', 'type', 'inner-line'],
};

// Затемнение поверх карты
export const overlayLayer: AnyLayer = {
  id: 'overlay-polygon',
  type: 'fill',
  source: 'overlay-polygon',
  paint: {
    'fill-color': '#000',
    'fill-opacity': 0.32,
    'fill-antialias': false,
  },
};

// Линейка
export const rulerLineLayer: AnyLayer = {
  id: 'ruler-line',
  type: 'line',
  source: 'ruler-source',
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  paint: {
    'line-color': '#F0F0F0',
    'line-width': 4,
  },
  filter: ['in', 'type', 'ruler-line'],
};

export const rulerDashedLineLayer: AnyLayer = {
  id: 'dashed-ruler-line',
  type: 'line',
  source: 'ruler-source',
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  paint: {
    'line-color': '#999999',
    'line-width': 2,
    'line-dasharray': [4, 4],
  },
  filter: ['in', 'type', 'ruler-line'],
};

export const rulerLinePoints: AnyLayer = {
  id: 'points-ruler-line',
  type: 'circle',
  source: 'ruler-source',
  paint: {
    'circle-radius': 2,
    'circle-stroke-width': 2,
    'circle-color': '#999999',
    'circle-stroke-color': '#FFFFFF',
  },
  filter: ['in', '$type', 'Point'],
};

const rulerLayers = [rulerLineLayer, rulerDashedLineLayer, rulerLinePoints];

export const layers = (taskId?: string, status?: TaskStatus | null) => {
  let layersArray: AnyLayer[] = [
    rasterLayers,
    drawLines,
    drawPoints,
    drawInnerLines,
    overlayLayer,
    externalPoints,
  ];

  if (taskId && status === 'SOLVED') {
    layersArray = layersArray.concat(vectorLayers);
  }

  return layersArray.concat(rulerLayers);
};
