import * as React from 'react';

function CalculateCheckboxIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={12}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.04 7c-2.541-1.68-3.697-2.925-5-6h10c-1.245 2.838-2.217 4.127-5 6zm0 0c-2.541 1.68-3.697 2.925-5 6h10c-1.245-2.838-2.217-4.127-5-6z"
        stroke="#4569A2"
        strokeWidth={2}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CalculateCheckboxIcon;
