import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDictionariesSlice } from './types';

const initialState: IDictionariesSlice = {
  allContentTypes: null,
  allDems: null,
  allRegions: null,
  allUsers: null,
  allLicenseAreas: null,
  allConnectionTypes: null,
  allConnectionPointTypes: null,
  allBuildingTypes: null,
  isFetching: false,
};

export const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {
    setDictionaries: (state, action: PayloadAction<IDictionariesSlice>) => {
      return {
        ...state,
        ...action.payload,
        isFetching: true,
      };
    },
  },
});

export const { setDictionaries } = dictionariesSlice.actions;

export default dictionariesSlice.reducer;
