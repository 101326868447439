import * as React from 'react';

import { ITaskListBottomRowProps } from './types';

const TaskListBottomRow: React.FC<ITaskListBottomRowProps> = ({ showMore }) => (
  <div className="task-list__bottom-row">
    <button
      type="button"
      onClick={showMore}
      className="task-list__show-more-button"
    >
      Показать больше проектов
    </button>
  </div>
);

export default TaskListBottomRow;
