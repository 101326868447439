import gql from 'graphql-tag';

export default gql`
  mutation deleteTask($taskId: Uuid!) {
    taskMutation {
      deleteTask(taskId: $taskId) {
        ok
      }
    }
  }
`;
