import gql from 'graphql-tag';

export default gql`
  query getData {
    allContentTypes {
      id
      name
    }
    allDems {
      id
      demType
    }
    allRegions {
      id
      name
    }
    allUsers {
      id
    }
    allLicenseAreas {
      id
      name
    }
    allConnectionTypes {
      id
      name
      widthM
      cost
    }
    allConnectionPointTypes {
      id
      name
      isExternal
    }
    allBuildingTypes {
      id
      name
      code
    }
  }
`;
