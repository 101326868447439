import {
  ConstructionType,
  ContentType,
  FireDangerRating,
  FireResistanceRating,
  FunctionalAreaType,
  StructuralFireHazardClass,
  TankLocationType,
} from 'generated/graphql';

type OptionsType<T> = {
  value: T;
  label: string;
};

export const shapeTypes = [
  { value: false, label: 'Прямоугольник' },
  { value: true, label: 'Круг' },
];

// Класс конструктивной пожарной опасности

export const fireDangerRatingOptions: OptionsType<FireDangerRating>[] = [
  { value: 'ENHANCED_EXPLOSIVE', label: 'категория А' },
  { value: 'EXPLOSIVE', label: 'категория Б' },
  { value: 'FLAMMABLE', label: 'категория В' },
  { value: 'LOW_FLAMMABLE', label: 'категория Г' },
  { value: 'MODERATE_FLAMMABLE', label: 'категория Д' },
];

// Категория по пожарной и взрывопожарной опасности

export const structuralFireHazardClassOptions: OptionsType<StructuralFireHazardClass>[] = [
  { value: 'C0', label: 'класс CO' },
  { value: 'C1', label: 'класс C1' },
  { value: 'C2', label: 'класс C2' },
  { value: 'C3', label: 'класс C3' },
];

// Степень огнестойкости

export const fireResistanceRatingOptions: OptionsType<FireResistanceRating>[] = [
  { value: 'ONE', label: 'степень I' },
  { value: 'TWO', label: 'степень II' },
  { value: 'THREE', label: 'степень III' },
  { value: 'FOUR', label: 'степень IV' },
  { value: 'FIVE', label: 'степень V' },
];

export const functionalAreaOptions: OptionsType<FunctionalAreaType>[] = [
  { value: 'ONE', label: 'I' },
  { value: 'TWO', label: 'II' },
  { value: 'THREE', label: 'III' },
  { value: 'FLARE_STACK_ZONE', label: 'Факельная зона' },
];

export const constructionTypeOptions: OptionsType<ConstructionType>[] = [
  { value: 'OPEN_AREA', label: 'Открытая площадка' },
  { value: 'BUILDING', label: 'Здание' },
];

export const tankLocationTypes: OptionsType<TankLocationType>[] = [
  { value: 'BURIED', label: 'Подземная' },
  { value: 'GROUND_LEVEL', label: 'Наземная' },
];

export const composeTankLocationTypesOptions = (
  contentTypes?: OptionsType<TankLocationType>[],
) => {
  if (!contentTypes) return [];

  const options = contentTypes.map(({ value, label }) => ({
    value,
    label,
  }));

  return [{ value: null, label: 'Не выбран' }, ...options];
};

export const composeContentTypeOptions = (contentTypes?: ContentType[]) => {
  if (!contentTypes) return [];

  const options = contentTypes.map(({ id: value, name: label }) => ({
    value,
    label,
  }));

  return [{ value: null, label: 'Не выбран' }, ...options];
};

export const contentTypeOptions: OptionsType<ContentType['name'] | null>[] = [
  { value: null, label: 'Не выбран' },
  { value: 'COMBUSTIBLE_GAS', label: 'ГГ' },
  { value: 'COMBUSTIBLE_LIQUID', label: 'ГЖ' },
  { value: 'FLAMMABLE_LIQUID', label: 'ЛВЖ' },
  { value: 'WATER', label: 'Вода' },
  { value: 'METHANOL', label: 'Метанол' },
];
