import {
  getLogs,
  getTaskCalculation,
  getTaskStatus,
} from '../apollo/socketRequests';
import { ITaskSlice } from '../store/types';
// Socket manager
export default class Socket {
  logs: any;

  calculation: any;

  status: any;

  taskId: ITaskSlice['id'] | null;

  constructor(
    public getTaskTrace: (e?: any) => void,
    public getCalculation: (e?: any) => void,
    public taskStatus: (e?: any) => void,
    public handleError: (e?: any) => void,
  ) {
    this.logs = null;
    this.calculation = null;
    this.status = null;

    this.getTaskTrace = getTaskTrace;
    this.getCalculation = getCalculation;
    this.taskStatus = taskStatus;
    this.taskId = null;

    this.handleError = handleError;
  }

  // Connect when get task
  connect = async (taskId: ITaskSlice) => {
    this.taskId = taskId;
    // Connect
    this.logs = getLogs(this.taskId).subscribe(logs => {
      const taskTraces = logs.data?.taskTraces;
      if (taskTraces) {
        this.getTaskTrace(taskTraces);
      }
    });

    this.calculation = getTaskCalculation(this.taskId).subscribe(
      calculation => {
        const taskCalculation = calculation.data?.taskCalculationStatuses;
        if (taskCalculation) {
          this.getCalculation(taskCalculation);
        }
      },
    );

    this.status = getTaskStatus(this.taskId).subscribe(calculation => {
      const taskStatus = calculation.data?.taskStatus?.taskStatus;
      if (taskStatus) {
        this.taskStatus(taskStatus);
      }
    });
  };

  // Close the socket
  disconnect = () => {
    this.logs.disconnect();
    this.calculation.disconnect();
    this.status.disconnect();
  };

  // Received error from socket
  onError = (err: any) => {
    this.disconnect();
  };
}
