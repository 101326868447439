import * as React from 'react';

function CancelIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.471 4.472a.667.667 0 10-.942-.943L8 7.058 4.471 3.529a.667.667 0 10-.942.943l3.528 3.529-3.528 3.528a.667.667 0 00.942.943L8 8.943l3.529 3.53a.667.667 0 00.942-.944L8.943 8.001l3.528-3.529z"
        fill="#737373"
      />
    </svg>
  );
}

export default CancelIcon;
