import gql from 'graphql-tag';

export default gql`
  subscription getTaskLogs($taskId: Uuid!) {
    taskTraces(taskId: $taskId) {
      percentage
      message
      debugInfo
      status
    }
  }
`;
