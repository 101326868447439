import gql from 'graphql-tag';

export default gql`
  subscription getTaskCalculation($taskId: Uuid!) {
    taskCalculationStatuses(taskId: $taskId) {
      taskCalculationId
      status
      errorMessage
      debugInfo
    }
  }
`;
