import * as React from 'react';
import { MapEvent } from 'react-mapbox-gl/lib/map-events';

import { FeatureIdentifier } from 'mapbox-gl';

const useHover = () => {
  const activeFeature = React.useRef<FeatureIdentifier | null>(null);

  const onMouseMove: MapEvent = React.useCallback((map, e: any) => {
    const feature = map.queryRenderedFeatures(e.point)[0];
    const source = feature?.source;
    const sourceLayer = feature?.sourceLayer;

    if (activeFeature.current && activeFeature.current.sourceLayer) {
      map.setFeatureState(activeFeature.current, { hover: false });
    }

    if (feature && feature.id && source) {
      activeFeature.current = { id: feature.id, source, sourceLayer };
      map.setFeatureState(
        { id: feature.id, source, sourceLayer },
        { hover: true },
      );
    }
  }, []);

  return onMouseMove;
};

export default useHover;
