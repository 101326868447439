import area from '@turf/area';
import { polygon } from '@turf/helpers';

export const formatter = new Intl.NumberFormat('ru', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const calculateArea = (closedPoly: number[][]): number => {
  if (closedPoly.length < 4) return 0;
  const reversedCoords = closedPoly.map(el => [el[1], el[0]]);
  return area(polygon([reversedCoords]));
};

export const findDuplicates = (arr: string[]): string[] =>
  arr.filter((item, index) => arr.indexOf(item) !== index);

const filterSelectValue = (t?: boolean | string | null) => ({ value }) =>
  value === t;

export const selectValue = options => value =>
  options.filter(filterSelectValue(value));
