import * as React from 'react';
import { MapContext, Popup } from 'react-mapbox-gl';
import { connect } from 'react-redux';

import length from '@turf/length';
import { ReactComponent as CancelIcon } from 'assets/images/cancel.svg';
import update from 'immutability-helper';
import _isEqual from 'lodash.isequal';
import { GeoJSONSource, MapLayerMouseEvent } from 'mapbox-gl';
import { RootState } from 'store/configureStore';

import { formatter } from 'utils';
import { composeFeature } from '../DrawArea/utils';
import { IRulerProps, IRulerState } from './types';

import './style.scss';

class Ruler extends React.Component<IRulerProps> {
  state: IRulerState = {
    coords: [],
    isMouseDown: false,
    activePointId: null,
    distance: '0',
  };

  map?: mapboxgl.Map = this.context;

  componentDidUpdate(prevProps: IRulerProps, prevState: IRulerState) {
    const { map } = this.props;
    const { coords } = this.state;

    if (prevProps.map.showRuler !== map.showRuler) {
      if (map.showRuler) {
        this.bindHandlers();
      } else {
        this.unbindHandlers();
      }
    }

    if (!_isEqual(prevState.coords, coords)) {
      this.handlePoints();
    }
  }

  componentWillUnmount() {
    this.unbindHandlers();
  }

  handlePoints = () => {
    const { coords } = this.state;
    const rulerPoint = composeFeature('Point');
    const rulerLineData = composeFeature('LineString')(coords) as any;
    rulerLineData.properties = {
      type: 'ruler-line',
    };
    const rulerPointsData = coords.map(rulerPoint).map((feature, index) => ({
      ...feature,
      properties: { index },
    })) as any;

    const data = {
      type: 'FeatureCollection',
      features: [...rulerPointsData, rulerLineData],
    } as any;

    this.handleDistance(rulerLineData);
    const source = this.map?.getSource('ruler-source') as GeoJSONSource;
    source.setData(data);
  };

  handleDistance = (rulerLineData: any) => {
    const distance = formatter.format(
      length(rulerLineData, { units: 'meters' }),
    );
    this.setState({ distance });
  };

  onClickHandler = (ev: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const { coords } = this.state;
    const { lat, lng } = ev.lngLat;
    const rulerCoords = [...coords, [lng, lat]];
    this.setState({ coords: rulerCoords });
  };

  bindHandlers = () => {
    this.map?.on('click', this.onClickHandler);
    this.map?.on('mousedown', 'points-ruler-line', this.mouseDown);
    this.map?.on('mouseenter', 'points-ruler-line', this.mouseEnter);
    this.map?.on('mouseleave', 'points-ruler-line', this.mouseLeave);
    this.map?.on('mousemove', this.onMove);
    const prevZoom = this.map?.getZoom();
    this.map?.setZoom(prevZoom as number);

    const container = this.map?.getCanvas();
    const style = container?.style;
    if (style) {
      style.cursor = 'pointer';
    }
  };

  unbindHandlers = () => {
    this.map?.off('click', this.onClickHandler);
    this.map?.off('mousedown', 'points-ruler-line', this.mouseDown);
    this.map?.off('mouseenter', 'points-ruler-line', this.mouseEnter);
    this.map?.off('mouseleave', 'points-ruler-line', this.mouseLeave);
    this.map?.off('mousemove', this.onMove);
    const prevZoom = this.map?.getZoom();
    this.map?.setZoom(prevZoom as number);

    this.clearState();
  };

  clearState = () => {
    const container = this.map?.getCanvas();
    const style = container?.style;
    if (style) {
      style.cursor = 'grab';
    }
    this.setState({
      activePointId: null,
      coords: [],
      isMouseDown: false,
    });
  };

  mouseEnter = (e: MapLayerMouseEvent) => {
    const feature = e.features?.[0];

    if (feature) {
      const { id } = feature;
      if (id !== undefined) {
        this.setState({ activePointId: id });
      }
    }
  };

  mouseLeave = () => {
    const { isMouseDown } = this.state;

    if (isMouseDown) return;

    this.setState({ activePointId: null });
  };

  mouseDown = (e: MapLayerMouseEvent) => {
    const map = this.context;
    e.preventDefault();

    this.setState({ isMouseDown: true }, () => {
      map?.once('mouseup', this.onUp);
    });
  };

  onUp = () => this.setState({ isMouseDown: false });

  onMove = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const { lng, lat } = e.lngLat;
    const { activePointId, isMouseDown } = this.state;

    if (!isMouseDown) return;

    if (
      activePointId !== null &&
      activePointId !== undefined &&
      typeof activePointId === 'number'
    ) {
      const { coords } = this.state;
      const newCoords = update(coords, {
        [activePointId]: { $set: [lng, lat] },
      });
      this.setState({ coords: newCoords });
    }
  };

  onCancelHandler = () => {
    const { coords: lastPoints } = this.state;

    const coords = update(lastPoints, {
      $splice: [[lastPoints.length - 1, 1]],
    });
    const data = composeFeature('LineString')(coords) as any;
    const distance = this.handleDistance(data);

    this.setState({ coords, distance });
  };

  render() {
    const { distance, coords } = this.state;

    if (!coords.length) return null;

    return (
      <Popup
        offset={[0, -10]}
        className="main-popup"
        coordinates={coords[coords.length - 1]}
      >
        <p className="ruler-text">{distance} м</p>
        <button
          type="button"
          onClick={this.onCancelHandler}
          className="main-popup__close"
        >
          <CancelIcon />
        </button>
      </Popup>
    );
  }
}

Ruler.contextType = MapContext;

const mapStateToProps = (state: RootState) => ({
  map: state.map,
});

export default connect(mapStateToProps)(Ruler);
