import { gql } from '@apollo/client';

import { BUILDING_FRAGMENT } from './fragments/buildings';

export default gql`
  mutation updateBuilding($buildingInput: UpdateBuildingInput!) {
    buildingMutation {
      updateBuilding(input: $buildingInput) {
        recordId
      }
    }
  }
  ${BUILDING_FRAGMENT}
`;
