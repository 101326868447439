import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as LayerPressed } from 'assets/images/layer-pressed.svg';

import LayersToolbar from './LayersToolbar';

import './style.scss';

function LayersToolbarButton(props: any) {
  const [isVisible, setVisible] = useState(false);
  const visibleRef = useRef(false);
  const wrapperRef: any = useRef(null);
  const setToolbarVisible = () => {
    setVisible(!visibleRef.current);
  };

  useEffect(() => {
    visibleRef.current = isVisible;
    function handleClickOutside(event) {
      // event.preventDefault();
      // event.stopPropagation();
      if (
        wrapperRef?.current &&
        !wrapperRef?.current?.contains(event.target) &&
        visibleRef.current
      ) {
        setToolbarVisible();
        // alert('You clicked outside of me!');
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  return (
    <div className="button-container" ref={wrapperRef}>
      <button
        type="button"
        className="layers-toolbar__icon-button"
        onClick={setToolbarVisible}
      >
        <LayerPressed />
      </button>
      {isVisible && <LayersToolbar setToolbarVisible={setToolbarVisible} />}
    </div>
  );
}

export default LayersToolbarButton;
