import React from 'react';

interface Props {
  text: string;
}

function SimpleLabel(props: Props) {
  const { text } = props;
  return (
    <div>
      <p style={{ color: '#999999', fontSize: '14px' }}>{text}</p>
    </div>
  );
}

export default SimpleLabel;
