import * as React from 'react';
import { connect } from 'react-redux';

import _isEqual from 'lodash.isequal';

import { RootState } from '../../store/configureStore';
import { selectError, setError } from '../../store/sidebarSlice';
import ErrorModalResponseView from './ErrorModalResponseView';
import ErrorModalTitleView from './ErrorModalTitleView';
import { IErrorModalProps, IErrorModalState } from './types';

/**
 * Основное модальное окно ошибки, выбрасывается на любую ошибку.
 */
class ErrorModal extends React.Component<IErrorModalProps> {
  textareaRef = React.createRef<HTMLTextAreaElement>();

  state: IErrorModalState = {
    show: false,
    errorText: '',
    type: 'response',
  };

  componentDidMount() {
    this.handleProps();
  }

  componentDidUpdate(prevProps: IErrorModalProps) {
    if (!_isEqual(prevProps.selectError, this.props.selectError)) {
      this.handleProps();
    }
  }

  handleProps = () => {
    const { selectError: errorFromStore } = this.props;
    if (errorFromStore) this.setState({ ...errorFromStore });
  };

  onClickHandler = () => this.props.setError({ show: false, type: 'response' });

  copyCodeHandler = () => {
    this.textareaRef.current?.select();
    document.execCommand('copy');
    this.setState({ show: false });
  };

  render() {
    const { errorText = '', type, show } = this.state;
    if (!show) return null;
    if (type === 'response') {
      return (
        <ErrorModalResponseView
          onClickHandler={this.onClickHandler}
          copyCodeHandler={this.copyCodeHandler}
          ref={this.textareaRef}
          errorText={errorText}
        />
      );
    }
    return (
      <ErrorModalTitleView
        onClickHandler={this.onClickHandler}
        errorText={errorText}
      />
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  selectError: selectError(state),
});

const mapDispatchToProps = { setError };

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
