import { Feature } from 'geojson';

type IFeature = {
  type: 'Feature';
  properties: any;
  geometry: {
    type: Feature['geometry']['type'];
    coordinates: number[] | number[][];
  };
};

export const composeFeature = (type: Feature['geometry']['type']) => (
  coordinates: any,
): IFeature => ({
  type: 'Feature',
  properties: {},
  geometry: {
    type,
    coordinates,
  },
});

export const handleClassName = (i: number) =>
  i % 2 !== 0 ? 'second' : 'first';

export const drawablePolygonPointsLayer: mapboxgl.AnyLayer = {
  id: 'point',
  type: 'circle',
  source: 'drawable-polygon',
  paint: {
    'circle-radius': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      6,
      4.5,
    ],
    'circle-color': ['match', ['get', 'order'], 'first', '#80a9ef', '#fff'],
  },
  filter: ['in', '$type', 'Point'],
};

export const drawablePolygonLineLayer: mapboxgl.AnyLayer = {
  id: 'drawable-polygon-line',
  type: 'line',
  source: 'drawable-polygon',
  paint: {
    'line-color': '#5F9BFA',
    'line-width': 2,
  },
  filter: ['in', 'type', 'polygon'],
};

export const drawablePolygonDashedLine: mapboxgl.AnyLayer = {
  id: 'dashed-line',
  type: 'line',
  source: 'dashed-line',
  paint: {
    'line-width': 2,
    'line-color': '#5F9BFA',
    'line-dasharray': [2, 10],
  },
  filter: ['in', 'type', 'dashed-line'],
};

export const borderCoords = [
  [-180, 85],
  [180, 85],
  [180, -85],
  [-180, -85],
];

export const overlayInitData = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        type: 'overlay',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [borderCoords],
      },
    },
  ],
} as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
