import React from 'react';

import { ReactComponent as FavoriteIcon } from 'assets/images/favorite.svg';

function Favorite(props: any) {
  return (
    <div>
      <FavoriteIcon />
    </div>
  );
}

export default Favorite;
