import gql from 'graphql-tag';

export default gql`
  mutation deleteConnection($input: DeleteConnectionInput!) {
    connection {
      delete(input: $input) {
        ok
      }
    }
  }
`;
