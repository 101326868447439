import * as React from 'react';

function CalculateRadioCircleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={8}
      height={8}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={4} cy={4} r={4} fill="#5F9BFA" />
    </svg>
  );
}

export default CalculateRadioCircleIcon;
