import * as React from 'react';

import { ReactComponent as ToCenter } from 'assets/images/to-center.svg';

const FlyToCenterButton: React.FC<{ flyTo: () => void }> = ({ flyTo }) => (
  <div className="button-container">
    <button type="button" onClick={flyTo} className="scale-buttons__button">
      <ToCenter />
    </button>
  </div>
);

export default FlyToCenterButton;
