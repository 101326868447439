import * as React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as BuildingsCount } from 'assets/images/buildings-count.svg';
import { ReactComponent as ListArea } from 'assets/images/list-area.svg';
import { ReactComponent as Remove } from 'assets/images/remove.svg';
import { ReactComponent as Star } from 'assets/images/star.svg';
import classnames from 'classnames';
import hostUrl from 'constants/hostUrl';
import { parseItemPolygon } from 'containers/Sidebar/utils';
import moment from 'moment';
import { handleError } from 'store/sidebarSlice';

import { calculateArea, formatter } from 'utils';
import IsFavoriteStarIcon from '../../elements/Icons/IsFavoriteStarIcon';
import { ITaskSlice } from '../../store/types';
import { handleStatus } from './utils';

interface Props {
  task: ITaskSlice;
  history: any;
  onRemoveHandler: (e?: any) => void;
}

function TaskListRow(props: Props) {
  const { task, history, onRemoveHandler } = props;

  const onClickHander = (e: any) => {
    if (e.metaKey) {
      window.open(`${hostUrl}/#/result/${task.id}`);
      return;
    }

    if (e.button === 1) {
      window.open(`${hostUrl}/#/result/${task.id}`);
      return;
    }

    history.push(`/result/${task.id}`);
  };

  const onRemoveTaskHandler = async () => onRemoveHandler(task);

  let areaInKm: number | string = '0';

  if (task.developmentArea) {
    const developmentAreaGeometry = parseItemPolygon({
      polygon: task.developmentArea,
    }).polygon;
    areaInKm = formatter.format(
      calculateArea(developmentAreaGeometry.coordinates[0]),
    );
  }

  // const dataTitle = favorite ? 'Убрать из избранного' : 'Добавить в избранное';

  return (
    <div className="relative task-list-row-container">
      <div
        // data-tip={dataTitle}
        className={classnames('task-list__favorite', {
          favorite: task.isFavorite,
        })}
      >
        {task.isFavorite ? <IsFavoriteStarIcon /> : <Star />}
      </div>

      <div className="task-list-div-for-hover" />
      <button
        className={classnames('task-list__remove', {
          favorite: task.isFavorite,
        })}
        type="button"
        onClick={onRemoveTaskHandler}
      >
        <Remove />
      </button>
      <div
        role="button"
        tabIndex={0}
        onKeyPress={onClickHander}
        onClick={onClickHander}
        onAuxClick={onClickHander}
        className="task-list-row"
      >
        <div>
          <div>
            <p className={classnames('task-list__status', [task.status])}>
              {handleStatus(task.status as any)}
            </p>
          </div>
          <p className="task-list__title">{task.name}</p>
          <div>
            <p className="task-list__bottom-text">
              {moment(task.created).format('YYYY-MM-DD HH:mm:ss ')}
            </p>
          </div>
        </div>
        <div>
          {/* <p className="task-list__count task-list__icon">
            <CalculationTypeIcon
              data-tip="Тип расчета"
              className="task-list-right-icon"
            />
            <span className="d-inline align-middle task-list__icon-text">
              {calculationTypeOptions[task.calculationType as any]}
            </span>
          </p> */}
          <p className="task-list__count task-list__icon">
            <BuildingsCount
              data-tip="Количество сооружений"
              className="task-list-right-icon"
            />
            <span className="d-inline align-middle task-list__icon-text">
              {task.buildings?.length}
            </span>
          </p>
          <p className="task-list__area task-list__icon">
            <ListArea
              data-multiline
              data-tip="Площадь допустимой области"
              className="task-list-right-icon"
            />
            <span className="d-inline align-middle task-list__icon-text task-list__area-text">
              {areaInKm} м
            </span>
          </p>
        </div>
      </div>
      <ReactTooltip
        place="bottom"
        effect="solid"
        className="custom-tooltip"
        backgroundColor="#242425"
        textColor="#F0F0F0"
        delayShow={1000}
      />
    </div>
  );
}

const mapDispatchToProps = { handleError };

export default connect(null, mapDispatchToProps)(TaskListRow);
