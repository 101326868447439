import gql from 'graphql-tag';

export default gql`
  mutation unlikeTask($taskId: Uuid!) {
    taskMutation {
      unlikeTask(taskId: $taskId) {
        recordId
      }
    }
  }
`;
