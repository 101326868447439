import React, { useEffect } from 'react';

import CalculateCheckboxCheckIcon from 'elements/Icons/CalculateCheckboxCheckIcon';
import CalculateCheckboxRectangleIcon from 'elements/Icons/CalculateCheckboxRectangleIcon';

import CalculateCheckboxIcon from '../Icons/CalculateCheckboxIcon';

interface Props {
  isRunning?: boolean;
  selectAll: boolean;
  selectSome?: boolean;
  disabled?: boolean;
  arg?: any;
  handleChange: (e?: any, a?: any) => void;
}

function CalculateCheckbox(props: Props) {
  const {
    isRunning,
    disabled,
    handleChange,
    arg,
    selectAll,
    selectSome,
  } = props;

  return (
    <button
      disabled={disabled}
      onClick={e => handleChange(e, arg)}
      type="button"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
        backgroundColor: disabled ? '#2F2F2F' : '#242425',
        border: '1px solid #4A4A4A',
        borderRadius: '3px',
      }}
    >
      {isRunning ? (
        <CalculateCheckboxIcon />
      ) : selectAll ? (
        <CalculateCheckboxCheckIcon />
      ) : selectSome ? (
        <CalculateCheckboxRectangleIcon />
      ) : null}
    </button>
  );
}

export default CalculateCheckbox;
