import * as React from 'react';

function BuildingsSearchSearchIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 10l3 3M7.167 11.167a4 4 0 100-8 4 4 0 000 8z"
        stroke="#999"
        strokeWidth={1.33}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BuildingsSearchSearchIcon;
