import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { calculateTask } from 'apollo/requests';
import hostUrl from 'constants/hostUrl';
import { handleError } from 'store/sidebarSlice';
import { updateTaskCalculations } from 'store/taskSlice';

import {
  cancelTaskCalculation,
  createTaskCalculation,
  deleteTaskCalculation,
} from '../../apollo/requests';
import SettingsIcon from '../../elements/Icons/SettingsIcon';
import { Method, Stage } from '../../generated/graphql';
import { RootState } from '../../store/configureStore';
import {
  IControlSlice,
  IDictionariesSlice,
  IMapSlice,
  ITaskSlice,
  IWebSocketSlice,
} from '../../store/types';
import { createTask } from './services';

interface Props {
  task: ITaskSlice;
  map: IMapSlice;
  dictionaries: IDictionariesSlice;
  websocket: IWebSocketSlice;
  control: IControlSlice;
}

function SidebarChangeButton(props: Props) {
  const { task, map, dictionaries, websocket, control } = props;

  const dispatch = useDispatch();
  const { taskId } = useParams() as any;
  const history = useHistory();

  const [isCalculate, setCalculate] = useState(false);
  const [buttonTitle, setButtonTitle] = useState<string>('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showSetDefaultButton, setShowSetDefaultButton] = useState(false);

  async function asyncForEach(array: any, callback: any) {
    for (let index: any = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const prepareDefaultScenario = async () => {
    console.log('prepareDefaultScenario');
    const defaultMethodsArr: any = [];
    const prevCalculations: any = JSON.parse(
      JSON.stringify(task.taskCalculations),
    );
    await asyncForEach(control.scenario?.stages, async (stage: Stage) => {
      console.log('STAGE', stage);
      await asyncForEach(stage.methods, async (method: Method) => {
        console.log('METHOD', method);
        if (method.isRunDefault) {
          if (stage.allowMultipleMethods) {
            const taskCalculationId = task?.taskCalculations?.find(
              item =>
                item?.methodId === method.id && item?.stageId === stage.id,
            );
            if (!taskCalculationId) {
              const response = await createTaskCalculation({
                taskId: task.id,
                stageId: stage.id,
                methodId: method.id,
              });
              console.log('RESPONSE', response);
              if (
                response &&
                response.taskCalculationMutation?.createTaskCalculation?.record
              ) {
                const record =
                  response.taskCalculationMutation?.createTaskCalculation
                    ?.record;

                defaultMethodsArr.push(record);
                // dispatch(updateTaskCalculations(prevCalculations));
              }
            }
          } else {
            const radioStageMethods = task?.taskCalculations?.find(
              item => item?.stageId === stage.id,
            );

            if (!radioStageMethods) {
              const response = await createTaskCalculation({
                taskId: task.id,
                stageId: stage.id,
                methodId: method.id,
              });
              console.log('RESPONSE', response);
              if (
                response &&
                response.taskCalculationMutation?.createTaskCalculation?.record
              ) {
                const record =
                  response.taskCalculationMutation?.createTaskCalculation
                    ?.record;

                // prevCalculations.push(record);
                defaultMethodsArr.push(record);
                // dispatch(updateTaskCalculations(prevCalculations));
              }
            } else {
              const taskCalculationId = task?.taskCalculations?.find(
                item => item?.stageId === stage.id,
              );

              const deleteResponse = await deleteTaskCalculation(
                taskCalculationId?.taskCalculationId,
              );

              if (
                deleteResponse &&
                deleteResponse.taskCalculationMutation?.deleteTaskCalculation
                  ?.ok
              ) {
                const findCalculation = prevCalculations.find(
                  (item: any) => item.stageId === stage.id,
                );

                const filterCalculations = prevCalculations.filter(
                  (item: any) =>
                    item.calculationId !== findCalculation.calculationId,
                );
                // defaultMethodsArr.push(filterCalculations);
                // dispatch(updateTaskCalculations(filterCalculations));

                const createResponse = await createTaskCalculation({
                  taskId: task.id,
                  stageId: stage.id,
                  methodId: method.id,
                });
                console.log('CREATE_RESPONSE', createResponse);
                if (
                  createResponse &&
                  createResponse.taskCalculationMutation?.createTaskCalculation
                    ?.record
                ) {
                  const record =
                    createResponse.taskCalculationMutation
                      ?.createTaskCalculation?.record;

                  const newArr = JSON.parse(JSON.stringify(filterCalculations));
                  // newArr.push(record);
                  defaultMethodsArr.push(record);
                  // dispatch(updateTaskCalculations(newArr));
                }
              }
            }
          }
        }
      });
    });

    dispatch(updateTaskCalculations(defaultMethodsArr));
  };

  const handleNewTask = async (id?: string) => {
    if (id) {
      history.replace(`/result/${id}`);
    }
  };

  const openTaskOnNewTab = async (id?: string) => {
    if (id) {
      window.open(`${hostUrl}/#/result/${id}`);
    }
  };

  const onCalculateTask = async () => {
    const sendObj = { taskId: task.id, stageMethods: [] };

    const newArr: any = [];

    task?.taskCalculations?.forEach(item => {
      newArr.push({ stageId: item?.stageId, methodId: item?.methodId });
    });

    sendObj.stageMethods = newArr;

    const response = await calculateTask(sendObj);

    if (response && response.taskMutation?.calculateTask?.recordId) {
      if (task.id !== response.taskMutation?.calculateTask?.recordId) {
        openTaskOnNewTab(response.taskMutation?.calculateTask?.recordId);
      }
    }
  };

  const onCreateTask = async () => {
    try {
      const developmentArea = JSON.stringify(map.geometry);
      const userId =
        dictionaries.allUsers && dictionaries.allUsers.length
          ? dictionaries.allUsers[0]?.id
          : null;
      const prepareTask = {
        userId,
        demId: task.demId,
        name: task.name,
        regionId: task.regionId,
        licenseAreaId: task.licenseAreaId,
        windRoseAngleDeg:
          typeof task.windRoseAngleDeg === 'string'
            ? parseFloat(task.windRoseAngleDeg)
            : task.windRoseAngleDeg,
        scenarioId: task.scenarioId,
      };
      const id = await createTask({
        taskInput: {
          ...prepareTask,
          developmentArea,
        },
      });

      handleNewTask(id);
    } catch (error) {
      dispatch(handleError(error));
    }
  };

  const onSubmitHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (taskId) {
      if (task.status === 'DRAFT' || task.status === 'FAILED') {
        setButtonDisabled(true);
        onCalculateTask();
      }
    } else {
      onCreateTask();
    }
  };

  const handleTaskTraceData = () => {
    const taskStatus = websocket.taskStatus;

    if (taskStatus) {
      if (taskStatus === 'FAILED') {
        setButtonDisabled(true);
        setCalculate(false);
        setButtonTitle('Рассчитать');
      }

      if (taskStatus === 'DRAFT') {
        setButtonDisabled(false);
        setCalculate(false);
        setButtonTitle('Рассчитать');
      }

      if (taskStatus === 'SOLVING') {
        setButtonDisabled(false);
        setCalculate(true);
        setButtonTitle('Остановить расчет');
      }

      if (taskStatus === 'SOLVED') {
        setButtonDisabled(true);
        setCalculate(false);
        setButtonTitle('Рассчитать');
      }
    }
  };

  const cancelCalculation = async () => {
    await cancelTaskCalculation(task.id);
  };

  useEffect(() => {
    if (map.geometry) {
      setButtonDisabled(false);
    }
  }, [map]);

  useEffect(() => {
    if (!task.id) {
      setButtonTitle('Создать задачу');
      setButtonDisabled(true);
    } else if (websocket.taskStatus) {
      handleTaskTraceData();
    }
  }, [websocket.taskStatus, task.id]);

  return (
    <div className="change__button_container" style={{ position: 'relative' }}>
      {task.id && task.status === 'DRAFT' ? (
        <button
          type="button"
          onClick={() => setShowSetDefaultButton(!showSetDefaultButton)}
          style={{ marginRight: '15px' }}
        >
          <button
            type="button"
            onClick={() => prepareDefaultScenario()}
            style={{
              width: '290px',
              height: '50px',
              position: 'absolute',
              top: '-30px',
              left: '15px',
              backgroundColor: '#1D1D1F',
              padding: '10px 15px',
              borderRadius: '3px',
              display: showSetDefaultButton ? 'flex' : 'none',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p style={{ color: '#CCCDCD', fontSize: '14px' }}>
              Выбрать этапы и методы по умолчанию
            </p>
          </button>
          <SettingsIcon />
        </button>
      ) : null}
      {isCalculate ? (
        <div
          style={{
            position: 'absolute',
            top: '-40px',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'stretch',
              backgroundColor: '#242425',
              padding: '12px',
            }}
          >
            <p>Идет расчет</p>
          </div>
        </div>
      ) : null}
      <button
        disabled={buttonDisabled}
        type="button"
        className={
          isCalculate ? 'change__button_button_stop' : 'change__button_button'
        }
        onClick={isCalculate ? cancelCalculation : onSubmitHandler}
      >
        <p style={{ color: isCalculate ? '#B4B4B4' : '#fff' }}>{buttonTitle}</p>
      </button>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  task: state.task,
  map: state.map,
  dictionaries: state.dictionaries,
  websocket: state.websocket,
});

export default connect(mapStateToProps)(SidebarChangeButton);
