import * as React from 'react';

function ConfirmIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.805 3.535c.26.26.26.683 0 .943L6.47 11.81a.667.667 0 01-.942 0L2.195 8.478a.667.667 0 11.943-.943L6 10.397l6.862-6.862c.26-.26.682-.26.943 0z"
        fill="#737373"
      />
    </svg>
  );
}

export default ConfirmIcon;
