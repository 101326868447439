import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import SidebarTabs from './Tabs/SidebarTabs';

import './sidebar.scss';

const Sidebar: React.FC<RouteComponentProps> = () => {
  return (
    <div className="sidebar">
      <div className="slidebar__container">
        <SidebarTabs />
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
