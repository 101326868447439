import * as React from 'react';

import PopupDemText from './PopupDemText';
import PopupErrorText from './PopupErrorText';
import { PopupPropsType } from './types';

export const PopupText: React.FC<Omit<PopupPropsType, 'containerPoint'>> = ({
  updatedArea,
  error,
}) => {
  if (error) return <PopupErrorText />;

  return <PopupDemText updatedArea={updatedArea} />;
};

export default PopupText;
