import gql from 'graphql-tag';

export const UTILITY_LINE_FRAGMENT = gql`
  fragment UtilityLineFragment on UtilityLine {
    id
    line
    polygon
    startNodeId
    endNodeId
    communicationNetworkId
  }
`;
