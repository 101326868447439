import * as React from 'react';

function CalculateCheckboxRectangleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={8}
      height={8}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x={0.04} width={8} height={8} rx={2} fill="#5F9BFA" />
    </svg>
  );
}

export default CalculateCheckboxRectangleIcon;
