import gql from 'graphql-tag';

export default gql`
  mutation createTaskCalculationByStage(
    $input: CreateTaskCalculationByStageInput!
  ) {
    taskCalculationMutation {
      createTaskCalculationByStage(input: $input) {
        record {
          taskCalculationId
          taskId
          calculationId
          stageId
          methodId
          isFinalResult
          status
        }
      }
    }
  }
`;
