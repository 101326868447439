import * as React from 'react';

import { ZoomButtonPropsType } from './types';

const ZoomButton: React.FC<ZoomButtonPropsType> = props => {
  const onZoomHandler = () => props.onZoomHandler(props.type);

  return (
    <button
      type="button"
      onClick={onZoomHandler}
      className="scale-buttons__zoom-button"
    >
      {props.children}
    </button>
  );
};

export default ZoomButton;
