import gql from 'graphql-tag';

export default gql`
  mutation calculateTask($input: CalculateTaskInput!) {
    taskMutation {
      calculateTask(input: $input) {
        recordId
      }
    }
  }
`;
