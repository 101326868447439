import * as React from 'react';

function IsFavoriteStarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.14 10.638a.3.3 0 00-.28 0l-3.2 1.683a.3.3 0 01-.435-.316l.611-3.564a.3.3 0 00-.086-.265L1.16 5.652a.3.3 0 01.167-.512l3.578-.52a.3.3 0 00.226-.164l1.6-3.242a.3.3 0 01.538 0l1.6 3.242a.3.3 0 00.226.164l3.578.52a.3.3 0 01.166.512L10.25 8.176a.3.3 0 00-.086.265l.611 3.563a.3.3 0 01-.435.317l-3.2-1.683z"
        fill="#F0F0F0"
        stroke="#F0F0F0"
        strokeLinecap="square"
      />
    </svg>
  );
}

export default IsFavoriteStarIcon;
