import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITaskSlice } from './types';

const initialState: ITaskSlice = {
  id: null,
  created: null,
  updated: null,
  name: null,
  isFavorite: false,
  developmentArea: null,
  windRoseAngleDeg: null,
  licenseAreaId: null,
  scenarioId: null,
  regionId: null,
  demId: null,
  userId: null,
  status: null,
  buildings: null,
  externalPoints: null,
  sitePlan: null,
  center: null,
  buildingConnections: null,
  taskCalculations: null,
  // stageMethods: null,
  isFetching: false,
};

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setTask: (state, action: PayloadAction<ITaskSlice>) => {
      return {
        ...state,
        ...action.payload,
        isFetching: true,
      };
    },
    updateExternalPoints: (
      state,
      action: PayloadAction<ITaskSlice['externalPoints']>,
    ) => {
      return {
        ...state,
        externalPoints: action.payload,
      };
    },
    updateTaskFields: (state, action: PayloadAction<ITaskSlice>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateTaskBuildings: (
      state,
      action: PayloadAction<ITaskSlice['buildings']>,
    ) => {
      return {
        ...state,
        buildings: action.payload,
      };
    },
    updateTaskFavorite: (
      state,
      action: PayloadAction<ITaskSlice['isFavorite']>,
    ) => {
      return {
        ...state,
        isFavorite: action.payload,
      };
    },
    updateTaskName: (state, action: PayloadAction<ITaskSlice['name']>) => {
      return {
        ...state,
        name: action.payload,
      };
    },
    updateTaskBuildingsConnections: (
      state,
      action: PayloadAction<ITaskSlice['buildingConnections']>,
    ) => {
      return {
        ...state,
        buildingConnections: action.payload,
      };
    },
    updateTaskStatus: (state, action: PayloadAction<ITaskSlice['status']>) => {
      return {
        ...state,
        status: action.payload,
      };
    },
    updateTaskCalculations: (
      state,
      action: PayloadAction<ITaskSlice['taskCalculations']>,
    ) => {
      return {
        ...state,
        taskCalculations: action.payload,
      };
    },
    updateTaskCalculationStatus: (state, action: PayloadAction<any>) => {
      const currentTaskCalculation = state?.taskCalculations?.find(
        item => item?.taskCalculationId === action.payload.taskCalculationId,
      );

      if (currentTaskCalculation) {
        const taskCalculationsCopy = JSON.parse(
          JSON.stringify(state.taskCalculations),
        );

        const newArr = taskCalculationsCopy.map((item: any) => {
          if (item.taskCalculationId === action.payload.taskCalculationId) {
            item.status = action.payload.status;
          }

          return item;
        });

        return {
          ...state,
          taskCalculations: newArr,
        };
      }

      return {
        ...state,
      };
    },
    setTaskInitial: () => {
      return initialState;
    },
  },
});

export const {
  setTask,
  setTaskInitial,
  updateExternalPoints,
  updateTaskFields,
  updateTaskBuildings,
  updateTaskName,
  updateTaskFavorite,
  updateTaskStatus,
  updateTaskBuildingsConnections,
  updateTaskCalculations,
  updateTaskCalculationStatus,
} = taskSlice.actions;

export default taskSlice.reducer;
