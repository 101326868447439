import * as React from 'react';
import { MapContext } from 'react-mapbox-gl';

import update from 'immutability-helper';
import _isEqual from 'lodash.isequal';
import { GeoJSONSource } from 'mapbox-gl';

import { IDashedLineProps, IDashedLineState } from './types';
import { composeFeature, drawablePolygonDashedLine } from './utils';

/**
 * Вспомогательная линия которая тянется за курсором при рисовании.
 */
class DashedLine extends React.Component<IDashedLineProps> {
  state: IDashedLineState = {
    polylinePoints: [],
  };

  map?: mapboxgl.Map = this.context;

  componentDidMount() {
    this.handleCoords();
    this.bindHandlers();
  }

  componentDidUpdate(prevProps: IDashedLineProps) {
    if (!_isEqual(prevProps.dashedLinePoints, this.props.dashedLinePoints)) {
      this.handleCoords();
    }
  }

  componentWillUnmount() {
    this.unbindHandlers();
  }

  bindHandlers = () => {
    this.map?.on('mousemove', this.onMove);
  };

  unbindHandlers = () => {
    const source = this.map?.getSource('dashed-line') as GeoJSONSource;
    if (source) {
      this.map?.removeLayer('dashed-line');
      this.map?.removeSource('dashed-line');
    }
    this.map?.off('mousemove', this.onMove);
  };

  onMove = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const { lng, lat } = e.lngLat;
    const { polylinePoints } = this.state;

    const points = update(polylinePoints, {
      1: { $set: [lng, lat] },
    });

    const lineFeatures = composeFeature('LineString')(
      points.filter(el => !!el),
    );
    lineFeatures.properties.type = 'dashed-line';

    const data = {
      type: 'FeatureCollection',
      features: [lineFeatures],
    } as GeoJSON.FeatureCollection<GeoJSON.Geometry>;

    const source = this.map?.getSource('dashed-line') as GeoJSONSource;

    if (source) {
      source.setData(data);
    } else {
      this.map?.addSource('dashed-line', {
        type: 'geojson',
        generateId: true,
        data,
      });
      this.map?.addLayer(drawablePolygonDashedLine);
    }

    this.setState({ polylinePoints: points });
  };

  handleCoords = () => {
    const { dashedLinePoints } = this.props;
    if (!dashedLinePoints.length) {
      this.setState({
        polylinePoints: [],
      });
    } else {
      const { polylinePoints } = this.state;

      let pointsArray = update(polylinePoints, {
        0: { $set: dashedLinePoints[0] },
      });

      const polyCoorsLength = dashedLinePoints.length;

      if (polyCoorsLength > 1) {
        pointsArray = update(pointsArray, {
          2: { $set: dashedLinePoints[polyCoorsLength - 1] },
        });
      }

      this.setState({ polylinePoints: pointsArray });
    }
  };

  render() {
    return null;
  }
}

DashedLine.contextType = MapContext;

export default DashedLine;
