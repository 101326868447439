import * as React from 'react';

function SettingsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.347 12.783a5.9 5.9 0 00.05-.783 4.82 4.82 0 00-.058-.783L20.03 9.9a.409.409 0 00.1-.508l-1.6-2.767a.407.407 0 00-.491-.183l-1.992.8a5.887 5.887 0 00-1.35-.784l-.3-2.116a.403.403 0 00-.4-.342h-3.2c-.2 0-.358.142-.392.342l-.3 2.116c-.491.2-.941.475-1.35.784l-1.991-.8a.398.398 0 00-.492.183L4.68 9.392c-.1.175-.066.391.1.508l1.692 1.317a4.89 4.89 0 00-.075.783c0 .258.017.533.058.783L4.764 14.1a.409.409 0 00-.1.508l1.6 2.767c.1.183.308.242.491.183l1.992-.8c.417.317.858.584 1.35.784l.3 2.116c.042.2.2.342.4.342h3.2c.2 0 .367-.142.392-.342l.3-2.116c.491-.2.941-.467 1.35-.784l1.991.8c.184.067.392 0 .492-.183l1.6-2.767c.1-.183.058-.391-.1-.508l-1.675-1.317zM12.397 15c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"
        fill="#717171"
      />
    </svg>
  );
}

export default SettingsIcon;
