import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import hostUrl from 'constants/hostUrl';
import restApiUrl from 'constants/restApiUrl';
import Divider from 'elements/Divider';
import RowsIcon from 'elements/Icons/RowsIcon';
import { setTask } from 'store/taskSlice';

import { cloneTask, getTask } from '../../apollo/requests';
import { CloneTaskMutation } from '../../generated/graphql';
import { RootState } from '../../store/configureStore';
import { ITaskSlice } from '../../store/types';

function RowsHeaderButton(props: any) {
  const [showMoreContent, setShowMoreContent] = useState(false);

  const handleShowMoreContent = () => {
    setShowMoreContent(!showMoreContent);
  };

  return (
    <div style={{ position: 'relative' }}>
      <button
        type="button"
        onClick={handleShowMoreContent}
        className="moreButton"
      >
        <RowsIcon />
      </button>
      {showMoreContent && (
        <RowsHeaderButtonContent
          handleOpen={handleShowMoreContent}
          {...props}
        />
      )}
    </div>
  );
}

interface RowsHeaderButtonContentProps {
  task: ITaskSlice;
  setDeleteModalData: any;
  setTaskNameActive: any;
  handleOpen: any;
}

function RowsHeaderButtonContent(props: RowsHeaderButtonContentProps) {
  const { task, handleOpen, setTaskNameActive, setDeleteModalData } = props;
  const history = useHistory();
  const wrapperRef = useRef<any>(null);
  const { taskId } = useParams() as any;

  const dispatch = useDispatch();

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      handleOpen();
    }
  };

  const handleNewTask = async (id?: string) => {
    if (id) {
      window.open(`${hostUrl}/#/result/${id}`);
    }
  };

  const onCloneTask = async () => {
    const data = (await cloneTask({ taskId })) as CloneTaskMutation;
    if (data && data.taskMutation) {
      await handleNewTask(data.taskMutation?.cloneTask?.recordId);
    }
  };

  const handleChangeTask = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (taskId) {
      onCloneTask();
    }
  };

  const loadBuildings = () => {
    const input = document.createElement('input');
    input.type = 'file';

    input.onchange = async (e: any) => {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const url = `${restApiUrl}/tasks/${taskId}/upload_xlsx`;
      const response = await fetch(url, { method: 'POST', body: formData });
      if (response.status === 200) {
        const fetchTask = await getTask(taskId);
        dispatch(setTask(fetchTask as ITaskSlice));
      }
    };

    input.click();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={wrapperRef}
      style={{
        position: 'absolute',
        top: 30,
        left: 0,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#1D1D1F',
        padding: '10px 15px',
        borderRadius: '3px',
        zIndex: 10000,
        width: '180px',
      }}
    >
      <button
        type="button"
        onClick={() => history.push('/task-list')}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          padding: '7px 0',
        }}
      >
        <p
          style={{
            color: '#F0F0F0',
            fontSize: '14px',
            lineHeight: '16px',
            textAlign: 'start',
          }}
        >
          К списку проектов
        </p>
      </button>
      <Divider height="10px" />
      <Divider isLine lineColor="#464646" />
      <Divider height="10px" />
      <button
        type="button"
        disabled={!task.id}
        onClick={handleChangeTask}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          padding: '7px 0',
        }}
      >
        <p
          style={{
            color: !task.id ? '#737373' : '#F0F0F0',
            fontSize: '14px',
            lineHeight: '16px',
          }}
        >
          Изменить данные
        </p>
      </button>
      <button
        type="button"
        disabled={task.status !== 'DRAFT'}
        onClick={loadBuildings}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          padding: '7px 0',
        }}
      >
        <p
          style={{
            color: '#F0F0F0',
            fontSize: '14px',
            lineHeight: '16px',
          }}
        >
          Загрузить сооружения
        </p>
      </button>
      <Divider height="10px" />
      <Divider isLine lineColor="#464646" />
      <Divider height="10px" />
      <button
        type="button"
        onClick={setTaskNameActive}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          padding: '7px 0',
        }}
      >
        <p style={{ color: '#F0F0F0', fontSize: '14px', lineHeight: '16px' }}>
          Переименовать
        </p>
      </button>
      <Divider height="10px" />
      <button
        type="button"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          padding: '7px 0',
        }}
        onClick={() => setDeleteModalData(task.name, task.created, task.id)}
      >
        <p style={{ color: '#F0F0F0', fontSize: '14px', lineHeight: '16px' }}>
          Удалить
        </p>
      </button>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  task: state.task,
});

export default connect(mapStateToProps)(RowsHeaderButton);
