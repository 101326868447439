import React from 'react';

import CalculateCheckboxIcon from 'elements/Icons/CalculateCheckboxIcon';
import CalculateRadioCircleIcon from 'elements/Icons/CalculateRadioCircleIcon';

interface Props {
  isRunning?: boolean;
  disabled?: boolean;
  active: boolean;
  handleChange: (e?: any, a?: any) => void;
}

function CalculateRadioButton(props: Props) {
  const { isRunning, disabled, active, handleChange } = props;

  return (
    <button
      disabled={disabled}
      onClick={handleChange}
      type="button"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
        backgroundColor: disabled ? '#2F2F2F' : '#242425',
        border: '1px solid #4A4A4A',
        borderRadius: '12px',
      }}
    >
      {active ? (
        <CalculateRadioCircleIcon />
      ) : isRunning ? (
        <CalculateCheckboxIcon />
      ) : null}
    </button>
  );
}

export default CalculateRadioButton;
