import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  Uuid: any;
  /**
   * Custom class for Polygon.
   *
   * Expect `JSONString` for polygon
   * (ex. `"{\"type\": \"Polygon\", \"coordinates\": [[[0, 0], [0, 1], [1, 1], [1, 0], [0, 0]]]}"`)
   *
   * Serialize in `JSONString`. Deserialize in `shapely.geometry.Polygon`
   *
   * The specification of GeoJSON-format is here.](https://tools.ietf.org/html/rfc7946#section-3.1.2)
   */
  GeoJsonPolygon: any;
  /**
   * Custom class for Point.
   *
   * Expect `JSONString` for point (ex. `"{\"type\": \"Point\", \"coordinates\": [1.0, 0.0]}"`)
   *
   * Serialize in `JSONString`. Deserialize in `shapely.geometry.Point`
   *
   * The specification of GeoJSON-format is here.](https://tools.ietf.org/html/rfc7946#section-3.1.2)
   */
  GeoJsonPoint: any;
  /**
   * Custom class for LineString.
   *
   * Expect `JSONString` for point
   * (ex. `"{\"type\": \"LineString\", \"coordinates\": [[0, 0], [0, 1], [1, 1]]}"`)
   *
   * Serialize in `JSONString`. Deserialize in `shapely.geometry.LineString`
   *
   * The specification of GeoJSON-format is here.](https://tools.ietf.org/html/rfc7946#section-3.1.2)
   */
  GeoJsonLineString: any;
  /**
   * Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  CustomJSONString: any;
};

/** Root Query */
export type Query = {
  /** Список всех типов сооружений */
  allBuildingTypes?: Maybe<Array<Maybe<BuildingType>>>;
  /** Список всех типов соединений */
  allConnectionTypes?: Maybe<Array<Maybe<ConnectionType>>>;
  /** Список всех типов точек подключения */
  allConnectionPointTypes?: Maybe<Array<Maybe<ConnectionPointType>>>;
  /** Список всех цифровых моделей рельефа. */
  allDems?: Maybe<Array<Maybe<Dem>>>;
  /** Список всех лицензионных участков. */
  allLicenseAreas?: Maybe<Array<Maybe<LicenseArea>>>;
  /** Список всех регионов */
  allRegions?: Maybe<Array<Maybe<Region>>>;
  /** Список всех расчетных задач. */
  allTasks: TaskPagination;
  /** Список всех пользователей */
  allUsers?: Maybe<Array<Maybe<User>>>;
  /** Список всех типов содержимого. */
  allContentTypes?: Maybe<Array<Maybe<ContentType>>>;
  /** Список всех сценариев расчета */
  allScenarios?: Maybe<Array<Maybe<Scenario>>>;
  /** Список всех этапов расчета */
  allStages?: Maybe<Array<Maybe<Stage>>>;
  /** Коммуникации между сооружениями */
  buildingConnections?: Maybe<Array<Maybe<BuildingConnection>>>;
  /** Внешние соединения */
  externalConnections: Array<Maybe<ExternalConnection>>;
  /** Тип сооружения. */
  buildingType?: Maybe<BuildingType>;
  /** Параметры сооружения. */
  building?: Maybe<Building>;
  /** Коммуникации, идущие от сооружения */
  connectionsFromBuilding?: Maybe<Array<Maybe<ConnectionFromBuilding>>>;
  /** Точка подключения */
  connectionPoint?: Maybe<ConnectionPoint>;
  /** Тип точки подключения */
  connectionPointType?: Maybe<ConnectionPointType>;
  /** Тип соединения */
  connectionType?: Maybe<ConnectionType>;
  /** Тип области в заданной точке местности. */
  costAreaType: CostAreaType;
  /** Цифровая модель рельефа. */
  dem?: Maybe<Dem>;
  /** Лицензионный участок */
  licenseArea?: Maybe<LicenseArea>;
  /** Нода */
  node?: Maybe<Node>;
  /** Центр сооружения */
  buildingCenterByTaskId?: Maybe<BuildingCenterWrapper>;
  /** Регион */
  region?: Maybe<Region>;
  /** Расчетная задача. */
  task?: Maybe<Task>;
  /** Выбор пользователя по методу расчета и его статус */
  taskCalculation?: Maybe<TaskCalculation>;
  /** Выбор пользователя по методу расчета и его статус */
  taskCalculationByTaskId?: Maybe<TaskCalculation>;
  /** Сценарий расчета */
  scenario?: Maybe<Scenario>;
  /** Этапы расчета в сценариях */
  stage?: Maybe<Stage>;
  /** Местоположение центра площадки */
  sitePosition?: Maybe<SitePosition>;
  /** Данные фичи */
  feature?: Maybe<FeatureData>;
  /** Проверка работоспособности */
  healthCheck?: Maybe<HealthCheck>;
};


/** Root Query */
export type QueryAllTasksArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  onlyFavorites?: Maybe<Scalars['Boolean']>;
};


/** Root Query */
export type QueryBuildingConnectionsArgs = {
  taskId: Scalars['Uuid'];
};


/** Root Query */
export type QueryExternalConnectionsArgs = {
  taskId: Scalars['Uuid'];
};


/** Root Query */
export type QueryBuildingTypeArgs = {
  id: Scalars['Uuid'];
};


/** Root Query */
export type QueryBuildingArgs = {
  id: Scalars['Uuid'];
  taskId: Scalars['Uuid'];
};


/** Root Query */
export type QueryConnectionsFromBuildingArgs = {
  buildingId: Scalars['Uuid'];
};


/** Root Query */
export type QueryConnectionPointArgs = {
  id: Scalars['Uuid'];
};


/** Root Query */
export type QueryConnectionPointTypeArgs = {
  id: Scalars['Uuid'];
};


/** Root Query */
export type QueryConnectionTypeArgs = {
  id: Scalars['Uuid'];
};


/** Root Query */
export type QueryCostAreaTypeArgs = {
  licenseAreaId: Scalars['Uuid'];
  lon: Scalars['Float'];
  lat: Scalars['Float'];
};


/** Root Query */
export type QueryDemArgs = {
  id: Scalars['Uuid'];
};


/** Root Query */
export type QueryLicenseAreaArgs = {
  id: Scalars['Uuid'];
};


/** Root Query */
export type QueryNodeArgs = {
  id: Scalars['Uuid'];
};


/** Root Query */
export type QueryBuildingCenterByTaskIdArgs = {
  buildingId: Scalars['Uuid'];
  taskId: Scalars['Uuid'];
};


/** Root Query */
export type QueryRegionArgs = {
  id: Scalars['Uuid'];
};


/** Root Query */
export type QueryTaskArgs = {
  taskId: Scalars['Uuid'];
};


/** Root Query */
export type QueryTaskCalculationArgs = {
  id: Scalars['Uuid'];
};


/** Root Query */
export type QueryTaskCalculationByTaskIdArgs = {
  taskId: Scalars['Uuid'];
  stageId: Scalars['Uuid'];
  methodId: Scalars['Uuid'];
};


/** Root Query */
export type QueryScenarioArgs = {
  id: Scalars['Uuid'];
};


/** Root Query */
export type QueryStageArgs = {
  id: Scalars['Uuid'];
};


/** Root Query */
export type QuerySitePositionArgs = {
  taskId: Scalars['Uuid'];
};


/** Root Query */
export type QueryFeatureArgs = {
  calculationId: Scalars['Uuid'];
  featureId: Scalars['Uuid'];
  taskId: Scalars['Uuid'];
};

/** Типы сооружений */
export type BuildingType = {
  id?: Maybe<Scalars['Uuid']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};


/** Тип/способ соединения между сооружениями */
export type ConnectionType = {
  id?: Maybe<Scalars['Uuid']>;
  name?: Maybe<Scalars['String']>;
  widthM?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
};

/** Тип точки подключения */
export type ConnectionPointType = {
  id?: Maybe<Scalars['Uuid']>;
  name?: Maybe<Scalars['String']>;
  isExternal?: Maybe<Scalars['Boolean']>;
};

/** Рельеф */
export type Dem = {
  id?: Maybe<Scalars['Uuid']>;
  demType?: Maybe<DemType>;
};

/**
 * Тип рельефа
 *
 * Attributes:
 *     :ZERO: нулевой рельеф
 *     :SRTM:
 *     :CUSTOM:
 */
export type DemType =
  | 'ZERO'
  | 'SRTM'
  | 'CUSTOM';

/** Лицензионный участок */
export type LicenseArea = {
  id?: Maybe<Scalars['Uuid']>;
  name?: Maybe<Scalars['String']>;
  polygon?: Maybe<Scalars['GeoJsonPolygon']>;
};


/** Регион */
export type Region = {
  id?: Maybe<Scalars['Uuid']>;
  name?: Maybe<Scalars['String']>;
};

export type TaskPagination = {
  tasks?: Maybe<Array<Maybe<Task>>>;
  pageInfo?: Maybe<PageInfo>;
};

/** Задача на расчет */
export type Task = {
  id?: Maybe<Scalars['Uuid']>;
  created?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  /** Статус задачи */
  status?: Maybe<TaskStatus>;
  /** id пользователя */
  userId?: Maybe<Scalars['Uuid']>;
  /** id родительской задачи */
  parentTaskId?: Maybe<Scalars['Uuid']>;
  /** id сценария */
  scenarioId?: Maybe<Scalars['Uuid']>;
  /** id региона */
  regionId?: Maybe<Scalars['Uuid']>;
  /** Преобладающий угол ветров в градусах */
  windRoseAngleDeg?: Maybe<Scalars['Float']>;
  /** id лицензионного участка */
  licenseAreaId?: Maybe<Scalars['Uuid']>;
  /** id цифровой модели рельефа */
  demId?: Maybe<Scalars['Uuid']>;
  /** Центр допустимой области */
  center?: Maybe<Scalars['GeoJsonPoint']>;
  /** Допустимая область */
  developmentArea?: Maybe<Scalars['GeoJsonPolygon']>;
  /** Характеристики внешнего мира */
  landCharacteristics?: Maybe<LandCharacteristics>;
  /** Список внешних точек */
  externalPoints?: Maybe<Array<Maybe<ExternalPoint>>>;
  /** Ограничивающий многоугольник генплана */
  boundingPolygon?: Maybe<BoundingPolygon>;
  /** Характеристики генплана */
  sitePlanParams?: Maybe<SitePlanParams>;
  /** Стоимостные показатели генплана */
  sitePreparation?: Maybe<SitePreparation>;
  /** Характеристики и стоимостные показатели генплана */
  sitePlan?: Maybe<SitePlan>;
  /** Полигон внутриплощадочных проездов генплана */
  road?: Maybe<Road>;
  /** Линии строительной сетки */
  gridLines?: Maybe<Array<Maybe<GridLine>>>;
  /** Линии проездов */
  roadLines?: Maybe<Array<Maybe<RoadLine>>>;
  /** Коридоры коммуникаций */
  corridors?: Maybe<Array<Maybe<Corridor>>>;
  /** Сооружения */
  buildings?: Maybe<Array<Maybe<Building>>>;
  /** Технологические линии связи */
  communicationNetwork?: Maybe<CommunicationNetwork>;
  /** Ограждения кластеров */
  barriers?: Maybe<Array<Maybe<Barrier>>>;
  /** Информация по методам расчета этапов */
  taskCalculations?: Maybe<Array<Maybe<TaskCalculation>>>;
  /** Информация о сценарии */
  scenario?: Maybe<Scenario>;
};

/**
 * Статус задачи
 *
 * Attributes:
 *     :DRAFT: задача создана, но еще не отправлена на расчет
 *     :SENT: задача отправлена на расчет
 *     :SOLVING: задача в процессе расчета
 *     :SOLVED: задача завершена успешно
 *     :FAILED: задача завершена с ошибкой
 *     :CANCELLED: расчет был остановлен из-за перезапуска сервера
 */
export type TaskStatus =
  | 'DRAFT'
  | 'SENT'
  | 'SOLVING'
  | 'SOLVED'
  | 'FAILED'
  | 'CANCELLED';


/** Характеристики внешнего мира */
export type LandCharacteristics = {
  id?: Maybe<Scalars['Uuid']>;
  /** Допустимая область */
  developmentArea?: Maybe<Scalars['GeoJsonPolygon']>;
  /** Преобладающий угол ветров в градусах */
  windRoseAngleDeg?: Maybe<Scalars['Float']>;
  /** id лицензионного участка */
  licenseAreaId?: Maybe<Scalars['Uuid']>;
  /** id региона */
  regionId?: Maybe<Scalars['Uuid']>;
  /** id цифровой модели рельефа */
  demId?: Maybe<Scalars['Uuid']>;
  /** id задачи */
  taskId?: Maybe<Scalars['Uuid']>;
  /** Центр допустимой области */
  center?: Maybe<Scalars['GeoJsonPoint']>;
};

/** Внешние точки */
export type ExternalPoint = {
  id?: Maybe<Scalars['Uuid']>;
  point?: Maybe<Scalars['GeoJsonPoint']>;
  typeId?: Maybe<Scalars['Uuid']>;
  connectionPointType?: Maybe<ConnectionPointType>;
  externalConnectionId?: Maybe<Scalars['Uuid']>;
};

/** Ограничивающий многоугольник генплана */
export type BoundingPolygon = {
  id?: Maybe<Scalars['Uuid']>;
  /** Ограничивающий многоугольник генплана */
  polygon?: Maybe<Scalars['GeoJsonPolygon']>;
  /** Периметр ограничивающего многоугольника генплана, метры */
  perimeterM?: Maybe<Scalars['Float']>;
  /** Площадь ограничивающего многоугольника генплана, метры кв. */
  area?: Maybe<Scalars['Float']>;
  /** id расчета этапа */
  calculationId?: Maybe<Scalars['Uuid']>;
};

/** Характеристики генплана */
export type SitePlanParams = {
  id?: Maybe<Scalars['Uuid']>;
  /** Коэффициент застройки территории */
  buildingCoverageRatio?: Maybe<Scalars['Float']>;
  /** Площадь застройки */
  buildingArea?: Maybe<Scalars['Float']>;
  /** Площадь объекта */
  sitePlanArea?: Maybe<Scalars['Float']>;
  /** Площадь внутриплощадных проездов */
  internalRoadsArea?: Maybe<Scalars['Float']>;
  /** Суммарная длина технологических связей */
  totalUtilityLinesLength?: Maybe<Scalars['Float']>;
  /** Длина эстакад коммуникаций */
  corridorTotalLengthM?: Maybe<Scalars['Float']>;
  /** Общая длина трубопроводов */
  pipelineTotalLengthM?: Maybe<Scalars['Float']>;
  /** Общая длина кабельных коммуникаций */
  powerlineTotalLengthM?: Maybe<Scalars['Float']>;
  /** Расстояние возки материалов от карьеров */
  transportationDistance?: Maybe<Scalars['Float']>;
  /** id расчета этапа */
  calculationId?: Maybe<Scalars['Uuid']>;
};

/** Стоимостные показатели генплана */
export type SitePreparation = {
  id?: Maybe<Scalars['Uuid']>;
  /** Итоговая стоимость инженерной подготовки */
  preparationCost?: Maybe<Scalars['Float']>;
  /** Земляные работы */
  earthworkM3?: Maybe<Scalars['Float']>;
  /** Песчанный грунт */
  sandM3?: Maybe<Scalars['Float']>;
  /** Лежневой настил */
  coverM2?: Maybe<Scalars['Float']>;
  /** Прослойка из геотекстиля */
  geotextileLayerM2?: Maybe<Scalars['Float']>;
  /** Теплоизоляционные плиты под откосы (ММГ) */
  slopeThermalLayerM2?: Maybe<Scalars['Float']>;
  /** Теплоизоляционные плиты (ММГ) */
  thermalLayerM3?: Maybe<Scalars['Float']>;
  /** Прослойка из биоматов для откосов */
  rollLawnLayerM2?: Maybe<Scalars['Float']>;
  /** Установка геотекстиля, георешетки и заполнение щебнем */
  geogridMakingM2?: Maybe<Scalars['Float']>;
  /** Щебень из природного камня для строительных работ */
  smallRockM3?: Maybe<Scalars['Float']>;
  /** Перевозка песка */
  sandTransportationTon?: Maybe<Scalars['Float']>;
  /** id расчета этапа */
  calculationId?: Maybe<Scalars['Uuid']>;
};

/** Характеристики и стоимостные показатели генплана */
export type SitePlan = {
  /** Коэффициент застройки территории */
  buildingCoverageRatio?: Maybe<Scalars['Float']>;
  /** Площадь застройки */
  buildingArea?: Maybe<Scalars['Float']>;
  /** Площадь объекта */
  sitePlanArea?: Maybe<Scalars['Float']>;
  /** Площадь внутриплощадных проездов */
  internalRoadsArea?: Maybe<Scalars['Float']>;
  /** Суммарная длина технологических связей */
  totalUtilityLinesLength?: Maybe<Scalars['Float']>;
  /** Длина эстакад коммуникаций */
  corridorTotalLengthM?: Maybe<Scalars['Float']>;
  /** Общая длина трубопроводов */
  pipelineTotalLengthM?: Maybe<Scalars['Float']>;
  /** Общая длина кабельных коммуникаций */
  powerlineTotalLengthM?: Maybe<Scalars['Float']>;
  /** Расстояние возки материалов от карьеров */
  transportationDistance?: Maybe<Scalars['Float']>;
  /** Итоговая стоимость инженерной подготовки */
  preparationCost?: Maybe<Scalars['Float']>;
  /** Земляные работы */
  earthworkM3?: Maybe<Scalars['Float']>;
  /** Песчанный грунт */
  sandM3?: Maybe<Scalars['Float']>;
  /** Лежневой настил */
  coverM2?: Maybe<Scalars['Float']>;
  /** Прослойка из геотекстиля */
  geotextileLayerM2?: Maybe<Scalars['Float']>;
  /** Теплоизоляционные плиты под откосы (ММГ) */
  slopeThermalLayerM2?: Maybe<Scalars['Float']>;
  /** Теплоизоляционные плиты (ММГ) */
  thermalLayerM3?: Maybe<Scalars['Float']>;
  /** Прослойка из биоматов для откосов */
  rollLawnLayerM2?: Maybe<Scalars['Float']>;
  /** Установка геотекстиля, георешетки и заполнение щебнем */
  geogridMakingM2?: Maybe<Scalars['Float']>;
  /** Щебень из природного камня для строительных работ */
  smallRockM3?: Maybe<Scalars['Float']>;
  /** Перевозка песка */
  sandTransportationTon?: Maybe<Scalars['Float']>;
  /** id расчета этапа */
  calculationId?: Maybe<Scalars['Uuid']>;
};

/** Полигон дорог генплана */
export type Road = {
  id?: Maybe<Scalars['Uuid']>;
  /** Полигон внутриплощадочных проездов генплана */
  polygon?: Maybe<Scalars['GeoJsonPolygon']>;
  /** Площадь внутриплощадочных проехдов, метры кв. */
  area?: Maybe<Scalars['Float']>;
  /** id расчета этапа */
  calculationId?: Maybe<Scalars['Uuid']>;
};

/** Линии строительной сетки. */
export type GridLine = {
  id?: Maybe<Scalars['Uuid']>;
  index?: Maybe<Scalars['Int']>;
  axis?: Maybe<AxisType>;
  line?: Maybe<Scalars['GeoJsonLineString']>;
  /** id расчета этапа */
  calculationId?: Maybe<Scalars['Uuid']>;
};

/**
 * Направление линии строительной сетки.
 *
 * Attributes:
 *     :С0: С0.
 *     :С1: С1.
 */
export type AxisType =
  | 'HORIZONTAL'
  | 'VERTICAL';


/** Линии проездов */
export type RoadLine = {
  id?: Maybe<Scalars['Uuid']>;
  line?: Maybe<Scalars['GeoJsonLineString']>;
  /** id расчета этапа */
  calculationId?: Maybe<Scalars['Uuid']>;
};

/** Коридоры коммуникаций */
export type Corridor = {
  id?: Maybe<Scalars['Uuid']>;
  polygon?: Maybe<Scalars['GeoJsonPolygon']>;
  /** id расчета этапа */
  calculationId?: Maybe<Scalars['Uuid']>;
  /** тип прокладки коридоров */
  locationType?: Maybe<ConnectionLocationType>;
};

/**
 * Расположение коммуникации
 *
 *     Attributes:
 *         :UNDERGROUND: подземная.
 *         :OVERGROUND: надземная.
 */
export type ConnectionLocationType =
  | 'UNDERGROUND'
  | 'OVERGROUND';

/** Параметры сооружения */
export type Building = {
  /** id сооружения */
  id?: Maybe<Scalars['Uuid']>;
  /** id задачи */
  taskId?: Maybe<Scalars['Uuid']>;
  /** Название сооружения */
  name?: Maybe<Scalars['String']>;
  /** id типа сооружения */
  buildingTypeId?: Maybe<Scalars['Uuid']>;
  /** Длина сооружения (м) - если сооружение прямоугольной формы */
  length?: Maybe<Scalars['Float']>;
  /** Ширина сооружения (м) - если сооружение прямоугольной формы */
  width?: Maybe<Scalars['Float']>;
  /** Радиус сооружения (м) - если сооружение круглое */
  radius?: Maybe<Scalars['Float']>;
  /** Высота факела (м) - только для факелов */
  height?: Maybe<Scalars['Float']>;
  /** Объём */
  capacity?: Maybe<Scalars['Float']>;
  /** Обозначение на схеме(группировка сооружений) */
  label?: Maybe<Scalars['String']>;
  /** Степень огнестойкости */
  fireResistanceRating?: Maybe<FireResistanceRating>;
  /** Категория по пожарной и взрывопожарной опасности */
  fireDangerRating?: Maybe<FireDangerRating>;
  /** Класс конструктивной пожарной опасности */
  structuralFireHazardClass?: Maybe<StructuralFireHazardClass>;
  /** Функциональная зона */
  functionalArea?: Maybe<FunctionalAreaType>;
  /** id типа содержимого */
  contentTypeId?: Maybe<Scalars['Uuid']>;
  /** Диаметр трубы факела (м) - только для факелов */
  flareStackDiameter?: Maybe<Scalars['Float']>;
  /** Признак постоянного присутствия персонала на сооружении */
  isPermanentStaffPresence?: Maybe<Scalars['Boolean']>;
  /** Тип сооружения */
  constructionType?: Maybe<ConstructionType>;
  /** Рабочее давление установки, МПа */
  workingPressureMpa?: Maybe<Scalars['Float']>;
  /** Тип ёмкости */
  tankLocationType?: Maybe<TankLocationType>;
  /** Тип содержимого */
  contentType?: Maybe<ContentType>;
  /** Точки подключения сооружения */
  connectionPoints?: Maybe<Array<Maybe<ConnectionPoint>>>;
  /** Полигон сооружения на рассчитанном генплане */
  polygon?: Maybe<Scalars['GeoJsonPolygon']>;
  /** Зона поражения при детонации сооружения */
  affectedAreas?: Maybe<Array<Maybe<AffectedArea>>>;
  /** Зоны теплового излучения факелов */
  thermalRadiationAreas?: Maybe<Array<Maybe<ThermalRadiationArea>>>;
  /** Параметры связанных сооружений */
  connectedBuildings?: Maybe<Array<Maybe<ConnectedBuilding>>>;
  /** Круглое сооружение или нет */
  isCircle?: Maybe<Scalars['Boolean']>;
};

/**
 * Степень огнестойкости
 *
 * Attributes:
 *     :ONE: I.
 *     :TWO: II.
 *     :THREE: III.
 *     :FOUR: IV.
 *     :FIVE: V.
 */
export type FireResistanceRating =
  | 'ONE'
  | 'TWO'
  | 'THREE'
  | 'FOUR'
  | 'FIVE';

/**
 * Категория по пожарной и взрывопожарной опасности
 *
 * Attributes:
 *     :ENHANCED_EXPLOSIVE: повышенная взрывопожароопасность (А).
 *     :EXPLOSIVE: взрывопожароопасность (Б).
 *     :FLAMMABLE: пожароопасность (В).
 *     :MODERATE_FLAMMABLE: умеренная пожароопасность (Г).
 *     :LOW_FLAMMABLE: пониженная пожароопасность (Д).
 */
export type FireDangerRating =
  | 'ENHANCED_EXPLOSIVE'
  | 'EXPLOSIVE'
  | 'FLAMMABLE'
  | 'MODERATE_FLAMMABLE'
  | 'LOW_FLAMMABLE';

/**
 * Класс конструктивной пожарной опасности
 *
 * Attributes:
 *     :С0: С0.
 *     :С1: С1.
 *     :С2: С2.
 *     :С3: С3.
 */
export type StructuralFireHazardClass =
  | 'C0'
  | 'C1'
  | 'C2'
  | 'C3';

/**
 * Функциональная зона сооружения
 *
 * Attributes:
 *     :ONE: I.
 *     :TWO: II.
 *     :THREE: III.
 *     :FLARE_STACK_ZONE: Факельная зона.
 */
export type FunctionalAreaType =
  | 'ONE'
  | 'TWO'
  | 'THREE'
  | 'FLARE_STACK_ZONE';

/**
 * Тип сооружения
 *
 *     Attributes:
 *         :OPEN_AREA: открытая площадка.
 *         :BUILDING: здание.
 */
export type ConstructionType =
  | 'OPEN_AREA'
  | 'BUILDING';

/**
 * Тип ёмкости.
 *
 * Attributes:
 *     :GROUND_LEVEL: наземная.
 *     :BURIED: подземная.
 */
export type TankLocationType =
  | 'GROUND_LEVEL'
  | 'BURIED';

/** Тип содержимого */
export type ContentType = {
  id?: Maybe<Scalars['Uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** Точка подключения */
export type ConnectionPoint = {
  id?: Maybe<Scalars['Uuid']>;
  pointM?: Maybe<Scalars['GeoJsonPoint']>;
  buildingId?: Maybe<Scalars['Uuid']>;
  typeId?: Maybe<Scalars['Uuid']>;
  pointType?: Maybe<ConnectionPointType>;
};

/** Зона поражения при детонации сооружения. */
export type AffectedArea = {
  id?: Maybe<Scalars['Uuid']>;
  damageLevel?: Maybe<DamageLevel>;
  pressureKpa?: Maybe<Scalars['Float']>;
  radiusM?: Maybe<Scalars['Float']>;
  /** id расчета этапа */
  calculationId?: Maybe<Scalars['Uuid']>;
};

/**
 * Степень разрушений здания/строения/сооружения при детонации другого сооружения.
 *
 * Attributes:
 *     :TOTAL: Полное
 *     :HIGH: Сильное
 *     :MEDIUM: Среднее
 *     :LOW: Слабое
 *     :VERY_LOW: Легкое
 */
export type DamageLevel =
  | 'TOTAL'
  | 'HIGH'
  | 'MEDIUM'
  | 'LOW'
  | 'VERY_LOW';

/** Зоны теплового излучения факелов. */
export type ThermalRadiationArea = {
  id?: Maybe<Scalars['Uuid']>;
  thermalRadiationLevel?: Maybe<ThermalRadiationLevel>;
  radiusM?: Maybe<Scalars['Float']>;
  /** id расчета этапа */
  calculationId?: Maybe<Scalars['Uuid']>;
};

/**
 * Уровень теплового излучения
 *
 * Attributes:
 *     :SAFE: Возможно постоянное присутствие людей в спец одежде
 *     :DANGEROUS: Персонал должен эвакуироваться в течение 3-х мин.
 *     :DEADLY: Персонал должен эвакуироваться в течение 30с
 */
export type ThermalRadiationLevel =
  | 'SAFE'
  | 'DANGEROUS'
  | 'DEADLY';

/** Связанные сооружения */
export type ConnectedBuilding = {
  /** Целевое сооружение */
  targetBuilding?: Maybe<Building>;
  /** Тип соединения */
  connectionType?: Maybe<ConnectionType>;
  /** id целевого сооружения */
  targetBuildingId?: Maybe<Scalars['Uuid']>;
  /** id типа соединения */
  connectionTypeId?: Maybe<Scalars['Uuid']>;
  /** id типа точки подключения */
  connectionPointTypeId?: Maybe<Scalars['Uuid']>;
  /** Отступ, м */
  offset?: Maybe<Scalars['Float']>;
  /** Сторона, в которую направлено соединение */
  side?: Maybe<Side>;
  /** id соединения */
  connectionId?: Maybe<Scalars['Uuid']>;
};

/**
 * Сторона
 *
 *         Attributes:
 *         :UNDEFINED: не определена
 *         :LEFT: лево
 *         :BOTTOM: низ
 *         :RIGHT: право
 *         :TOP: верх
 */
export type Side =
  | 'UNDEFINED'
  | 'LEFT'
  | 'BOTTOM'
  | 'RIGHT'
  | 'TOP';

/** Технологические линии связи */
export type CommunicationNetwork = {
  id?: Maybe<Scalars['Uuid']>;
  utilityLines?: Maybe<Array<Maybe<UtilityLine>>>;
  nodes?: Maybe<Array<Maybe<Node>>>;
  /** id расчета этапа */
  calculationId?: Maybe<Scalars['Uuid']>;
};

/** Линии */
export type UtilityLine = {
  id?: Maybe<Scalars['Uuid']>;
  line?: Maybe<Scalars['GeoJsonLineString']>;
  polygon?: Maybe<Scalars['GeoJsonPolygon']>;
  startNodeId?: Maybe<Scalars['Uuid']>;
  endNodeId?: Maybe<Scalars['Uuid']>;
  communicationNetworkId?: Maybe<Scalars['Uuid']>;
};

/** Нода */
export type Node = {
  id?: Maybe<Scalars['Uuid']>;
  point?: Maybe<Scalars['GeoJsonPoint']>;
  connectionPointId?: Maybe<Scalars['Uuid']>;
  communicationNetworkId?: Maybe<Scalars['Uuid']>;
  connectionPoint?: Maybe<ConnectionPoint>;
};

/** Ограждение для сооружения/группы сооружений. */
export type Barrier = {
  id?: Maybe<Scalars['Uuid']>;
  line?: Maybe<Scalars['GeoJsonLineString']>;
  /** id расчета этапа */
  calculationId?: Maybe<Scalars['Uuid']>;
};

/** Выбор пользователя по методам для расчета */
export type TaskCalculation = {
  taskCalculationId?: Maybe<Scalars['Uuid']>;
  /** id задачи */
  taskId?: Maybe<Scalars['Uuid']>;
  /** id расчета */
  calculationId?: Maybe<Scalars['Uuid']>;
  /** id этапа */
  stageId?: Maybe<Scalars['Uuid']>;
  /** id метода */
  methodId?: Maybe<Scalars['Uuid']>;
  /** Именно этот результат метода использовать далее как входные данные в след. этапах */
  isFinalResult?: Maybe<Scalars['Boolean']>;
  /** Состояние метода расчета */
  status?: Maybe<CalculationStatus>;
  /** Описание метода расчета */
  description?: Maybe<Scalars['String']>;
  /** Расчет */
  calculation?: Maybe<Calculation>;
  /** История изменений calculations */
  history?: Maybe<Array<Maybe<CalculationHistory>>>;
  /** Метод */
  method?: Maybe<Method>;
  /** Этап */
  stage?: Maybe<Stage>;
  /** Время последнего обновления расчета. */
  updated?: Maybe<Scalars['DateTime']>;
};

/**
 * Состояние метода расчета
 * Attributes:
 *     :NEW: создан, устанавливается при запуске варианта проекта на расчет.
 *     :RUNNING: запущен на расчет, устанавливается после начала выполнения соответствующей функции расчета.
 *     :FAILED: ошибка, устанавливается при возникновении ошибки во время расчета метода.
 *     :FINISHED: рассчитан, устанавливается при наличии результатов метода, соответствующих набору выходных фич этапа.
 */
export type CalculationStatus =
  | 'NEW'
  | 'RUNNING'
  | 'FAILED'
  | 'FINISHED';

/** Расчет */
export type Calculation = {
  calculationId?: Maybe<Scalars['Uuid']>;
  methodId?: Maybe<Scalars['Uuid']>;
  status?: Maybe<CalculationStatus>;
  description?: Maybe<Scalars['String']>;
};

/** История изменений calculations */
export type CalculationHistory = {
  created?: Maybe<Scalars['String']>;
  taskCalculationId?: Maybe<Scalars['Uuid']>;
  newCalculationId?: Maybe<Scalars['Uuid']>;
  oldCalculationId?: Maybe<Scalars['Uuid']>;
  oldCalculation?: Maybe<Calculation>;
  newCalculation?: Maybe<Calculation>;
};

/** Методы расчета */
export type Method = {
  id?: Maybe<Scalars['Uuid']>;
  /** Наименование для отображения в интерфейсе пользователя */
  name?: Maybe<Scalars['String']>;
  /** Идентификатор метода, используемый для взаимодействия между компонентами */
  code?: Maybe<Scalars['String']>;
  /** Вызываемый программный сервис */
  programImpl?: Maybe<Scalars['String']>;
  /** Запуск данного метода по умолчанию в рамках этапа */
  isRunDefault?: Maybe<Scalars['Boolean']>;
  /** Выходные фичи метода */
  outputFeatures?: Maybe<Array<Maybe<Feature>>>;
};

/** Типы фичей расчета */
export type Feature = {
  id?: Maybe<Scalars['Uuid']>;
  /** Наименование для отображения в интерфейсе пользователя */
  name?: Maybe<Scalars['String']>;
  /** Признак того, что данную фичу можно отобразить */
  isVisible?: Maybe<Scalars['Boolean']>;
};

/** Этапы, используемые для настройки сценария */
export type Stage = {
  id?: Maybe<Scalars['Uuid']>;
  /** Наименование этапа, отображаемое для пользователя */
  name?: Maybe<Scalars['String']>;
  /** Идентификатор этапа для использования при взаимодействии между компонентами системами */
  code?: Maybe<Scalars['String']>;
  /** Идентификатор визуального компонента для анализа результатов пользователем */
  visualView?: Maybe<Scalars['String']>;
  /** Методы расчета для данного этапа. */
  methods?: Maybe<Array<Maybe<Method>>>;
  /** Запуск нескольких методов в одном этапе расчета */
  allowMultipleMethods?: Maybe<Scalars['Boolean']>;
  /** Порядок этапа в рамках сценария */
  order?: Maybe<Scalars['Int']>;
  /** id сценария, в рамках которого используется данный этап расчета */
  scenarioId?: Maybe<Scalars['UUID']>;
};



/** Сценарии расчета, в рамках которых настраиваются этапы и методы расчета */
export type Scenario = {
  id?: Maybe<Scalars['Uuid']>;
  /** Наименование сценария, отображаемое для пользователя */
  name?: Maybe<Scalars['String']>;
  /** Идентификатор сценария для использования при взаимодействии между компонентами системами */
  code?: Maybe<Scalars['String']>;
  /** Этапы расчета для данного сценария */
  stages?: Maybe<Array<Maybe<Stage>>>;
};

/** Информация о пагинации */
export type PageInfo = {
  totalPages?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
};

/** Пользователи. */
export type User = {
  id?: Maybe<Scalars['Uuid']>;
  login?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

/** Коммуникация */
export type BuildingConnection = {
  id?: Maybe<Scalars['Uuid']>;
  sourceConnectionPoint?: Maybe<ConnectionPoint>;
  targetConnectionPoint?: Maybe<ConnectionPoint>;
  sourceBuilding?: Maybe<Building>;
  targetBuilding?: Maybe<Building>;
  connectionType?: Maybe<ConnectionType>;
  locationType?: Maybe<ConnectionLocationType>;
  utilityLine?: Maybe<UtilityLine>;
};

/** Внешнее соединение */
export type ExternalConnection = {
  id?: Maybe<Scalars['Uuid']>;
  /** точка подключения на сооружении */
  connectionPoint?: Maybe<ConnectionPoint>;
  /** внешняя точка подключения */
  externalPoint?: Maybe<ExternalPoint>;
  /** направление соединения */
  connectionDirection?: Maybe<ConnectionDirectionType>;
  /** отступ, м */
  pointOffset?: Maybe<Scalars['Float']>;
  /** сторона, в которую направлено соединение */
  buildingSide?: Maybe<Side>;
  /** сооружение */
  building?: Maybe<Building>;
};

/**
 * Направление соединения
 * Attributes:
 *     :INCOMING: исходящее из сооружения
 *     :OUTGOING: входящее в сооружение
 */
export type ConnectionDirectionType =
  | 'INCOMING'
  | 'OUTGOING';

/** Коммуникация, идущая от сооружения */
export type ConnectionFromBuilding = {
  /** id точки подключения */
  connectionPointId?: Maybe<Scalars['Uuid']>;
  /** id типа точки подключения */
  connectionPointTypeId?: Maybe<Scalars['Uuid']>;
  /** название типа точки подключения */
  connectionPointTypeName?: Maybe<Scalars['String']>;
  /** внешняя ли точка подключения */
  connectionPointIsExternal?: Maybe<Scalars['Boolean']>;
  /** существует ли точка подключения */
  connectionPointIsExists?: Maybe<Scalars['Boolean']>;
  /** id типа соединения */
  connectionTypeId?: Maybe<Scalars['Uuid']>;
  /** название типа соединения */
  connectionTypeName?: Maybe<Scalars['String']>;
  /** id целевой точки подключения */
  connectedConnectionPointId?: Maybe<Scalars['String']>;
  /** название целевого сооружения */
  targetBuildingName?: Maybe<Scalars['String']>;
  /** id целевого сооружения */
  targetBuildingId?: Maybe<Scalars['Uuid']>;
  /** метка целевого сооружения */
  targetBuildingLabel?: Maybe<Scalars['String']>;
  /** направление соединения */
  connectionDirection?: Maybe<ConnectionDirectionType>;
  /** сторона, в которую направлено соединение */
  connectionSide?: Maybe<Side>;
  /** отступ, м */
  pointOffset?: Maybe<Scalars['Float']>;
  /** id соединения между сооружениями */
  connectionId?: Maybe<Scalars['Uuid']>;
  /** расположение соединения */
  locationType?: Maybe<ConnectionLocationType>;
};

/**
 * Тип области с заданной стоимостью строительства
 *
 * Attributes:
 *     :DRY_LAND: Суходол.
 *     :BOG_1_2: Болото I, II.
 *     :BOG_3: Болото III.
 *     :FLOODLAND: Пойма.
 *     :PERMAFROST: Многолетне-мерзлые грунты (ММГ).
 *     :RESTRICTED: Запретная область.
 */
export type CostAreaType =
  | 'DRY_LAND'
  | 'BOG_1_2'
  | 'BOG_3'
  | 'FLOODLAND'
  | 'PERMAFROST'
  | 'RESTRICTED';

export type BuildingCenterWrapper = {
  buildingId?: Maybe<Scalars['Uuid']>;
  taskId?: Maybe<Scalars['Uuid']>;
  /** Центр здания */
  center?: Maybe<Scalars['GeoJsonPoint']>;
};

/** Местоположение центра площадки */
export type SitePosition = {
  id?: Maybe<Scalars['Uuid']>;
  /** косинус широты */
  cosTheta?: Maybe<Scalars['Float']>;
  isPositionRestricted?: Maybe<Scalars['Boolean']>;
  /** смещение по оси X, м */
  x?: Maybe<Scalars['Float']>;
  /** смещение по оси Y, м */
  y?: Maybe<Scalars['Float']>;
  /** угол поворота против часовой стрелки в градусах относительно оси OX */
  angleDeg?: Maybe<Scalars['Float']>;
  /** id расчета этапа */
  calculationId?: Maybe<Scalars['Uuid']>;
};

export type FeatureData = {
  /** id расчета этапа */
  calculationId?: Maybe<Scalars['Uuid']>;
  /** id фичи */
  featureId?: Maybe<Scalars['Uuid']>;
  /** id задачи */
  taskId?: Maybe<Scalars['Uuid']>;
  /** Название слоя */
  layerName?: Maybe<Scalars['String']>;
  /** geojson представление фичи для дальнейшей визуализации (в географических координатах) */
  geojsonDegree?: Maybe<Scalars['CustomJSONString']>;
  /** geojson представление фичи для дальнейшей визуализации (в метрических координатах) */
  geojsonMetric?: Maybe<Scalars['CustomJSONString']>;
};


export type HealthCheck = {
  hasDatabaseConnection?: Maybe<Scalars['Boolean']>;
};

/** Root Mutation */
export type Mutation = {
  externalPointMutation?: Maybe<ExternalPointMutation>;
  taskMutation?: Maybe<TaskMutation>;
  buildingMutation?: Maybe<BuildingMutation>;
  connection?: Maybe<ConnectionMutation>;
  boundingPolygonMutation?: Maybe<BoundingPolygonMutation>;
  taskCalculationMutation?: Maybe<TaskCalculationMutation>;
  externalConnectionMutation?: Maybe<ExternalConnectionMutation>;
};

export type ExternalPointMutation = {
  create?: Maybe<CreateExternalPoint>;
  updateCoordinates?: Maybe<UpdateCoordinates>;
  delete?: Maybe<DeleteExternalPoint>;
};


export type ExternalPointMutationCreateArgs = {
  input: CreateExternalPointInput;
};


export type ExternalPointMutationUpdateCoordinatesArgs = {
  input: UpdateExternalPointCoordinatesInput;
};


export type ExternalPointMutationDeleteArgs = {
  externalPointId: Scalars['Uuid'];
};

export type CreateExternalPoint = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id внешней точки */
  recordId?: Maybe<Scalars['Uuid']>;
  /** внешняя точка */
  record?: Maybe<ExternalPoint>;
};

export type CreateExternalPointInput = {
  /** координаты внешней точки (широта, долгота) */
  point: Scalars['GeoJsonPoint'];
  /** id задачи */
  taskId: Scalars['Uuid'];
  /** id типа точки подключения */
  typeId: Scalars['Uuid'];
};

export type UpdateCoordinates = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id внешней точки */
  recordId?: Maybe<Scalars['Uuid']>;
  /** внешняя точка */
  record?: Maybe<ExternalPoint>;
};

export type UpdateExternalPointCoordinatesInput = {
  /** id внешней точки */
  externalPointId: Scalars['Uuid'];
  /** глобальные координаты внешней точки(широта, долгота) */
  newCoordinates: Scalars['GeoJsonPoint'];
};

export type DeleteExternalPoint = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
};

export type TaskMutation = {
  createTask?: Maybe<CreateTask>;
  updateTask?: Maybe<UpdateTask>;
  updateTaskDevelopmentArea?: Maybe<UpdateTaskDevelopmentArea>;
  calculateThisTask?: Maybe<CalculateThisTask>;
  calculateTask?: Maybe<CalculateTask>;
  cancelTaskCalculation?: Maybe<CancelTaskCalculation>;
  cloneTaskFromStage?: Maybe<CloneTaskFromStage>;
  cloneTaskWithAllStages?: Maybe<CloneTaskWithAllStages>;
  cloneTaskWithAllData?: Maybe<CloneTaskWithAllData>;
  cloneTask?: Maybe<CloneTask>;
  likeTask?: Maybe<LikeTask>;
  unlikeTask?: Maybe<UnlikeTask>;
  makeTaskDraft?: Maybe<MakeTaskDraft>;
  makeTaskSolved?: Maybe<MakeTaskSolved>;
  renameTask?: Maybe<RenameTask>;
  deleteTask?: Maybe<DeleteTask>;
};


export type TaskMutationCreateTaskArgs = {
  input: CreateTaskInput;
};


export type TaskMutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};


export type TaskMutationUpdateTaskDevelopmentAreaArgs = {
  input: UpdateTaskDevelopmentAreaInput;
};


export type TaskMutationCalculateThisTaskArgs = {
  taskId: Scalars['Uuid'];
};


export type TaskMutationCalculateTaskArgs = {
  input: CalculateTaskInput;
};


export type TaskMutationCancelTaskCalculationArgs = {
  taskId: Scalars['Uuid'];
};


export type TaskMutationCloneTaskFromStageArgs = {
  input: CloneTaskFromStageInput;
};


export type TaskMutationCloneTaskWithAllStagesArgs = {
  taskId: Scalars['Uuid'];
};


export type TaskMutationCloneTaskWithAllDataArgs = {
  input: CloneTaskWithAllDataInput;
};


export type TaskMutationCloneTaskArgs = {
  input: CloneTaskInput;
};


export type TaskMutationLikeTaskArgs = {
  taskId: Scalars['Uuid'];
};


export type TaskMutationUnlikeTaskArgs = {
  taskId: Scalars['Uuid'];
};


export type TaskMutationMakeTaskDraftArgs = {
  taskId: Scalars['Uuid'];
};


export type TaskMutationMakeTaskSolvedArgs = {
  taskId: Scalars['Uuid'];
};


export type TaskMutationRenameTaskArgs = {
  input: RenameTaskInput;
};


export type TaskMutationDeleteTaskArgs = {
  taskId: Scalars['Uuid'];
};

export type CreateTask = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id задачи */
  recordId?: Maybe<Scalars['Uuid']>;
  /** задача */
  record?: Maybe<Task>;
};

export type CreateTaskInput = {
  /** Название задачи */
  name: Scalars['String'];
  /** id сценария */
  scenarioId: Scalars['Uuid'];
  /** id пользователя */
  userId: Scalars['Uuid'];
  /** id региона для получения стоимостей инженерной подготовки */
  regionId: Scalars['Uuid'];
  /** id лицензионного участка для областей */
  licenseAreaId: Scalars['Uuid'];
  /** id цифровой модели рельефа(digital elevation model) */
  demId: Scalars['Uuid'];
  /** допустимая область размещения генплана(область расчета) */
  developmentArea: Scalars['GeoJsonPolygon'];
  /** Азимут преобладающего направления ветра(роза ветров) */
  windRoseAngleDeg?: Maybe<Scalars['Float']>;
};

export type UpdateTask = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id задачи */
  recordId?: Maybe<Scalars['Uuid']>;
  /** задача */
  record?: Maybe<Task>;
};

export type UpdateTaskInput = {
  /** Название задачи */
  name: Scalars['String'];
  /** id сценария */
  scenarioId: Scalars['Uuid'];
  /** id пользователя */
  userId: Scalars['Uuid'];
  /** id региона для получения стоимостей инженерной подготовки */
  regionId: Scalars['Uuid'];
  /** id лицензионного участка для областей */
  licenseAreaId: Scalars['Uuid'];
  /** id цифровой модели рельефа(digital elevation model) */
  demId: Scalars['Uuid'];
  /** допустимая область размещения генплана(область расчета) */
  developmentArea: Scalars['GeoJsonPolygon'];
  /** Азимут преобладающего направления ветра(роза ветров) */
  windRoseAngleDeg?: Maybe<Scalars['Float']>;
  taskId: Scalars['Uuid'];
};

export type UpdateTaskDevelopmentArea = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id задачи */
  recordId?: Maybe<Scalars['Uuid']>;
  /** задача */
  record?: Maybe<Task>;
};

export type UpdateTaskDevelopmentAreaInput = {
  taskId: Scalars['Uuid'];
  /** допустимая область размещения генплана(область расчета) */
  developmentArea: Scalars['GeoJsonPolygon'];
};

export type CalculateThisTask = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id задачи */
  recordId?: Maybe<Scalars['Uuid']>;
  /** задача */
  record?: Maybe<Task>;
};

export type CalculateTask = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id задачи */
  recordId?: Maybe<Scalars['Uuid']>;
  /** задача */
  record?: Maybe<Task>;
};

export type CalculateTaskInput = {
  /** id задачи */
  taskId: Scalars['Uuid'];
  /** Набор методов и этапов, которые выбрал пользователь */
  stageMethods: Array<Maybe<StageMethods>>;
};

export type StageMethods = {
  /** id этапа */
  stageId: Scalars['Uuid'];
  /** id метода */
  methodId: Scalars['Uuid'];
};

export type CancelTaskCalculation = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id задачи */
  recordId?: Maybe<Scalars['Uuid']>;
  /** задача */
  record?: Maybe<Task>;
};

export type CloneTaskFromStage = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id задачи */
  recordId?: Maybe<Scalars['Uuid']>;
  /** задача */
  record?: Maybe<Task>;
};

export type CloneTaskFromStageInput = {
  /** id задачи */
  taskId: Scalars['Uuid'];
  /** id связи метода с этапом в рамках сценария */
  stageMethodId: Scalars['Uuid'];
};

export type CloneTaskWithAllStages = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id задачи */
  recordId?: Maybe<Scalars['Uuid']>;
  /** задача */
  record?: Maybe<Task>;
};

export type CloneTaskWithAllData = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id задачи */
  recordId?: Maybe<Scalars['Uuid']>;
  /** задача */
  record?: Maybe<Task>;
};

export type CloneTaskWithAllDataInput = {
  /** id задачи */
  taskId: Scalars['Uuid'];
};

export type CloneTask = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id задачи */
  recordId?: Maybe<Scalars['Uuid']>;
  /** задача */
  record?: Maybe<Task>;
};

export type CloneTaskInput = {
  /** id задачи */
  taskId: Scalars['Uuid'];
};

export type LikeTask = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id задачи */
  recordId?: Maybe<Scalars['Uuid']>;
  /** задача */
  record?: Maybe<Task>;
};

export type UnlikeTask = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id задачи */
  recordId?: Maybe<Scalars['Uuid']>;
  /** задача */
  record?: Maybe<Task>;
};

export type MakeTaskDraft = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
};

export type MakeTaskSolved = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
};

export type RenameTask = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id задачи */
  recordId?: Maybe<Scalars['Uuid']>;
  /** задача */
  record?: Maybe<Task>;
};

export type RenameTaskInput = {
  taskId: Scalars['Uuid'];
  name: Scalars['String'];
};

export type DeleteTask = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
};

export type BuildingMutation = {
  createBuilding?: Maybe<CreateBuilding>;
  updateBuilding?: Maybe<UpdateBuilding>;
  deleteBuilding?: Maybe<DeleteBuilding>;
  /** QGIS */
  updateBuildingGeometry?: Maybe<UpdateBuildingGeometry>;
};


export type BuildingMutationCreateBuildingArgs = {
  input: CreateBuildingInput;
};


export type BuildingMutationUpdateBuildingArgs = {
  input: UpdateBuildingInput;
};


export type BuildingMutationDeleteBuildingArgs = {
  buildingId: Scalars['Uuid'];
};


export type BuildingMutationUpdateBuildingGeometryArgs = {
  input: UpdateBuildingGeometryInput;
};

export type CreateBuilding = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id сооружения */
  recordId?: Maybe<Scalars['Uuid']>;
  /** параметры сооружения */
  record?: Maybe<Building>;
};

export type CreateBuildingInput = {
  /** Название сооружения */
  name: Scalars['String'];
  /** Ширина сооружения в метрах (вертикальная сторона). */
  width?: Maybe<Scalars['Float']>;
  /** Длина сооружения в метрах (горизонтальная сторона). */
  length?: Maybe<Scalars['Float']>;
  /** Радиус сооружения в метрах. */
  radius?: Maybe<Scalars['Float']>;
  /** Высота сооружения в метрах. */
  height?: Maybe<Scalars['Float']>;
  /** Диаметр факельной трубы в метрах. */
  flareStackDiameter?: Maybe<Scalars['Float']>;
  /** Тип сооружения */
  buildingTypeId: Scalars['Uuid'];
  /** Степень огнестойкости */
  fireResistanceRating: FireResistanceRating;
  /** Категория по пожарной и взрывопожарной опасности */
  fireDangerRating: FireDangerRating;
  /** Класс конструктивной пожарной опасности */
  structuralFireHazardClass: StructuralFireHazardClass;
  /** Функциональная зона сооружения */
  functionalArea: FunctionalAreaType;
  /** Обозначение сооружения на схеме */
  label: Scalars['String'];
  /** Тип содержимого */
  contentTypeId?: Maybe<Scalars['Uuid']>;
  /** Емкость */
  capacity?: Maybe<Scalars['Float']>;
  /** Маркер постоянного присутствия людей */
  isPermanentStaffPresence: Scalars['Boolean'];
  /** Тип сооружения */
  constructionType: ConstructionType;
  /** Рабочее давление */
  workingPressureMpa?: Maybe<Scalars['Float']>;
  /** Тип емкости */
  tankLocationType?: Maybe<TankLocationType>;
  /** id задачи */
  taskId: Scalars['Uuid'];
};

export type UpdateBuilding = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id сооружения */
  recordId?: Maybe<Scalars['Uuid']>;
  /** параметры сооружения */
  record?: Maybe<Building>;
};

export type UpdateBuildingInput = {
  /** Название сооружения */
  name: Scalars['String'];
  /** Ширина сооружения в метрах (вертикальная сторона). */
  width?: Maybe<Scalars['Float']>;
  /** Длина сооружения в метрах (горизонтальная сторона). */
  length?: Maybe<Scalars['Float']>;
  /** Радиус сооружения в метрах. */
  radius?: Maybe<Scalars['Float']>;
  /** Высота сооружения в метрах. */
  height?: Maybe<Scalars['Float']>;
  /** Диаметр факельной трубы в метрах. */
  flareStackDiameter?: Maybe<Scalars['Float']>;
  /** Тип сооружения */
  buildingTypeId: Scalars['Uuid'];
  /** Степень огнестойкости */
  fireResistanceRating: FireResistanceRating;
  /** Категория по пожарной и взрывопожарной опасности */
  fireDangerRating: FireDangerRating;
  /** Класс конструктивной пожарной опасности */
  structuralFireHazardClass: StructuralFireHazardClass;
  /** Функциональная зона сооружения */
  functionalArea: FunctionalAreaType;
  /** Обозначение сооружения на схеме */
  label: Scalars['String'];
  /** Тип содержимого */
  contentTypeId?: Maybe<Scalars['Uuid']>;
  /** Емкость */
  capacity?: Maybe<Scalars['Float']>;
  /** Маркер постоянного присутствия людей */
  isPermanentStaffPresence: Scalars['Boolean'];
  /** Тип сооружения */
  constructionType: ConstructionType;
  /** Рабочее давление */
  workingPressureMpa?: Maybe<Scalars['Float']>;
  /** Тип емкости */
  tankLocationType?: Maybe<TankLocationType>;
  buildingId: Scalars['Uuid'];
};

export type DeleteBuilding = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
};

export type UpdateBuildingGeometry = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id сооружения */
  buildingId: Scalars['Uuid'];
};

export type UpdateBuildingGeometryInput = {
  /** id сооружения */
  buildingId: Scalars['Uuid'];
  /** смещение сооружения по горизонтали, м */
  xOffset: Scalars['Float'];
  /** смещение сооружения по вертикали, м */
  yOffset: Scalars['Float'];
  /** угол поворота сооружения против часовой стрелки, градусы (должен быть кратен 90 градусам) */
  rotationAngle: Scalars['Int'];
};

export type ConnectionMutation = {
  /** QGIS */
  createBetweenConnectionPoints?: Maybe<CreateConnectionBetweenConnectionPoints>;
  createBetweenBuildings?: Maybe<CreateConnectionBetweenBuildings>;
  /** QGIS */
  update?: Maybe<UpdateConnection>;
  updateBetweenBuildings?: Maybe<UpdateConnectionBetweenBuildings>;
  delete?: Maybe<DeleteConnection>;
  /** QGIS */
  deleteByConnectionPoint?: Maybe<DeleteConnectionByConnectionPoint>;
};


export type ConnectionMutationCreateBetweenConnectionPointsArgs = {
  input: CreateConnectionBetweenConnectionPointsInput;
};


export type ConnectionMutationCreateBetweenBuildingsArgs = {
  input: CreateConnectionBetweenBuildingsInput;
};


export type ConnectionMutationUpdateArgs = {
  input: UpdateConnectionInput;
};


export type ConnectionMutationUpdateBetweenBuildingsArgs = {
  input: UpdateConnectionBetweenBuildingsInput;
};


export type ConnectionMutationDeleteArgs = {
  input: DeleteConnectionInput;
};


export type ConnectionMutationDeleteByConnectionPointArgs = {
  connectionPointId: Scalars['Uuid'];
};

export type CreateConnectionBetweenConnectionPoints = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** соединение */
  connection?: Maybe<BuildingConnection>;
  /** id соединения */
  connectionId: Scalars['Uuid'];
  /** id исходной точки подключения */
  sourceConnectionPointId: Scalars['Uuid'];
  /** id целевой точки подключения */
  targetConnectionPointId: Scalars['Uuid'];
};

export type CreateConnectionBetweenConnectionPointsInput = {
  /** id исходной точки подключения */
  sourceConnectionPointId: Scalars['Uuid'];
  /** id целевой точки подключения */
  targetConnectionPointId?: Maybe<Scalars['Uuid']>;
  /** id типа соединения */
  connectionTypeId?: Maybe<Scalars['Uuid']>;
  /** расположение коммуникации */
  locationType: ConnectionLocationType;
};

export type CreateConnectionBetweenBuildings = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** соединение */
  connection: BuildingConnection;
  /** id соединения */
  connectionId: Scalars['Uuid'];
  /** id целевой точки подключения */
  targetConnectionPointId: Scalars['Uuid'];
  /** id исходной точки подключения */
  sourceConnectionPointId: Scalars['Uuid'];
};

export type CreateConnectionBetweenBuildingsInput = {
  /** id исходного сооружения */
  sourceBuildingId: Scalars['Uuid'];
  /** сторона на исходном сооружении, из которой выходит соединение */
  sourceBuildingSide: Side;
  /** id целевого сооружения */
  targetBuildingId: Scalars['Uuid'];
  /** сторона на целевом сооружении, в которую входит соединение */
  targetBuildingSide: Side;
  /** id типа точки подключения */
  connectionPointTypeId: Scalars['Uuid'];
  /** id типа соединения */
  connectionTypeId: Scalars['Uuid'];
  /** расположение коммуникации */
  locationType: ConnectionLocationType;
};

export type UpdateConnection = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id точки подключения */
  connectionPointId: Scalars['Uuid'];
};

export type UpdateConnectionInput = {
  /** id точки соединения */
  connectionPointId: Scalars['Uuid'];
  /** id типа соединения */
  connectionTypeId: Scalars['Uuid'];
  /** сторона сооружения, на которой находится точка подключения */
  side: Side;
  /** отступ, м */
  offset: Scalars['Float'];
  /** id типа точки соединения */
  connectionPointTypeId: Scalars['Uuid'];
  /** расположение коммуникации */
  locationType: ConnectionLocationType;
};

export type UpdateConnectionBetweenBuildings = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id точки подключения */
  connectionPointId: Scalars['Uuid'];
};

export type UpdateConnectionBetweenBuildingsInput = {
  /** id соединения */
  connectionId: Scalars['Uuid'];
  /** id целевого сооружения */
  targetBuildingId: Scalars['Uuid'];
  /** id типа соединения */
  connectionTypeId: Scalars['Uuid'];
  /** сторона исходного сооружения, на которой находится точка подключения */
  connectionSide: Side;
  /** отступ, м */
  pointOffset: Scalars['Float'];
  /** id типа точки подключения */
  connectionPointTypeId: Scalars['Uuid'];
  /** расположение коммуникации */
  locationType: ConnectionLocationType;
};

export type DeleteConnection = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
};

export type DeleteConnectionInput = {
  /** id соединения */
  connectionId: Scalars['Uuid'];
  /** удалять ли точки подключения вместе с соединением */
  isDeleteConnectionPoints?: Maybe<Scalars['Boolean']>;
};

export type DeleteConnectionByConnectionPoint = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
};

export type BoundingPolygonMutation = {
  updateBoundingPolygon?: Maybe<UpdateBoundingPolygon>;
};


export type BoundingPolygonMutationUpdateBoundingPolygonArgs = {
  input: UpdateBoundingPolygonInput;
};

export type UpdateBoundingPolygon = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id генплана */
  recordId?: Maybe<Scalars['Uuid']>;
  /** генплан */
  record?: Maybe<BoundingPolygon>;
};

export type UpdateBoundingPolygonInput = {
  /** id ограничивающего многоугольника */
  boundingPolygonId: Scalars['Uuid'];
  /** измененный ограничивающий многоугольник */
  newPolygon: Scalars['GeoJsonPolygon'];
};

export type TaskCalculationMutation = {
  createTaskCalculation?: Maybe<CreateTaskCalculation>;
  createTaskCalculationByStage?: Maybe<CreateTaskCalculationByStage>;
  updateTaskCalculation?: Maybe<UpdateTaskCalculation>;
  updateCalculation?: Maybe<UpdateCalculation>;
  setIsFinalResult?: Maybe<SetIsFinalResult>;
  copyTaskCalculationsResult?: Maybe<CopyTaskCalculationResults>;
  deleteTaskCalculation?: Maybe<DeleteTaskCalculation>;
  forceDeleteTaskCalculation?: Maybe<ForceDeleteTaskCalculation>;
  deleteTaskCalculationByStage?: Maybe<DeleteTaskCalculationByStage>;
};


export type TaskCalculationMutationCreateTaskCalculationArgs = {
  input: CreateTaskCalculationInput;
};


export type TaskCalculationMutationCreateTaskCalculationByStageArgs = {
  input: CreateTaskCalculationByStageInput;
};


export type TaskCalculationMutationUpdateTaskCalculationArgs = {
  input: UpdateTaskCalculationInput;
};


export type TaskCalculationMutationUpdateCalculationArgs = {
  input: UpdateCalculationIdInput;
};


export type TaskCalculationMutationSetIsFinalResultArgs = {
  input: SetIsFinalResultInput;
};


export type TaskCalculationMutationCopyTaskCalculationsResultArgs = {
  taskCalculationId: Scalars['Uuid'];
};


export type TaskCalculationMutationDeleteTaskCalculationArgs = {
  taskCalculationId: Scalars['Uuid'];
};


export type TaskCalculationMutationForceDeleteTaskCalculationArgs = {
  taskCalculationId: Scalars['Uuid'];
};


export type TaskCalculationMutationDeleteTaskCalculationByStageArgs = {
  input: DeleteTaskCalculationByStageInput;
};

export type CreateTaskCalculation = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id выбора пользователя по методу расчета */
  recordId?: Maybe<Scalars['Uuid']>;
  /** Выбор пользователя по методу расчета */
  record?: Maybe<TaskCalculation>;
};

export type CreateTaskCalculationInput = {
  /** id задачи */
  taskId: Scalars['Uuid'];
  /** id этапа */
  stageId: Scalars['Uuid'];
  /** id метода */
  methodId: Scalars['Uuid'];
  /** Описание задачи */
  description?: Maybe<Scalars['String']>;
};

export type CreateTaskCalculationByStage = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** Выбор пользователя по методу расчета */
  record?: Maybe<Array<Maybe<TaskCalculation>>>;
};

export type CreateTaskCalculationByStageInput = {
  /** id задачи */
  taskId: Scalars['Uuid'];
  /** id этапа */
  stageId: Scalars['Uuid'];
  /** Описание задачи */
  description?: Maybe<Scalars['String']>;
};

export type UpdateTaskCalculation = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id выбора пользователя по методу расчета */
  recordId?: Maybe<Scalars['Uuid']>;
  /** Выбор пользователя по методу расчета */
  record?: Maybe<TaskCalculation>;
};

export type UpdateTaskCalculationInput = {
  /** id задачи */
  taskId: Scalars['Uuid'];
  /** id этапа */
  stageId: Scalars['Uuid'];
  /** id метода */
  methodId: Scalars['Uuid'];
  /** Описание задачи */
  description?: Maybe<Scalars['String']>;
  taskCalculationId: Scalars['Uuid'];
};

export type UpdateCalculation = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id выбора пользователя по методу расчета */
  recordId?: Maybe<Scalars['Uuid']>;
  /** Выбор пользователя по методу расчета */
  record?: Maybe<TaskCalculation>;
};

export type UpdateCalculationIdInput = {
  /** id выбора пользователя по методу расчета */
  taskCalculationId: Scalars['Uuid'];
  /** id расчета этапа */
  calculationId: Scalars['Uuid'];
};

export type SetIsFinalResult = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id выбора пользователя по методу расчета */
  recordId?: Maybe<Scalars['Uuid']>;
  /** Выбор пользователя по методу расчета */
  record?: Maybe<TaskCalculation>;
};

export type SetIsFinalResultInput = {
  /** id выбора пользователя по методу расчета */
  taskCalculationId: Scalars['Uuid'];
  /** является ли этот результат итоговым в рамках этапа */
  isFinalResult: Scalars['Boolean'];
};

export type CopyTaskCalculationResults = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** id выбора пользователя по методу расчета */
  recordId?: Maybe<Scalars['Uuid']>;
  /** Выбор пользователя по методу расчета */
  record?: Maybe<TaskCalculation>;
};

export type DeleteTaskCalculation = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
};

export type ForceDeleteTaskCalculation = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
};

export type DeleteTaskCalculationByStage = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
};

export type DeleteTaskCalculationByStageInput = {
  /** id задачи */
  taskId: Scalars['Uuid'];
  /** id этапа */
  stageId: Scalars['Uuid'];
};

export type ExternalConnectionMutation = {
  create?: Maybe<CreateExternalConnection>;
  update?: Maybe<UpdateExternalConnection>;
  delete?: Maybe<DeleteExternalConnection>;
};


export type ExternalConnectionMutationCreateArgs = {
  input: CreateExternalConnectionInput;
};


export type ExternalConnectionMutationUpdateArgs = {
  input: UpdateExternalConnectionInput;
};


export type ExternalConnectionMutationDeleteArgs = {
  externalConnectionId: Scalars['Uuid'];
};

export type CreateExternalConnection = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** внешнее соединение */
  externalConnection?: Maybe<ExternalConnection>;
  /** id внешнего соединения */
  externalConnectionId?: Maybe<Scalars['Uuid']>;
  /** id точки подключения */
  connectionPointId: Scalars['Uuid'];
};

export type CreateExternalConnectionInput = {
  /** id внешней точки подключения */
  externalPointId: Scalars['Uuid'];
  /** id сооружения */
  buildingId: Scalars['Uuid'];
  /** сторона на сооружении */
  buildingSide: Side;
  /** направление соединения */
  connectionDirection: ConnectionDirectionType;
};

export type UpdateExternalConnection = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
  /** внешнее соединение */
  externalConnection?: Maybe<ExternalConnection>;
  /** id внешнего соединения */
  externalConnectionId?: Maybe<Scalars['Uuid']>;
  /** id точки подключения */
  connectionPointId: Scalars['Uuid'];
};

export type UpdateExternalConnectionInput = {
  /** id внешнего соединения */
  externalConnectionId: Scalars['Uuid'];
  /** сторона на сооружении */
  buildingSide: Side;
  /** отступ, м */
  offset: Scalars['Float'];
  /** направление соединения */
  connectionDirection: ConnectionDirectionType;
};

export type DeleteExternalConnection = {
  /** статус выполнения мутации */
  ok: Scalars['Boolean'];
  /** Основное тело запроса */
  query: Query;
};

/** Root Subscription */
export type Subscription = {
  /** Логи задачи */
  taskTraces?: Maybe<TaskTraceData>;
  /** Статус задачи */
  taskStatus?: Maybe<CurrentTaskStatus>;
  /** Статус расчета метода */
  taskCalculationStatuses?: Maybe<Array<Maybe<TaskCalculationStatus>>>;
};


/** Root Subscription */
export type SubscriptionTaskTracesArgs = {
  taskId?: Maybe<Scalars['Uuid']>;
};


/** Root Subscription */
export type SubscriptionTaskStatusArgs = {
  taskId?: Maybe<Scalars['Uuid']>;
};


/** Root Subscription */
export type SubscriptionTaskCalculationStatusesArgs = {
  taskId?: Maybe<Scalars['Uuid']>;
};

export type TaskTraceData = {
  timestamp?: Maybe<Scalars['DateTime']>;
  percentage?: Maybe<Scalars['Int']>;
  status?: Maybe<TaskStatus>;
  message?: Maybe<Scalars['String']>;
  debugInfo?: Maybe<Scalars['String']>;
};

export type CurrentTaskStatus = {
  /** Статус задачи */
  taskStatus?: Maybe<TaskStatus>;
};

export type TaskCalculationStatus = {
  taskCalculationId?: Maybe<Scalars['Uuid']>;
  status?: Maybe<CalculationStatus>;
  errorMessage?: Maybe<Scalars['String']>;
  debugInfo?: Maybe<Scalars['String']>;
};

export type CalculateTaskMutationVariables = Exact<{
  input: CalculateTaskInput;
}>;


export type CalculateTaskMutation = { taskMutation?: Maybe<{ calculateTask?: Maybe<{ recordId?: Maybe<any> }> }> };

export type CancelTaskCalculationMutationVariables = Exact<{
  taskId: Scalars['Uuid'];
}>;


export type CancelTaskCalculationMutation = { taskMutation?: Maybe<{ cancelTaskCalculation?: Maybe<{ ok: boolean }> }> };

export type CloneTaskMutationVariables = Exact<{
  input: CloneTaskInput;
}>;


export type CloneTaskMutation = { taskMutation?: Maybe<{ cloneTask?: Maybe<{ recordId?: Maybe<any>, record?: Maybe<{ id?: Maybe<any>, developmentArea?: Maybe<any>, windRoseAngleDeg?: Maybe<number>, licenseAreaId?: Maybe<any>, regionId?: Maybe<any>, userId?: Maybe<any>, demId?: Maybe<any>, isFavorite?: Maybe<boolean>, name?: Maybe<string>, status?: Maybe<TaskStatus>, center?: Maybe<any>, buildings?: Maybe<Array<Maybe<BuildingFragmentFragment>>>, sitePlan?: Maybe<{ preparationCost?: Maybe<number>, buildingCoverageRatio?: Maybe<number>, totalUtilityLinesLength?: Maybe<number>, transportationDistance?: Maybe<number>, corridorTotalLengthM?: Maybe<number>, pipelineTotalLengthM?: Maybe<number>, powerlineTotalLengthM?: Maybe<number>, buildingArea?: Maybe<number>, earthworkM3?: Maybe<number>, sandM3?: Maybe<number>, coverM2?: Maybe<number>, geotextileLayerM2?: Maybe<number>, slopeThermalLayerM2?: Maybe<number>, thermalLayerM3?: Maybe<number>, rollLawnLayerM2?: Maybe<number>, geogridMakingM2?: Maybe<number>, smallRockM3?: Maybe<number>, sandTransportationTon?: Maybe<number> }>, externalPoints?: Maybe<Array<Maybe<ExtrenalPointsFragmentFragment>>>, taskCalculations?: Maybe<Array<Maybe<{ taskCalculationId?: Maybe<any>, taskId?: Maybe<any>, calculationId?: Maybe<any>, stageId?: Maybe<any>, methodId?: Maybe<any>, isFinalResult?: Maybe<boolean>, status?: Maybe<CalculationStatus> }>>> }> }> }> };

export type GetConnectionsFromBuildingQueryVariables = Exact<{
  buildingId: Scalars['Uuid'];
}>;


export type GetConnectionsFromBuildingQuery = { connectionsFromBuilding?: Maybe<Array<Maybe<{ connectionPointId?: Maybe<any>, connectionTypeName?: Maybe<string>, targetBuildingName?: Maybe<string>, connectionPointIsExternal?: Maybe<boolean>, connectionPointTypeName?: Maybe<string>, targetBuildingId?: Maybe<any>, targetBuildingLabel?: Maybe<string>, connectionDirection?: Maybe<ConnectionDirectionType>, connectionPointIsExists?: Maybe<boolean>, connectionTypeId?: Maybe<any>, connectedConnectionPointId?: Maybe<string>, connectionSide?: Maybe<Side>, pointOffset?: Maybe<number>, connectionId?: Maybe<any>, connectionPointTypeId?: Maybe<any>, locationType?: Maybe<ConnectionLocationType> }>>> };

export type CreateConnectionBetweenPointsMutationVariables = Exact<{
  input: CreateConnectionBetweenConnectionPointsInput;
}>;


export type CreateConnectionBetweenPointsMutation = { connection?: Maybe<{ createBetweenConnectionPoints?: Maybe<{ ok: boolean, connection?: Maybe<{ id?: Maybe<any>, sourceConnectionPoint?: Maybe<ConnectionPointFragmentFragment>, targetConnectionPoint?: Maybe<ConnectionPointFragmentFragment>, sourceBuilding?: Maybe<BuildingFragmentFragment>, targetBuilding?: Maybe<BuildingFragmentFragment>, connectionType?: Maybe<ConnectionTypeFragmentFragment>, utilityLine?: Maybe<UtilityLineFragmentFragment> }> }> }> };

export type CreateBuildingMutationVariables = Exact<{
  buildingInput: CreateBuildingInput;
}>;


export type CreateBuildingMutation = { buildingMutation?: Maybe<{ createBuilding?: Maybe<{ recordId?: Maybe<any>, record?: Maybe<BuildingFragmentFragment> }> }> };

export type CreateConnectionBetweenBuildingsMutationVariables = Exact<{
  input: CreateConnectionBetweenBuildingsInput;
}>;


export type CreateConnectionBetweenBuildingsMutation = { connection?: Maybe<{ createBetweenBuildings?: Maybe<{ ok: boolean, connectionId: any }> }> };

export type CreateTaskMutationVariables = Exact<{
  taskInput: CreateTaskInput;
}>;


export type CreateTaskMutation = { taskMutation?: Maybe<{ createTask?: Maybe<{ recordId?: Maybe<any> }> }> };

export type CreateTaskCalculationMutationVariables = Exact<{
  input: CreateTaskCalculationInput;
}>;


export type CreateTaskCalculationMutation = { taskCalculationMutation?: Maybe<{ createTaskCalculation?: Maybe<{ recordId?: Maybe<any>, record?: Maybe<{ taskCalculationId?: Maybe<any>, taskId?: Maybe<any>, calculationId?: Maybe<any>, stageId?: Maybe<any>, methodId?: Maybe<any>, isFinalResult?: Maybe<boolean>, status?: Maybe<CalculationStatus> }> }> }> };

export type CreateTaskCalculationByStageMutationVariables = Exact<{
  input: CreateTaskCalculationByStageInput;
}>;


export type CreateTaskCalculationByStageMutation = { taskCalculationMutation?: Maybe<{ createTaskCalculationByStage?: Maybe<{ record?: Maybe<Array<Maybe<{ taskCalculationId?: Maybe<any>, taskId?: Maybe<any>, calculationId?: Maybe<any>, stageId?: Maybe<any>, methodId?: Maybe<any>, isFinalResult?: Maybe<boolean>, status?: Maybe<CalculationStatus> }>>> }> }> };

export type RemoveBuildingMutationVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type RemoveBuildingMutation = { buildingMutation?: Maybe<{ deleteBuilding?: Maybe<{ ok: boolean }> }> };

export type DeleteConnectionMutationVariables = Exact<{
  input: DeleteConnectionInput;
}>;


export type DeleteConnectionMutation = { connection?: Maybe<{ delete?: Maybe<{ ok: boolean }> }> };

export type DeleteTaskMutationVariables = Exact<{
  taskId: Scalars['Uuid'];
}>;


export type DeleteTaskMutation = { taskMutation?: Maybe<{ deleteTask?: Maybe<{ ok: boolean }> }> };

export type DeleteTaskCalculationMutationVariables = Exact<{
  taskCalculationId: Scalars['Uuid'];
}>;


export type DeleteTaskCalculationMutation = { taskCalculationMutation?: Maybe<{ deleteTaskCalculation?: Maybe<{ ok: boolean }> }> };

export type DeleteTaskCalculationByStageMutationVariables = Exact<{
  input: DeleteTaskCalculationByStageInput;
}>;


export type DeleteTaskCalculationByStageMutation = { taskCalculationMutation?: Maybe<{ deleteTaskCalculationByStage?: Maybe<{ ok: boolean }> }> };

export type AffectedAreasFragmentFragment = { id?: Maybe<any>, damageLevel?: Maybe<DamageLevel>, pressureKpa?: Maybe<number>, radiusM?: Maybe<number>, calculationId?: Maybe<any> };

export type ThermalRadiationAreasFragmentFragment = { id?: Maybe<any>, thermalRadiationLevel?: Maybe<ThermalRadiationLevel>, radiusM?: Maybe<number>, calculationId?: Maybe<any> };

export type BuildingFragmentFragment = { id?: Maybe<any>, name?: Maybe<string>, buildingTypeId?: Maybe<any>, length?: Maybe<number>, width?: Maybe<number>, radius?: Maybe<number>, height?: Maybe<number>, capacity?: Maybe<number>, label?: Maybe<string>, fireResistanceRating?: Maybe<FireResistanceRating>, fireDangerRating?: Maybe<FireDangerRating>, structuralFireHazardClass?: Maybe<StructuralFireHazardClass>, functionalArea?: Maybe<FunctionalAreaType>, contentTypeId?: Maybe<any>, flareStackDiameter?: Maybe<number>, isPermanentStaffPresence?: Maybe<boolean>, constructionType?: Maybe<ConstructionType>, workingPressureMpa?: Maybe<number>, tankLocationType?: Maybe<TankLocationType>, polygon?: Maybe<any>, isCircle?: Maybe<boolean>, contentType?: Maybe<ContentTypeFragmentFragment>, affectedAreas?: Maybe<Array<Maybe<AffectedAreasFragmentFragment>>>, thermalRadiationAreas?: Maybe<Array<Maybe<ThermalRadiationAreasFragmentFragment>>> };

export type ConnectionTypeFragmentFragment = { id?: Maybe<any>, name?: Maybe<string>, widthM?: Maybe<number>, cost?: Maybe<number> };

export type ConnectionPointTypeFragmentFragment = { id?: Maybe<any>, name?: Maybe<string>, isExternal?: Maybe<boolean> };

export type ConnectionPointFragmentFragment = { id?: Maybe<any>, pointM?: Maybe<any>, buildingId?: Maybe<any>, typeId?: Maybe<any>, pointType?: Maybe<ConnectionPointTypeFragmentFragment> };

export type ConnectedBuildingFragmentFragment = { targetBuildingId?: Maybe<any>, connectionTypeId?: Maybe<any>, connectionPointTypeId?: Maybe<any>, offset?: Maybe<number>, side?: Maybe<Side>, connectionId?: Maybe<any>, targetBuilding?: Maybe<{ id?: Maybe<any> }>, connectionType?: Maybe<ConnectionTypeFragmentFragment> };

export type UtilityLineFragmentFragment = { id?: Maybe<any>, line?: Maybe<any>, polygon?: Maybe<any>, startNodeId?: Maybe<any>, endNodeId?: Maybe<any>, communicationNetworkId?: Maybe<any> };

export type CalculationMethodFragmentFragment = { id?: Maybe<any>, name?: Maybe<string>, code?: Maybe<string>, programImpl?: Maybe<string>, isRunDefault?: Maybe<boolean> };

export type CalculationStageFragmentFragment = { id?: Maybe<any>, name?: Maybe<string>, code?: Maybe<string>, visualView?: Maybe<string>, allowMultipleMethods?: Maybe<boolean>, order?: Maybe<number>, scenarioId?: Maybe<any>, methods?: Maybe<Array<Maybe<CalculationMethodFragmentFragment>>> };

export type ContentTypeFragmentFragment = { id?: Maybe<any>, name?: Maybe<string> };

export type ExtrenalPointsFragmentFragment = { id?: Maybe<any>, point?: Maybe<any>, typeId?: Maybe<any> };

export type GetAllBuildingTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllBuildingTypesQuery = { allBuildingTypes?: Maybe<Array<Maybe<{ id?: Maybe<any>, name?: Maybe<string>, code?: Maybe<string> }>>> };

export type GetAllConnectionTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllConnectionTypesQuery = { allConnectionTypes?: Maybe<Array<Maybe<{ id?: Maybe<any>, name?: Maybe<string>, widthM?: Maybe<number>, cost?: Maybe<number> }>>> };

export type GetAllConnectionsPointTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllConnectionsPointTypesQuery = { allConnectionPointTypes?: Maybe<Array<Maybe<{ id?: Maybe<any>, name?: Maybe<string>, isExternal?: Maybe<boolean> }>>> };

export type GetBuildingCenterQueryVariables = Exact<{
  buildingId: Scalars['Uuid'];
  taskId: Scalars['Uuid'];
}>;


export type GetBuildingCenterQuery = { buildingCenterByTaskId?: Maybe<{ center?: Maybe<any>, buildingId?: Maybe<any>, taskId?: Maybe<any> }> };

export type GetBuildingParametersQueryVariables = Exact<{
  id: Scalars['Uuid'];
  taskId: Scalars['Uuid'];
}>;


export type GetBuildingParametersQuery = { building?: Maybe<{ id?: Maybe<any>, name?: Maybe<string>, buildingTypeId?: Maybe<any>, length?: Maybe<number>, width?: Maybe<number>, radius?: Maybe<number>, height?: Maybe<number>, capacity?: Maybe<number>, label?: Maybe<string>, fireResistanceRating?: Maybe<FireResistanceRating>, fireDangerRating?: Maybe<FireDangerRating>, structuralFireHazardClass?: Maybe<StructuralFireHazardClass>, functionalArea?: Maybe<FunctionalAreaType>, contentTypeId?: Maybe<any>, flareStackDiameter?: Maybe<number>, isPermanentStaffPresence?: Maybe<boolean>, constructionType?: Maybe<ConstructionType>, workingPressureMpa?: Maybe<number>, tankLocationType?: Maybe<TankLocationType>, polygon?: Maybe<any>, isCircle?: Maybe<boolean>, contentType?: Maybe<{ id?: Maybe<any>, name?: Maybe<string> }>, connectionPoints?: Maybe<Array<Maybe<{ id?: Maybe<any>, pointM?: Maybe<any>, buildingId?: Maybe<any>, typeId?: Maybe<any>, pointType?: Maybe<{ id?: Maybe<any>, name?: Maybe<string>, isExternal?: Maybe<boolean> }> }>>>, affectedAreas?: Maybe<Array<Maybe<AffectedAreasFragmentFragment>>>, thermalRadiationAreas?: Maybe<Array<Maybe<ThermalRadiationAreasFragmentFragment>>> }> };

export type CostAreaTypeQueryVariables = Exact<{
  licenseAreaId: Scalars['Uuid'];
  lon: Scalars['Float'];
  lat: Scalars['Float'];
}>;


export type CostAreaTypeQuery = { costAreaType: CostAreaType };

export type GetDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDataQuery = { allContentTypes?: Maybe<Array<Maybe<{ id?: Maybe<any>, name?: Maybe<string> }>>>, allDems?: Maybe<Array<Maybe<{ id?: Maybe<any>, demType?: Maybe<DemType> }>>>, allRegions?: Maybe<Array<Maybe<{ id?: Maybe<any>, name?: Maybe<string> }>>>, allUsers?: Maybe<Array<Maybe<{ id?: Maybe<any> }>>>, allLicenseAreas?: Maybe<Array<Maybe<{ id?: Maybe<any>, name?: Maybe<string> }>>>, allConnectionTypes?: Maybe<Array<Maybe<{ id?: Maybe<any>, name?: Maybe<string>, widthM?: Maybe<number>, cost?: Maybe<number> }>>>, allConnectionPointTypes?: Maybe<Array<Maybe<{ id?: Maybe<any>, name?: Maybe<string>, isExternal?: Maybe<boolean> }>>>, allBuildingTypes?: Maybe<Array<Maybe<{ id?: Maybe<any>, name?: Maybe<string>, code?: Maybe<string> }>>> };

export type GetScenarionQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetScenarionQuery = { scenario?: Maybe<{ id?: Maybe<any>, name?: Maybe<string>, code?: Maybe<string>, stages?: Maybe<Array<Maybe<{ id?: Maybe<any>, name?: Maybe<string>, code?: Maybe<string>, visualView?: Maybe<string>, allowMultipleMethods?: Maybe<boolean>, order?: Maybe<number>, methods?: Maybe<Array<Maybe<{ id?: Maybe<any>, name?: Maybe<string>, code?: Maybe<string>, programImpl?: Maybe<string>, isRunDefault?: Maybe<boolean> }>>> }>>> }> };

export type GetTaskQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;


export type GetTaskQuery = { task?: Maybe<{ id?: Maybe<any>, developmentArea?: Maybe<any>, windRoseAngleDeg?: Maybe<number>, licenseAreaId?: Maybe<any>, regionId?: Maybe<any>, userId?: Maybe<any>, demId?: Maybe<any>, isFavorite?: Maybe<boolean>, name?: Maybe<string>, status?: Maybe<TaskStatus>, center?: Maybe<any>, scenarioId?: Maybe<any>, buildings?: Maybe<Array<Maybe<BuildingFragmentFragment>>>, sitePlan?: Maybe<{ preparationCost?: Maybe<number>, buildingCoverageRatio?: Maybe<number>, totalUtilityLinesLength?: Maybe<number>, transportationDistance?: Maybe<number>, corridorTotalLengthM?: Maybe<number>, pipelineTotalLengthM?: Maybe<number>, powerlineTotalLengthM?: Maybe<number>, sitePlanArea?: Maybe<number>, buildingArea?: Maybe<number>, internalRoadsArea?: Maybe<number>, earthworkM3?: Maybe<number>, sandM3?: Maybe<number>, coverM2?: Maybe<number>, geotextileLayerM2?: Maybe<number>, slopeThermalLayerM2?: Maybe<number>, thermalLayerM3?: Maybe<number>, rollLawnLayerM2?: Maybe<number>, geogridMakingM2?: Maybe<number>, smallRockM3?: Maybe<number>, sandTransportationTon?: Maybe<number> }>, externalPoints?: Maybe<Array<Maybe<ExtrenalPointsFragmentFragment>>>, taskCalculations?: Maybe<Array<Maybe<{ taskCalculationId?: Maybe<any>, taskId?: Maybe<any>, calculationId?: Maybe<any>, stageId?: Maybe<any>, methodId?: Maybe<any>, isFinalResult?: Maybe<boolean>, status?: Maybe<CalculationStatus> }>>> }> };

export type GetTaskCalculationSubscriptionVariables = Exact<{
  taskId: Scalars['Uuid'];
}>;


export type GetTaskCalculationSubscription = { taskCalculationStatuses?: Maybe<Array<Maybe<{ taskCalculationId?: Maybe<any>, status?: Maybe<CalculationStatus>, errorMessage?: Maybe<string>, debugInfo?: Maybe<string> }>>> };

export type GetTaskLogsSubscriptionVariables = Exact<{
  taskId: Scalars['Uuid'];
}>;


export type GetTaskLogsSubscription = { taskTraces?: Maybe<{ percentage?: Maybe<number>, message?: Maybe<string>, debugInfo?: Maybe<string>, status?: Maybe<TaskStatus> }> };

export type GetAllTaskQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  onlyFavorites?: Maybe<Scalars['Boolean']>;
}>;


export type GetAllTaskQuery = { allTasks: { tasks?: Maybe<Array<Maybe<{ id?: Maybe<any>, name?: Maybe<string>, isFavorite?: Maybe<boolean>, status?: Maybe<TaskStatus>, created?: Maybe<string>, developmentArea?: Maybe<any>, buildings?: Maybe<Array<Maybe<{ id?: Maybe<any> }>>> }>>>, pageInfo?: Maybe<{ totalItems?: Maybe<number>, totalPages?: Maybe<number>, page?: Maybe<number>, perPage?: Maybe<number>, hasNextPage?: Maybe<boolean>, hasPreviousPage?: Maybe<boolean> }> } };

export type LikeTaskMutationVariables = Exact<{
  taskId: Scalars['Uuid'];
}>;


export type LikeTaskMutation = { taskMutation?: Maybe<{ likeTask?: Maybe<{ recordId?: Maybe<any> }> }> };

export type RenameTaskMutationVariables = Exact<{
  input: RenameTaskInput;
}>;


export type RenameTaskMutation = { taskMutation?: Maybe<{ renameTask?: Maybe<{ ok: boolean }> }> };

export type GetTaskStatusSubscriptionVariables = Exact<{
  taskId: Scalars['Uuid'];
}>;


export type GetTaskStatusSubscription = { taskStatus?: Maybe<{ taskStatus?: Maybe<TaskStatus> }> };

export type UnlikeTaskMutationVariables = Exact<{
  taskId: Scalars['Uuid'];
}>;


export type UnlikeTaskMutation = { taskMutation?: Maybe<{ unlikeTask?: Maybe<{ recordId?: Maybe<any> }> }> };

export type UpdateBuildingMutationVariables = Exact<{
  buildingInput: UpdateBuildingInput;
}>;


export type UpdateBuildingMutation = { buildingMutation?: Maybe<{ updateBuilding?: Maybe<{ recordId?: Maybe<any> }> }> };

export type UpdateConnectionMutationVariables = Exact<{
  input: UpdateConnectionInput;
}>;


export type UpdateConnectionMutation = { connection?: Maybe<{ update?: Maybe<{ ok: boolean, connectionPointId: any }> }> };

export type UpdateBetweenBuildingsMutationVariables = Exact<{
  input: UpdateConnectionBetweenBuildingsInput;
}>;


export type UpdateBetweenBuildingsMutation = { connection?: Maybe<{ updateBetweenBuildings?: Maybe<{ ok: boolean, connectionPointId: any }> }> };

export type UpdateCoordinatesMutationVariables = Exact<{
  input: UpdateExternalPointCoordinatesInput;
}>;


export type UpdateCoordinatesMutation = { externalPointMutation?: Maybe<{ updateCoordinates?: Maybe<{ recordId?: Maybe<any> }> }> };

export type UpdateTaskMutationVariables = Exact<{
  taskInput: UpdateTaskInput;
}>;


export type UpdateTaskMutation = { taskMutation?: Maybe<{ updateTask?: Maybe<{ recordId?: Maybe<any> }> }> };

export const ContentTypeFragmentFragmentDoc = gql`
    fragment ContentTypeFragment on ContentType {
  id
  name
}
    `;
export const AffectedAreasFragmentFragmentDoc = gql`
    fragment AffectedAreasFragment on AffectedArea {
  id
  damageLevel
  pressureKpa
  radiusM
  calculationId
}
    `;
export const ThermalRadiationAreasFragmentFragmentDoc = gql`
    fragment ThermalRadiationAreasFragment on ThermalRadiationArea {
  id
  thermalRadiationLevel
  radiusM
  calculationId
}
    `;
export const BuildingFragmentFragmentDoc = gql`
    fragment BuildingFragment on Building {
  id
  name
  buildingTypeId
  length
  width
  radius
  height
  capacity
  label
  fireResistanceRating
  fireDangerRating
  structuralFireHazardClass
  functionalArea
  contentTypeId
  flareStackDiameter
  isPermanentStaffPresence
  constructionType
  workingPressureMpa
  tankLocationType
  polygon
  isCircle
  contentType {
    ...ContentTypeFragment
  }
  affectedAreas {
    ...AffectedAreasFragment
  }
  thermalRadiationAreas {
    ...ThermalRadiationAreasFragment
  }
}
    ${ContentTypeFragmentFragmentDoc}
${AffectedAreasFragmentFragmentDoc}
${ThermalRadiationAreasFragmentFragmentDoc}`;
export const ConnectionPointTypeFragmentFragmentDoc = gql`
    fragment ConnectionPointTypeFragment on ConnectionPointType {
  id
  name
  isExternal
}
    `;
export const ConnectionPointFragmentFragmentDoc = gql`
    fragment ConnectionPointFragment on ConnectionPoint {
  id
  pointM
  buildingId
  typeId
  pointType {
    ...ConnectionPointTypeFragment
  }
}
    ${ConnectionPointTypeFragmentFragmentDoc}`;
export const ConnectionTypeFragmentFragmentDoc = gql`
    fragment ConnectionTypeFragment on ConnectionType {
  id
  name
  widthM
  cost
}
    `;
export const ConnectedBuildingFragmentFragmentDoc = gql`
    fragment ConnectedBuildingFragment on ConnectedBuilding {
  targetBuildingId
  connectionTypeId
  connectionPointTypeId
  offset
  side
  connectionId
  targetBuilding {
    id
  }
  connectionType {
    ...ConnectionTypeFragment
  }
}
    ${ConnectionTypeFragmentFragmentDoc}`;
export const UtilityLineFragmentFragmentDoc = gql`
    fragment UtilityLineFragment on UtilityLine {
  id
  line
  polygon
  startNodeId
  endNodeId
  communicationNetworkId
}
    `;
export const CalculationMethodFragmentFragmentDoc = gql`
    fragment CalculationMethodFragment on Method {
  id
  name
  code
  programImpl
  isRunDefault
}
    `;
export const CalculationStageFragmentFragmentDoc = gql`
    fragment CalculationStageFragment on Stage {
  id
  name
  code
  visualView
  methods {
    ...CalculationMethodFragment
  }
  allowMultipleMethods
  order
  scenarioId
}
    ${CalculationMethodFragmentFragmentDoc}`;
export const ExtrenalPointsFragmentFragmentDoc = gql`
    fragment ExtrenalPointsFragment on ExternalPoint {
  id
  point
  typeId
}
    `;
export const CalculateTask = gql`
    mutation calculateTask($input: CalculateTaskInput!) {
  taskMutation {
    calculateTask(input: $input) {
      recordId
    }
  }
}
    `;
export type CalculateTaskMutationFn = Apollo.MutationFunction<CalculateTaskMutation, CalculateTaskMutationVariables>;
export type CalculateTaskMutationResult = Apollo.MutationResult<CalculateTaskMutation>;
export type CalculateTaskMutationOptions = Apollo.BaseMutationOptions<CalculateTaskMutation, CalculateTaskMutationVariables>;
export const CancelTaskCalculation = gql`
    mutation cancelTaskCalculation($taskId: Uuid!) {
  taskMutation {
    cancelTaskCalculation(taskId: $taskId) {
      ok
    }
  }
}
    `;
export type CancelTaskCalculationMutationFn = Apollo.MutationFunction<CancelTaskCalculationMutation, CancelTaskCalculationMutationVariables>;
export type CancelTaskCalculationMutationResult = Apollo.MutationResult<CancelTaskCalculationMutation>;
export type CancelTaskCalculationMutationOptions = Apollo.BaseMutationOptions<CancelTaskCalculationMutation, CancelTaskCalculationMutationVariables>;
export const CloneTask = gql`
    mutation cloneTask($input: CloneTaskInput!) {
  taskMutation {
    cloneTask(input: $input) {
      record {
        id
        developmentArea
        windRoseAngleDeg
        licenseAreaId
        regionId
        userId
        demId
        isFavorite
        name
        status
        center
        buildings {
          ...BuildingFragment
        }
        sitePlan {
          preparationCost
          buildingCoverageRatio
          totalUtilityLinesLength
          transportationDistance
          corridorTotalLengthM
          pipelineTotalLengthM
          powerlineTotalLengthM
          buildingArea
          earthworkM3
          sandM3
          coverM2
          geotextileLayerM2
          slopeThermalLayerM2
          thermalLayerM3
          rollLawnLayerM2
          geogridMakingM2
          smallRockM3
          sandTransportationTon
        }
        externalPoints {
          ...ExtrenalPointsFragment
        }
        taskCalculations {
          taskCalculationId
          taskId
          calculationId
          stageId
          methodId
          isFinalResult
          status
        }
      }
      recordId
    }
  }
}
    ${BuildingFragmentFragmentDoc}
${ExtrenalPointsFragmentFragmentDoc}`;
export type CloneTaskMutationFn = Apollo.MutationFunction<CloneTaskMutation, CloneTaskMutationVariables>;
export type CloneTaskMutationResult = Apollo.MutationResult<CloneTaskMutation>;
export type CloneTaskMutationOptions = Apollo.BaseMutationOptions<CloneTaskMutation, CloneTaskMutationVariables>;
export const GetConnectionsFromBuilding = gql`
    query getConnectionsFromBuilding($buildingId: Uuid!) {
  connectionsFromBuilding(buildingId: $buildingId) {
    connectionPointId
    connectionTypeName
    targetBuildingName
    connectionPointIsExternal
    connectionPointTypeName
    targetBuildingId
    targetBuildingLabel
    connectionDirection
    connectionPointIsExists
    connectionTypeId
    connectedConnectionPointId
    connectionSide
    pointOffset
    connectionId
    connectionPointTypeId
    locationType
  }
}
    `;
export type GetConnectionsFromBuildingQueryResult = Apollo.QueryResult<GetConnectionsFromBuildingQuery, GetConnectionsFromBuildingQueryVariables>;
export const CreateConnectionBetweenPoints = gql`
    mutation createConnectionBetweenPoints($input: CreateConnectionBetweenConnectionPointsInput!) {
  connection {
    createBetweenConnectionPoints(input: $input) {
      ok
      connection {
        id
        sourceConnectionPoint {
          ...ConnectionPointFragment
        }
        targetConnectionPoint {
          ...ConnectionPointFragment
        }
        sourceBuilding {
          ...BuildingFragment
        }
        targetBuilding {
          ...BuildingFragment
        }
        connectionType {
          ...ConnectionTypeFragment
        }
        utilityLine {
          ...UtilityLineFragment
        }
      }
    }
  }
}
    ${ConnectionPointFragmentFragmentDoc}
${BuildingFragmentFragmentDoc}
${ConnectionTypeFragmentFragmentDoc}
${UtilityLineFragmentFragmentDoc}`;
export type CreateConnectionBetweenPointsMutationFn = Apollo.MutationFunction<CreateConnectionBetweenPointsMutation, CreateConnectionBetweenPointsMutationVariables>;
export type CreateConnectionBetweenPointsMutationResult = Apollo.MutationResult<CreateConnectionBetweenPointsMutation>;
export type CreateConnectionBetweenPointsMutationOptions = Apollo.BaseMutationOptions<CreateConnectionBetweenPointsMutation, CreateConnectionBetweenPointsMutationVariables>;
export const CreateBuilding = gql`
    mutation createBuilding($buildingInput: CreateBuildingInput!) {
  buildingMutation {
    createBuilding(input: $buildingInput) {
      record {
        ...BuildingFragment
      }
      recordId
    }
  }
}
    ${BuildingFragmentFragmentDoc}`;
export type CreateBuildingMutationFn = Apollo.MutationFunction<CreateBuildingMutation, CreateBuildingMutationVariables>;
export type CreateBuildingMutationResult = Apollo.MutationResult<CreateBuildingMutation>;
export type CreateBuildingMutationOptions = Apollo.BaseMutationOptions<CreateBuildingMutation, CreateBuildingMutationVariables>;
export const CreateConnectionBetweenBuildings = gql`
    mutation createConnectionBetweenBuildings($input: CreateConnectionBetweenBuildingsInput!) {
  connection {
    createBetweenBuildings(input: $input) {
      ok
      connectionId
    }
  }
}
    `;
export type CreateConnectionBetweenBuildingsMutationFn = Apollo.MutationFunction<CreateConnectionBetweenBuildingsMutation, CreateConnectionBetweenBuildingsMutationVariables>;
export type CreateConnectionBetweenBuildingsMutationResult = Apollo.MutationResult<CreateConnectionBetweenBuildingsMutation>;
export type CreateConnectionBetweenBuildingsMutationOptions = Apollo.BaseMutationOptions<CreateConnectionBetweenBuildingsMutation, CreateConnectionBetweenBuildingsMutationVariables>;
export const CreateTask = gql`
    mutation createTask($taskInput: CreateTaskInput!) {
  taskMutation {
    createTask(input: $taskInput) {
      recordId
    }
  }
}
    `;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const CreateTaskCalculation = gql`
    mutation createTaskCalculation($input: CreateTaskCalculationInput!) {
  taskCalculationMutation {
    createTaskCalculation(input: $input) {
      recordId
      record {
        taskCalculationId
        taskId
        calculationId
        stageId
        methodId
        isFinalResult
        status
      }
    }
  }
}
    `;
export type CreateTaskCalculationMutationFn = Apollo.MutationFunction<CreateTaskCalculationMutation, CreateTaskCalculationMutationVariables>;
export type CreateTaskCalculationMutationResult = Apollo.MutationResult<CreateTaskCalculationMutation>;
export type CreateTaskCalculationMutationOptions = Apollo.BaseMutationOptions<CreateTaskCalculationMutation, CreateTaskCalculationMutationVariables>;
export const CreateTaskCalculationByStage = gql`
    mutation createTaskCalculationByStage($input: CreateTaskCalculationByStageInput!) {
  taskCalculationMutation {
    createTaskCalculationByStage(input: $input) {
      record {
        taskCalculationId
        taskId
        calculationId
        stageId
        methodId
        isFinalResult
        status
      }
    }
  }
}
    `;
export type CreateTaskCalculationByStageMutationFn = Apollo.MutationFunction<CreateTaskCalculationByStageMutation, CreateTaskCalculationByStageMutationVariables>;
export type CreateTaskCalculationByStageMutationResult = Apollo.MutationResult<CreateTaskCalculationByStageMutation>;
export type CreateTaskCalculationByStageMutationOptions = Apollo.BaseMutationOptions<CreateTaskCalculationByStageMutation, CreateTaskCalculationByStageMutationVariables>;
export const RemoveBuilding = gql`
    mutation removeBuilding($id: Uuid!) {
  buildingMutation {
    deleteBuilding(buildingId: $id) {
      ok
    }
  }
}
    `;
export type RemoveBuildingMutationFn = Apollo.MutationFunction<RemoveBuildingMutation, RemoveBuildingMutationVariables>;
export type RemoveBuildingMutationResult = Apollo.MutationResult<RemoveBuildingMutation>;
export type RemoveBuildingMutationOptions = Apollo.BaseMutationOptions<RemoveBuildingMutation, RemoveBuildingMutationVariables>;
export const DeleteConnection = gql`
    mutation deleteConnection($input: DeleteConnectionInput!) {
  connection {
    delete(input: $input) {
      ok
    }
  }
}
    `;
export type DeleteConnectionMutationFn = Apollo.MutationFunction<DeleteConnectionMutation, DeleteConnectionMutationVariables>;
export type DeleteConnectionMutationResult = Apollo.MutationResult<DeleteConnectionMutation>;
export type DeleteConnectionMutationOptions = Apollo.BaseMutationOptions<DeleteConnectionMutation, DeleteConnectionMutationVariables>;
export const DeleteTask = gql`
    mutation deleteTask($taskId: Uuid!) {
  taskMutation {
    deleteTask(taskId: $taskId) {
      ok
    }
  }
}
    `;
export type DeleteTaskMutationFn = Apollo.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const DeleteTaskCalculation = gql`
    mutation deleteTaskCalculation($taskCalculationId: Uuid!) {
  taskCalculationMutation {
    deleteTaskCalculation(taskCalculationId: $taskCalculationId) {
      ok
    }
  }
}
    `;
export type DeleteTaskCalculationMutationFn = Apollo.MutationFunction<DeleteTaskCalculationMutation, DeleteTaskCalculationMutationVariables>;
export type DeleteTaskCalculationMutationResult = Apollo.MutationResult<DeleteTaskCalculationMutation>;
export type DeleteTaskCalculationMutationOptions = Apollo.BaseMutationOptions<DeleteTaskCalculationMutation, DeleteTaskCalculationMutationVariables>;
export const DeleteTaskCalculationByStage = gql`
    mutation deleteTaskCalculationByStage($input: DeleteTaskCalculationByStageInput!) {
  taskCalculationMutation {
    deleteTaskCalculationByStage(input: $input) {
      ok
    }
  }
}
    `;
export type DeleteTaskCalculationByStageMutationFn = Apollo.MutationFunction<DeleteTaskCalculationByStageMutation, DeleteTaskCalculationByStageMutationVariables>;
export type DeleteTaskCalculationByStageMutationResult = Apollo.MutationResult<DeleteTaskCalculationByStageMutation>;
export type DeleteTaskCalculationByStageMutationOptions = Apollo.BaseMutationOptions<DeleteTaskCalculationByStageMutation, DeleteTaskCalculationByStageMutationVariables>;
export const GetAllBuildingTypes = gql`
    query getAllBuildingTypes {
  allBuildingTypes {
    id
    name
    code
  }
}
    `;
export type GetAllBuildingTypesQueryResult = Apollo.QueryResult<GetAllBuildingTypesQuery, GetAllBuildingTypesQueryVariables>;
export const GetAllConnectionTypes = gql`
    query getAllConnectionTypes {
  allConnectionTypes {
    id
    name
    widthM
    cost
  }
}
    `;
export type GetAllConnectionTypesQueryResult = Apollo.QueryResult<GetAllConnectionTypesQuery, GetAllConnectionTypesQueryVariables>;
export const GetAllConnectionsPointTypes = gql`
    query getAllConnectionsPointTypes {
  allConnectionPointTypes {
    id
    name
    isExternal
  }
}
    `;
export type GetAllConnectionsPointTypesQueryResult = Apollo.QueryResult<GetAllConnectionsPointTypesQuery, GetAllConnectionsPointTypesQueryVariables>;
export const GetBuildingCenter = gql`
    query getBuildingCenter($buildingId: Uuid!, $taskId: Uuid!) {
  buildingCenterByTaskId(buildingId: $buildingId, taskId: $taskId) {
    center
    buildingId
    taskId
  }
}
    `;
export type GetBuildingCenterQueryResult = Apollo.QueryResult<GetBuildingCenterQuery, GetBuildingCenterQueryVariables>;
export const GetBuildingParameters = gql`
    query getBuildingParameters($id: Uuid!, $taskId: Uuid!) {
  building(id: $id, taskId: $taskId) {
    id
    name
    buildingTypeId
    length
    width
    radius
    height
    capacity
    label
    fireResistanceRating
    fireDangerRating
    structuralFireHazardClass
    functionalArea
    contentTypeId
    flareStackDiameter
    isPermanentStaffPresence
    constructionType
    workingPressureMpa
    tankLocationType
    contentType {
      id
      name
    }
    connectionPoints {
      id
      pointM
      buildingId
      typeId
      pointType {
        id
        name
        isExternal
      }
    }
    polygon
    affectedAreas {
      ...AffectedAreasFragment
    }
    thermalRadiationAreas {
      ...ThermalRadiationAreasFragment
    }
    isCircle
  }
}
    ${AffectedAreasFragmentFragmentDoc}
${ThermalRadiationAreasFragmentFragmentDoc}`;
export type GetBuildingParametersQueryResult = Apollo.QueryResult<GetBuildingParametersQuery, GetBuildingParametersQueryVariables>;
export const CostAreaType = gql`
    query costAreaType($licenseAreaId: Uuid!, $lon: Float!, $lat: Float!) {
  costAreaType(licenseAreaId: $licenseAreaId, lon: $lon, lat: $lat)
}
    `;
export type CostAreaTypeQueryResult = Apollo.QueryResult<CostAreaTypeQuery, CostAreaTypeQueryVariables>;
export const GetData = gql`
    query getData {
  allContentTypes {
    id
    name
  }
  allDems {
    id
    demType
  }
  allRegions {
    id
    name
  }
  allUsers {
    id
  }
  allLicenseAreas {
    id
    name
  }
  allConnectionTypes {
    id
    name
    widthM
    cost
  }
  allConnectionPointTypes {
    id
    name
    isExternal
  }
  allBuildingTypes {
    id
    name
    code
  }
}
    `;
export type GetDataQueryResult = Apollo.QueryResult<GetDataQuery, GetDataQueryVariables>;
export const GetScenarion = gql`
    query getScenarion($id: Uuid!) {
  scenario(id: $id) {
    id
    name
    code
    stages {
      id
      name
      code
      visualView
      methods {
        id
        name
        code
        programImpl
        isRunDefault
      }
      allowMultipleMethods
      order
    }
  }
}
    `;
export type GetScenarionQueryResult = Apollo.QueryResult<GetScenarionQuery, GetScenarionQueryVariables>;
export const GetTask = gql`
    query getTask($id: Uuid!) {
  task(taskId: $id) {
    id
    developmentArea
    windRoseAngleDeg
    licenseAreaId
    regionId
    userId
    demId
    isFavorite
    name
    status
    center
    scenarioId
    buildings {
      ...BuildingFragment
    }
    sitePlan {
      preparationCost
      buildingCoverageRatio
      totalUtilityLinesLength
      transportationDistance
      corridorTotalLengthM
      pipelineTotalLengthM
      powerlineTotalLengthM
      sitePlanArea
      buildingArea
      internalRoadsArea
      earthworkM3
      sandM3
      coverM2
      geotextileLayerM2
      slopeThermalLayerM2
      thermalLayerM3
      rollLawnLayerM2
      geogridMakingM2
      smallRockM3
      sandTransportationTon
    }
    externalPoints {
      ...ExtrenalPointsFragment
    }
    taskCalculations {
      taskCalculationId
      taskId
      calculationId
      stageId
      methodId
      isFinalResult
      status
    }
  }
}
    ${BuildingFragmentFragmentDoc}
${ExtrenalPointsFragmentFragmentDoc}`;
export type GetTaskQueryResult = Apollo.QueryResult<GetTaskQuery, GetTaskQueryVariables>;
export const GetTaskCalculation = gql`
    subscription getTaskCalculation($taskId: Uuid!) {
  taskCalculationStatuses(taskId: $taskId) {
    taskCalculationId
    status
    errorMessage
    debugInfo
  }
}
    `;
export type GetTaskCalculationSubscriptionResult = Apollo.SubscriptionResult<GetTaskCalculationSubscription>;
export const GetTaskLogs = gql`
    subscription getTaskLogs($taskId: Uuid!) {
  taskTraces(taskId: $taskId) {
    percentage
    message
    debugInfo
    status
  }
}
    `;
export type GetTaskLogsSubscriptionResult = Apollo.SubscriptionResult<GetTaskLogsSubscription>;
export const GetAllTask = gql`
    query getAllTask($page: Int, $perPage: Int, $onlyFavorites: Boolean) {
  allTasks(page: $page, perPage: $perPage, onlyFavorites: $onlyFavorites) {
    tasks {
      id
      name
      isFavorite
      status
      created
      developmentArea
      buildings {
        id
      }
    }
    pageInfo {
      totalItems
      totalPages
      page
      perPage
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;
export type GetAllTaskQueryResult = Apollo.QueryResult<GetAllTaskQuery, GetAllTaskQueryVariables>;
export const LikeTask = gql`
    mutation likeTask($taskId: Uuid!) {
  taskMutation {
    likeTask(taskId: $taskId) {
      recordId
    }
  }
}
    `;
export type LikeTaskMutationFn = Apollo.MutationFunction<LikeTaskMutation, LikeTaskMutationVariables>;
export type LikeTaskMutationResult = Apollo.MutationResult<LikeTaskMutation>;
export type LikeTaskMutationOptions = Apollo.BaseMutationOptions<LikeTaskMutation, LikeTaskMutationVariables>;
export const RenameTask = gql`
    mutation renameTask($input: RenameTaskInput!) {
  taskMutation {
    renameTask(input: $input) {
      ok
    }
  }
}
    `;
export type RenameTaskMutationFn = Apollo.MutationFunction<RenameTaskMutation, RenameTaskMutationVariables>;
export type RenameTaskMutationResult = Apollo.MutationResult<RenameTaskMutation>;
export type RenameTaskMutationOptions = Apollo.BaseMutationOptions<RenameTaskMutation, RenameTaskMutationVariables>;
export const GetTaskStatus = gql`
    subscription getTaskStatus($taskId: Uuid!) {
  taskStatus(taskId: $taskId) {
    taskStatus
  }
}
    `;
export type GetTaskStatusSubscriptionResult = Apollo.SubscriptionResult<GetTaskStatusSubscription>;
export const UnlikeTask = gql`
    mutation unlikeTask($taskId: Uuid!) {
  taskMutation {
    unlikeTask(taskId: $taskId) {
      recordId
    }
  }
}
    `;
export type UnlikeTaskMutationFn = Apollo.MutationFunction<UnlikeTaskMutation, UnlikeTaskMutationVariables>;
export type UnlikeTaskMutationResult = Apollo.MutationResult<UnlikeTaskMutation>;
export type UnlikeTaskMutationOptions = Apollo.BaseMutationOptions<UnlikeTaskMutation, UnlikeTaskMutationVariables>;
export const UpdateBuilding = gql`
    mutation updateBuilding($buildingInput: UpdateBuildingInput!) {
  buildingMutation {
    updateBuilding(input: $buildingInput) {
      recordId
    }
  }
}
    `;
export type UpdateBuildingMutationFn = Apollo.MutationFunction<UpdateBuildingMutation, UpdateBuildingMutationVariables>;
export type UpdateBuildingMutationResult = Apollo.MutationResult<UpdateBuildingMutation>;
export type UpdateBuildingMutationOptions = Apollo.BaseMutationOptions<UpdateBuildingMutation, UpdateBuildingMutationVariables>;
export const UpdateConnection = gql`
    mutation updateConnection($input: UpdateConnectionInput!) {
  connection {
    update(input: $input) {
      ok
      connectionPointId
    }
  }
}
    `;
export type UpdateConnectionMutationFn = Apollo.MutationFunction<UpdateConnectionMutation, UpdateConnectionMutationVariables>;
export type UpdateConnectionMutationResult = Apollo.MutationResult<UpdateConnectionMutation>;
export type UpdateConnectionMutationOptions = Apollo.BaseMutationOptions<UpdateConnectionMutation, UpdateConnectionMutationVariables>;
export const UpdateBetweenBuildings = gql`
    mutation updateBetweenBuildings($input: UpdateConnectionBetweenBuildingsInput!) {
  connection {
    updateBetweenBuildings(input: $input) {
      ok
      connectionPointId
    }
  }
}
    `;
export type UpdateBetweenBuildingsMutationFn = Apollo.MutationFunction<UpdateBetweenBuildingsMutation, UpdateBetweenBuildingsMutationVariables>;
export type UpdateBetweenBuildingsMutationResult = Apollo.MutationResult<UpdateBetweenBuildingsMutation>;
export type UpdateBetweenBuildingsMutationOptions = Apollo.BaseMutationOptions<UpdateBetweenBuildingsMutation, UpdateBetweenBuildingsMutationVariables>;
export const UpdateCoordinates = gql`
    mutation updateCoordinates($input: UpdateExternalPointCoordinatesInput!) {
  externalPointMutation {
    updateCoordinates(input: $input) {
      recordId
    }
  }
}
    `;
export type UpdateCoordinatesMutationFn = Apollo.MutationFunction<UpdateCoordinatesMutation, UpdateCoordinatesMutationVariables>;
export type UpdateCoordinatesMutationResult = Apollo.MutationResult<UpdateCoordinatesMutation>;
export type UpdateCoordinatesMutationOptions = Apollo.BaseMutationOptions<UpdateCoordinatesMutation, UpdateCoordinatesMutationVariables>;
export const UpdateTask = gql`
    mutation updateTask($taskInput: UpdateTaskInput!) {
  taskMutation {
    updateTask(input: $taskInput) {
      recordId
    }
  }
}
    `;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
