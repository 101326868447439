import gql from 'graphql-tag';

export default gql`
  query getAllTask($page: Int, $perPage: Int, $onlyFavorites: Boolean) {
    allTasks(page: $page, perPage: $perPage, onlyFavorites: $onlyFavorites) {
      tasks {
        id
        name
        isFavorite
        status
        created
        # calculationType
        developmentArea
        buildings {
          id
        }
      }
      pageInfo {
        totalItems
        totalPages
        page
        perPage
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
