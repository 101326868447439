import gql from 'graphql-tag';

export default gql`
  mutation createTaskCalculation($input: CreateTaskCalculationInput!) {
    taskCalculationMutation {
      createTaskCalculation(input: $input) {
        recordId
        record {
          taskCalculationId
          taskId
          calculationId
          stageId
          methodId
          isFinalResult
          status
        }
      }
    }
  }
`;
