import { client } from 'apollo';

import {
  GetTaskCalculation,
  GetTaskCalculationSubscription,
  GetTaskLogs,
  GetTaskLogsSubscription,
  GetTaskStatus,
  GetTaskStatusSubscription,
} from '../generated/graphql';

export const getLogs = (taskId: string) =>
  client.subscribe<GetTaskLogsSubscription>({
    query: GetTaskLogs,
    variables: {
      taskId,
    },
    fetchPolicy: 'no-cache',
  });

export const getTaskCalculation = (taskId: string) =>
  client.subscribe<GetTaskCalculationSubscription>({
    query: GetTaskCalculation,
    variables: {
      taskId,
    },
    fetchPolicy: 'no-cache',
  });

export const getTaskStatus = (taskId: string) =>
  client.subscribe<GetTaskStatusSubscription>({
    query: GetTaskStatus,
    variables: {
      taskId,
    },
    fetchPolicy: 'no-cache',
  });
