import gql from 'graphql-tag';

export default gql`
  mutation likeTask($taskId: Uuid!) {
    taskMutation {
      likeTask(taskId: $taskId) {
        recordId
      }
    }
  }
`;
