import gql from 'graphql-tag';

export const AFFECTED_AREAS_FRAGMENT = gql`
  fragment AffectedAreasFragment on AffectedArea {
    id
    damageLevel
    pressureKpa
    radiusM
    calculationId
  }
`;

export const THERMAL_RADIATION_AREAS_FRAGMENT = gql`
  fragment ThermalRadiationAreasFragment on ThermalRadiationArea {
    id
    thermalRadiationLevel
    radiusM
    calculationId
  }
`;
