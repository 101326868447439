import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { ReactComponent as RulerIcon } from 'assets/images/ruler.svg';
import classnames from 'classnames';

import { RootState } from '../../../store/configureStore';
import { setShowRuler } from '../../../store/mapSlice';
import { IMapSlice } from '../../../store/types';

interface Props {
  map: IMapSlice;
}

function RulerButton(props: Props) {
  const { map } = props;
  const dispatch = useDispatch();

  const keysHandler = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      dispatch(setShowRuler(false));
      document.removeEventListener('keyup', keysHandler);
    }
  };

  const onClickHandler = () => {
    dispatch(setShowRuler(!map.showRuler));
    if (!map.showRuler) {
      document.addEventListener('keyup', keysHandler);
    } else {
      document.removeEventListener('keyup', keysHandler);
    }
  };

  return (
    <button
      type="button"
      onClick={onClickHandler}
      className={classnames('control-button', { active: map.showRuler })}
    >
      <RulerIcon className="control-button__icon" />
      <span className="control-button__text">Линейка</span>
    </button>
  );
}

const mapStateToProps = (state: RootState) => ({
  map: state.map,
});

export default connect(mapStateToProps)(RulerButton);
