import * as React from 'react';
import { Provider } from 'react-redux';
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import TaskList from 'containers/TaskList';
import store from 'store/configureStore';

import RootContainer from './containers/RootContainer';

import './App.scss';

function App(props: any) {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/task-list">
            <TaskList />
          </Route>
          <Route path="/result/:taskId">
            <RootContainer />
          </Route>
          <Route path="/create">
            <RootContainer />
          </Route>
          <Route path="/">
            <Redirect to="/task-list" />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
