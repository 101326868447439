import gql from 'graphql-tag';

export default gql`
  mutation createTask($taskInput: CreateTaskInput!) {
    taskMutation {
      createTask(input: $taskInput) {
        recordId
      }
    }
  }
`;
