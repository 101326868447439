import { CreateBuildingInput } from 'generated/graphql';
import { SidebarState } from 'store/configureStore';

type UpdateValueArgType = {
  index: number;
  value: number | string | null;
  field: string;
};

export const composeUpdateRules = (acc = {}, cur: UpdateValueArgType) => {
  if (!acc[cur.index]) {
    acc[cur.index] = { [cur.field]: { $set: cur.value } };
  } else {
    acc[cur.index][cur.field] = { $set: cur.value };
  }

  return acc;
};

export const menuTypes = {
  rename: {
    text: 'Переименовать',
  },
  remove: {
    text: 'Удалить',
  },
};

export const defaultBuildingProps: Pick<
  CreateBuildingInput,
  | 'width'
  | 'length'
  | 'fireResistanceRating'
  | 'fireDangerRating'
  | 'structuralFireHazardClass'
  | 'functionalArea'
  | 'label'
  | 'capacity'
  | 'contentTypeId'
  | 'isPermanentStaffPresence'
  | 'constructionType'
  | 'workingPressureMpa'
  | 'tankLocationType'
> = {
  width: 10,
  length: 10,
  fireResistanceRating: 'FIVE',
  fireDangerRating: 'ENHANCED_EXPLOSIVE',
  structuralFireHazardClass: 'C0',
  functionalArea: 'ONE',
  label: '',
  contentTypeId: null,
  capacity: 10.0,
  isPermanentStaffPresence: false,
  constructionType: 'OPEN_AREA',
  workingPressureMpa: 0,
  tankLocationType: null,
};

export const composeBuildings = (
  buildingTypes: SidebarState['allBuildingTypes'],
) =>
  buildingTypes?.reduce((acc: any = {}, cur) => {
    acc[cur!.id] = { text: cur?.name };
    return acc;
  }, {});
