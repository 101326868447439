import { client } from 'apollo';
import store from 'store/configureStore';
import { handleError } from 'store/sidebarSlice';

import {
  Building,
  CalculateTask,
  CalculateTaskInput,
  CalculateTaskMutation,
  CancelTaskCalculation,
  CancelTaskCalculationMutation,
  CloneTask,
  CloneTaskInput,
  CloneTaskMutation,
  CostAreaType,
  CostAreaTypeQuery,
  CostAreaTypeQueryVariables,
  CreateBuilding,
  CreateBuildingMutation,
  CreateConnectionBetweenBuildings,
  CreateConnectionBetweenBuildingsInput,
  CreateConnectionBetweenBuildingsMutation,
  CreateConnectionBetweenConnectionPointsInput,
  CreateConnectionBetweenPoints,
  CreateConnectionBetweenPointsMutation,
  CreateTask,
  CreateTaskCalculation,
  CreateTaskCalculationByStage,
  CreateTaskCalculationByStageInput,
  CreateTaskCalculationByStageMutation,
  CreateTaskCalculationInput,
  CreateTaskCalculationMutation,
  CreateTaskMutationResult,
  DeleteConnection,
  DeleteConnectionMutation,
  DeleteTask,
  DeleteTaskCalculation,
  DeleteTaskCalculationByStage,
  DeleteTaskCalculationByStageInput,
  DeleteTaskCalculationByStageMutation,
  DeleteTaskCalculationMutation,
  DeleteTaskMutation,
  GetAllBuildingTypes,
  GetAllBuildingTypesQuery,
  GetAllConnectionsPointTypes,
  GetAllConnectionsPointTypesQuery,
  GetAllConnectionTypes,
  GetAllConnectionTypesQuery,
  GetAllTask,
  GetAllTaskQuery,
  GetBuildingCenter,
  GetBuildingCenterQuery,
  GetBuildingParameters,
  GetConnectionsFromBuilding,
  GetConnectionsFromBuildingQuery,
  GetData,
  GetDataQuery,
  GetScenarion,
  GetScenarionQuery,
  GetTask,
  GetTaskQuery,
  LikeTask,
  LikeTaskMutation,
  RemoveBuilding,
  RemoveBuildingMutation,
  RenameTask,
  RenameTaskMutation,
  UnlikeTask,
  UnlikeTaskMutation,
  UpdateBetweenBuildings,
  UpdateBetweenBuildingsMutation,
  UpdateBuilding,
  UpdateBuildingInput,
  UpdateBuildingMutation,
  UpdateConnection,
  UpdateConnectionBetweenBuildingsInput,
  UpdateConnectionMutation,
  UpdateCoordinates,
  UpdateCoordinatesMutation,
  UpdateTask,
  UpdateTaskMutation,
} from '../generated/graphql';

const dispatch = store.dispatch;

export const getBuildingCenterByTaskId = (buildingId: string, taskId: string) =>
  client
    .query<GetBuildingCenterQuery>({
      query: GetBuildingCenter,
      variables: {
        buildingId,
        taskId,
      },
    })
    .then(({ data }) => data.buildingCenterByTaskId?.center)
    .catch(e => console.log('E', e));

export const cancelTaskCalculation = (uid: string) =>
  client
    .mutate<CancelTaskCalculationMutation>({
      mutation: CancelTaskCalculation,
      variables: { taskId: uid },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const createTaskCalculation = (params: CreateTaskCalculationInput) =>
  client
    .mutate<CreateTaskCalculationMutation>({
      mutation: CreateTaskCalculation,
      variables: { input: params },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const createTaskCalculationByStage = (
  params: CreateTaskCalculationByStageInput,
) =>
  client
    .mutate<CreateTaskCalculationByStageMutation>({
      mutation: CreateTaskCalculationByStage,
      variables: { input: params },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const deleteTaskCalculation = (uid: string) =>
  client
    .mutate<DeleteTaskCalculationMutation>({
      mutation: DeleteTaskCalculation,
      variables: { taskCalculationId: uid },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const deleteTaskCalculationByStage = (
  params: DeleteTaskCalculationByStageInput,
) =>
  client
    .mutate<DeleteTaskCalculationByStageMutation>({
      mutation: DeleteTaskCalculationByStage,
      variables: { input: params },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const getAllTasks = (
  page: number,
  perPage: number,
  onlyFavorites: boolean,
) =>
  client
    .query<GetAllTaskQuery>({
      query: GetAllTask,
      variables: {
        page,
        perPage,
        onlyFavorites,
      },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const getScenario = (id: string) =>
  client
    .query<GetScenarionQuery>({
      query: GetScenarion,
      variables: {
        id,
      },
    })
    .then(({ data }) => data.scenario)
    .catch(e => console.log('E', e));

export const likeTask = (taskId: string) =>
  client
    .mutate<LikeTaskMutation>({
      mutation: LikeTask,
      variables: { taskId },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const unLikeTask = (taskId: string) =>
  client
    .mutate<UnlikeTaskMutation>({
      mutation: UnlikeTask,
      variables: { taskId },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const getAreaType = ({
  licenseAreaId,
  lon,
  lat,
}: CostAreaTypeQueryVariables) =>
  client
    .query<CostAreaTypeQuery, CostAreaTypeQueryVariables>({
      query: CostAreaType,
      variables: {
        licenseAreaId,
        lon,
        lat,
      },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const getBuildingParameters = (id: string, taskId: string) =>
  client
    .query<Building>({
      query: GetBuildingParameters,
      variables: {
        id,
        taskId,
      },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const updateCoordinates = input =>
  client
    .mutate<UpdateCoordinatesMutation>({
      mutation: UpdateCoordinates,
      variables: {
        input,
      },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const getAllBuildingTypes = () =>
  client
    .query<GetAllBuildingTypesQuery>({
      query: GetAllBuildingTypes,
    })
    .then(({ data }) => data.allBuildingTypes)
    .catch(e => console.log('E', e));

export const getAllConnectionTypes = () =>
  client
    .query<GetAllConnectionTypesQuery>({
      query: GetAllConnectionTypes,
      fetchPolicy: 'no-cache',
    })
    .then(({ data }) => data.allConnectionTypes)
    .catch(e => console.log('E', e));

export const getAllConnectionPointTypes = () =>
  client
    .query<GetAllConnectionsPointTypesQuery>({
      query: GetAllConnectionsPointTypes,
      fetchPolicy: 'no-cache',
    })
    .then(({ data }) => data.allConnectionPointTypes)
    .catch(e => console.log('E', e));

export const getConnectionsFromBuildings = (uid: string) =>
  client
    .query<GetConnectionsFromBuildingQuery>({
      query: GetConnectionsFromBuilding,
      variables: { buildingId: uid },
      fetchPolicy: 'no-cache',
    })
    .then(({ data }) => data.connectionsFromBuilding)
    .catch(e => console.log('E', e));

export const getTask = (id: string) =>
  client
    .query<GetTaskQuery>({
      query: GetTask,
      variables: { id },
      fetchPolicy: 'no-cache',
    })
    .then(({ data }) => data?.task)
    .catch(e => console.log('E', e));

export const createTask = ({ taskInput }) =>
  client
    .mutate<CreateTaskMutationResult['data']>({
      mutation: CreateTask,
      variables: {
        taskInput,
      },
    })
    .then(({ data }) => data?.taskMutation?.createTask?.recordId)
    .catch(e => dispatch(handleError(e)));

export const createConnectionBetweenPoints = (
  params: CreateConnectionBetweenConnectionPointsInput,
) =>
  client
    .mutate<CreateConnectionBetweenPointsMutation>({
      mutation: CreateConnectionBetweenPoints,
      variables: { input: params },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const createConnectionBetweenBuildings = (
  params: CreateConnectionBetweenBuildingsInput,
) =>
  client
    .mutate<CreateConnectionBetweenBuildingsMutation>({
      mutation: CreateConnectionBetweenBuildings,
      variables: { input: params },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const updateConnectionBetweenBuildings = (
  params: UpdateConnectionBetweenBuildingsInput,
) =>
  client
    .mutate<UpdateBetweenBuildingsMutation>({
      mutation: UpdateBetweenBuildings,
      variables: { input: params },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const updateConnection = ({ connectionInput }) =>
  client.mutate<UpdateConnectionMutation>({
    mutation: UpdateConnection,
    variables: { input: connectionInput },
  });

export const renameTask = ({ input }) => {
  client
    .mutate<RenameTaskMutation>({
      mutation: RenameTask,
      variables: { input },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));
};

export const getData = () =>
  client
    .query<GetDataQuery>({ query: GetData })
    .then(({ data }) => data)
    .catch(e => dispatch(handleError(e)));

export const createBuilding = ({ buildingInput }) =>
  client
    .mutate<CreateBuildingMutation>({
      mutation: CreateBuilding,
      variables: {
        buildingInput,
      },
    })
    .then(({ data }) => data?.buildingMutation?.createBuilding)
    .catch(e => console.log('E', e));

export const removeBuilding = ({ id }) =>
  client.mutate<RemoveBuildingMutation>({
    mutation: RemoveBuilding,
    variables: {
      id,
    },
  });

export const updateBuilding = (params: UpdateBuildingInput) =>
  client
    .mutate<UpdateBuildingMutation>({
      mutation: UpdateBuilding,
      variables: {
        buildingInput: params,
      },
    })
    .then(({ data }) => data?.buildingMutation?.updateBuilding?.recordId)
    .catch(e => console.log('E', e));

export const calculateTask = (params: CalculateTaskInput) =>
  client
    .mutate<CalculateTaskMutation>({
      mutation: CalculateTask,
      variables: {
        input: params,
      },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const updateTask = ({ taskInput }) =>
  client
    .mutate<UpdateTaskMutation>({
      mutation: UpdateTask,
      variables: {
        taskInput,
      },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const cloneTask = (taskId: CloneTaskInput) =>
  client
    .mutate<CloneTaskMutation>({
      mutation: CloneTask,
      variables: {
        input: taskId,
      },
    })
    .then(({ data }) => data)
    .catch(e => dispatch(handleError(e)));

export const deleteConnection = ({ connectionId }) =>
  client
    .mutate<DeleteConnectionMutation>({
      mutation: DeleteConnection,
      variables: { input: { connectionId } },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));

export const deleteTask = ({ taskId }) =>
  client
    .mutate<DeleteTaskMutation>({
      mutation: DeleteTask,
      variables: {
        taskId,
      },
    })
    .then(({ data }) => data)
    .catch(e => console.log('E', e));
