import * as React from 'react';

import { ReactComponent as Logo } from 'assets/images/logo.svg';

import PlusIcon from '../../elements/Icons/PlusIcon';

function TaskListTopRow(props: any) {
  return (
    <div className="task-list__top-row">
      <div
        className="task-list__container"
        style={{
          flexDirection: 'row',
          padding: '32px 0',
          position: 'relative',
        }}
      >
        <Logo className="main-logo" />
        <h1 className="main-title">PlanDesign</h1>
        <button
          type="button"
          className="create_task_button"
          onClick={props.createNewProject}
        >
          <PlusIcon />
        </button>
      </div>
    </div>
  );
}

export default TaskListTopRow;
