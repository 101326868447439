import gql from 'graphql-tag';

import {
  AFFECTED_AREAS_FRAGMENT,
  THERMAL_RADIATION_AREAS_FRAGMENT,
} from './areas';
import {
  CONNECTED_BUILDING_FRAGMENT,
  CONNECTION_POINT_FRAGMENT,
} from './connections';
import { CONTENT_TYPE_FRAGMENT } from './types';

export const BUILDING_FRAGMENT = gql`
  fragment BuildingFragment on Building {
    id
    name
    buildingTypeId
    length
    width
    radius
    height
    capacity
    label
    fireResistanceRating
    fireDangerRating
    structuralFireHazardClass
    functionalArea
    contentTypeId
    flareStackDiameter
    isPermanentStaffPresence
    constructionType
    workingPressureMpa
    tankLocationType
    polygon
    isCircle
    contentType {
      ...ContentTypeFragment
    }
    # connectionPoints {
    #   ...ConnectionPointFragment
    # }
    affectedAreas {
      ...AffectedAreasFragment
    }
    thermalRadiationAreas {
      ...ThermalRadiationAreasFragment
    }
    # connectedBuildings {
    #   ...ConnectedBuildingFragment
    # }
  }
  ${CONTENT_TYPE_FRAGMENT}
  # ${CONNECTION_POINT_FRAGMENT}
  ${AFFECTED_AREAS_FRAGMENT}
  ${THERMAL_RADIATION_AREAS_FRAGMENT}
  # ${CONNECTED_BUILDING_FRAGMENT}
`;
