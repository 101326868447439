import * as React from 'react';

import { formatter } from 'utils';
import { PopupPropsType } from './types';

const PopupDemText: React.FC<Pick<PopupPropsType, 'updatedArea'>> = ({
  updatedArea,
}) => (
  <span>
    {formatter.format(updatedArea)}
    <span className="dem-text">км</span>
  </span>
);

export default PopupDemText;
