import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { clearCalculationErrors } from 'store/controlSlice';

import { RootState } from '../../store/configureStore';
import { IControlSlice } from '../../store/types';

interface Props {
  calculationErrors: IControlSlice['calculationErrors'];
}

function CalculationErrorModal(props: Props) {
  const { calculationErrors } = props;

  const dispatch = useDispatch();

  const [errors, setErrors] = useState<boolean>(false);

  const clearErrors = () => {
    dispatch(clearCalculationErrors());
  };

  useEffect(() => {
    if (calculationErrors) {
      setErrors(true);
    }
  }, [calculationErrors]);

  if (!errors) return null;

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgb(0, 0, 0, 0.5)',
        zIndex: 10000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          maxWidth: '460px',
          backgroundColor: '#2F2F2F',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'stretch',
            backgroundColor: '#242425',
            padding: '12px 16px',
          }}
        >
          <p>Отсутствуют данные для расчета</p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            alignSelf: 'stretch',
            flexDirection: 'column',
            padding: '16px',
          }}
        >
          <p>
            Для расчета этапа “Формирование локальных топологий” не хватает
            входных данных
          </p>
          <p>Необходимо рассчитать этапы:</p>
          {['Расчет минимально допустимых расстояний', 'Кластеризация'].map(
            (item, i) => (
              <div key={i} style={{ display: 'flex', flexDirection: 'row' }}>
                <p>&bull;</p>
                <p>&nbsp;{item}</p>
              </div>
            ),
          )}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            alignSelf: 'stretch',
            flexDirection: 'column',
            padding: '16px',
          }}
        >
          <button
            type="button"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#5F9BFA',
              padding: '10px 20px',
              borderRadius: '4px',
            }}
            onClick={clearErrors}
          >
            <p style={{ color: '#fff' }}>Ok</p>
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  calculationErrors: state.control.calculationErrors,
});

export default connect(mapStateToProps)(CalculationErrorModal);
