import React from 'react';

interface Props {
  disabled?: boolean;
  active: boolean;
  handleChange: (e?: any, a?: any) => void;
}

function SimpleCheckbox(props: Props) {
  const { disabled, active, handleChange } = props;
  return (
    <button
      disabled={disabled}
      onClick={handleChange}
      type="button"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '20px',
        height: '20px',
        backgroundColor: disabled ? '#2F2F2F' : '#242425',
        border: '1px solid #4A4A4A',
        borderRadius: '1px',
      }}
    >
      {active && (
        <div
          style={{
            width: '12px',
            height: '12px',
            backgroundColor: '#CCCDCD',
            borderRadius: '1px',
          }}
        />
      )}
    </button>
  );
}

export default SimpleCheckbox;
