import gql from 'graphql-tag';

export default gql`
  mutation deleteTaskCalculationByStage(
    $input: DeleteTaskCalculationByStageInput!
  ) {
    taskCalculationMutation {
      deleteTaskCalculationByStage(input: $input) {
        ok
      }
    }
  }
`;
