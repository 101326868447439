import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { setTaskInitial } from 'store/taskSlice';
import {
  deleteTask,
  paginateTasksList,
  setTasks,
  updateTasksPageInfo,
} from 'store/tasksSlice';

import { deleteTask as removeTask, getAllTasks } from '../../apollo/requests';
import {
  GetAllTaskQuery,
  PageInfo,
  Task,
  TaskPagination,
} from '../../generated/graphql';
import { RootState } from '../../store/configureStore';
import { ITasksSlice } from '../../store/types';
import TaskListBottomRow from './TaskListBottomRow';
import TaskListHandlers from './TaskListHandlers';
import TaskListModal from './TaskListModal';
import TaskListRow from './TaskListRow';
import TaskListTopRow from './TaskListTopRow';

import './style.scss';

interface Props {
  tasks: ITasksSlice;
  // history: any;
}

interface IState extends Partial<GetAllTaskQuery> {
  favorite: boolean;
  showPaginationButton: boolean;
  showModal?: boolean;
  name?: string | null;
  created?: string | null;
  id?: string;
}

function TaskList(props: Props) {
  const { tasks } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = useState<IState>({
    favorite: false,
    showPaginationButton: true,
    id: '',
    name: '',
    created: '',
    allTasks: {
      pageInfo: {
        hasNextPage: true,
        hasPreviousPage: false,
        perPage: 0,
        page: 0,
        totalItems: 0,
        totalPages: 0,
      },
    },
  });

  const [solving, setSolving] = useState(false);

  const { favorite, showModal, name, created } = state;

  const favoriteFilter = favorite
    ? tasks.items.filter(items => items.isFavorite)
    : tasks.items;

  const solvingFilter = solving
    ? favoriteFilter.filter(item => item.status === 'SOLVING')
    : favoriteFilter;

  const fetchTasks = async (page?: number) => {
    const data = await getAllTasks(page || 1, 12, favorite);
    if (data) {
      if (page === 1 || !page) {
        dispatch(setTasks(data.allTasks as TaskPagination));
      } else {
        dispatch(paginateTasksList(data.allTasks.tasks as Task[]));
        dispatch(updateTasksPageInfo(data.allTasks.pageInfo as PageInfo));
      }
    }
  };

  const createNewProject = () => {
    dispatch(setTaskInitial());
    history.push('/create');
  };

  const toggleFavorite = () => {
    setState({
      ...state,
      favorite: !state.favorite,
    });
  };

  const toggleSolving = () => {
    setSolving(!solving);
  };

  const showMore = () => {
    if (tasks && tasks.pageInfo) {
      dispatch(
        updateTasksPageInfo({ page: (tasks?.pageInfo?.page as number) + 1 }),
      );
    }
  };

  const onOpenModal = (task: Task) => {
    setState({
      ...state,
      id: task.id,
      name: task.name,
      created: task.created,
      showModal: true,
    });
  };

  const onRemoveHandler = async () => {
    const { id } = state;
    if (!id) return;

    await removeTask({ taskId: id });
    dispatch(deleteTask(id as string));
    setState({
      ...state,
      showModal: false,
    });
  };

  const onCloseHandler = () =>
    setState({
      ...state,
      showModal: false,
    });

  useEffect(() => {
    if (!tasks.isFetching) {
      fetchTasks();
    }
  }, [tasks]);

  useEffect(() => {
    fetchTasks(tasks.pageInfo.page as number);
  }, [tasks.pageInfo.page]);

  useEffect(() => {
    fetchTasks(tasks.pageInfo.page as number);
  }, [favorite]);

  return (
    <div className="task-list">
      <TaskListTopRow createNewProject={createNewProject} />
      <div className="task-list__container">
        <div>
          <TaskListHandlers
            favorite={favorite}
            solving={solving}
            toggleFavorite={toggleFavorite}
            toggleSolving={toggleSolving}
          />
          {solvingFilter.map((el, i) => (
            <TaskListRow
              onRemoveHandler={onOpenModal}
              key={i}
              task={el}
              history={history}
            />
          ))}
        </div>
        {tasks.pageInfo.hasNextPage && (
          <TaskListBottomRow showMore={showMore} />
        )}
      </div>
      {!!showModal && (
        <TaskListModal
          name={name}
          created={created}
          onClickHandler={onRemoveHandler}
          onCloseHandler={onCloseHandler}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  tasks: state.tasks,
});

export default connect(mapStateToProps)(TaskList);
