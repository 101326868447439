import * as React from 'react';

import { ReactComponent as Plus } from 'assets/images/task-list-plus.svg';

import TaskListButton from './TaskListButton';
import { ITaskListHandlersProps } from './types';

const TaskListHandlers: React.FC<ITaskListHandlersProps> = ({
  favorite,
  solving,
  toggleFavorite,
  toggleSolving,
}) => (
  <div className="task-list-handlers">
    <div>
      <TaskListButton favorite active={favorite} onClick={toggleFavorite}>
        Избранное
      </TaskListButton>
      <TaskListButton active={!favorite} onClick={toggleFavorite}>
        Все проекты
      </TaskListButton>
    </div>
    <button
      type="button"
      className={
        solving ? 'filter_solving_button__pressed' : 'filter_solving_button'
      }
      onClick={toggleSolving}
    >
      <p>Идет расчет</p>
    </button>
  </div>
);

export default TaskListHandlers;
