import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PageInfo, Task, TaskPagination } from '../generated/graphql';
import { ITasksSlice } from './types';

const initialState: ITasksSlice = {
  items: [],
  pageInfo: {
    totalPages: 15,
    totalItems: 0,
    page: 1,
    perPage: 1,
    hasNextPage: false,
    hasPreviousPage: false,
  },
  isFetching: false,
};

export const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setTasks: (state, action: PayloadAction<TaskPagination>) => {
      return {
        items: action.payload.tasks as Task[],
        pageInfo: action.payload.pageInfo as PageInfo,
        isFetching: true,
      };
    },
    updateTasksList: (state, action: PayloadAction<ITasksSlice['items']>) => {
      return {
        ...state,
        items: action.payload,
      };
    },
    paginateTasksList: (state, action: PayloadAction<ITasksSlice['items']>) => {
      return {
        ...state,
        items: [...state.items, ...action.payload],
      };
    },
    updateTasksPageInfo: (
      state,
      action: PayloadAction<ITasksSlice['pageInfo']>,
    ) => {
      return {
        ...state,
        pageInfo: { ...action.payload },
      };
    },
    deleteTask: (state, action: PayloadAction<string>) => {
      let newTasksArr: Task[] = JSON.parse(JSON.stringify(state.items));
      newTasksArr = newTasksArr.filter(item => item.id !== action.payload);

      return {
        ...state,
        items: newTasksArr,
      };
    },
  },
});

export const {
  setTasks,
  updateTasksList,
  updateTasksPageInfo,
  deleteTask,
  paginateTasksList,
} = taskSlice.actions;

export default taskSlice.reducer;
