import gql from 'graphql-tag';

export default gql`
  query getAllBuildingTypes {
    allBuildingTypes {
      id
      name
      code
    }
  }
`;
