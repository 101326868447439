import gql from 'graphql-tag';

import {
  AFFECTED_AREAS_FRAGMENT,
  THERMAL_RADIATION_AREAS_FRAGMENT,
} from './fragments/areas';

export default gql`
  query getBuildingParameters($id: Uuid!, $taskId: Uuid!) {
    building(id: $id, taskId: $taskId) {
      id
      name
      buildingTypeId
      length
      width
      radius
      height
      capacity
      label
      fireResistanceRating
      fireDangerRating
      structuralFireHazardClass
      functionalArea
      contentTypeId
      flareStackDiameter
      isPermanentStaffPresence
      constructionType
      workingPressureMpa
      tankLocationType
      contentType {
        id
        name
      }
      connectionPoints {
        id
        pointM
        buildingId
        typeId
        pointType {
          id
          name
          isExternal
        }
      }
      polygon
      affectedAreas {
        ...AffectedAreasFragment
      }
      thermalRadiationAreas {
        ...ThermalRadiationAreasFragment
      }
      isCircle
    }
  }
  ${AFFECTED_AREAS_FRAGMENT}
  ${THERMAL_RADIATION_AREAS_FRAGMENT}
`;
