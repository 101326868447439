import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { v4 as uuid } from 'uuid';

import {
  createConnectionBetweenBuildings,
  deleteConnection,
  getConnectionsFromBuildings,
  updateConnectionBetweenBuildings,
} from '../../../apollo/requests';
import { ReactComponent as AddButton } from '../../../assets/images/add-plus.svg';
import { ReactComponent as ChevronIcon } from '../../../assets/images/chevrons-right_2.svg';
import {
  ConnectionFromBuilding,
  ConnectionLocationType,
  CreateConnectionBetweenBuildingsInput,
  Side,
  UpdateConnectionBetweenBuildingsInput,
} from '../../../generated/graphql';
import { RootState } from '../../../store/configureStore';
import { updateTaskBuildingsConnections } from '../../../store/taskSlice';
import {
  IControlSlice,
  IDictionariesSlice,
  ITaskSlice,
} from '../../../store/types';
import Table from './Table';

import './style.scss';

interface Props {
  task: ITaskSlice;
  control: IControlSlice;
  dictionaries: IDictionariesSlice;
}

function BuildingsConnect(props: Props) {
  const { task, dictionaries, control } = props;
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [tableHeader, setTableHeader] = useState<any>(null);
  const [newConnectionsIds, setNewConnectionId] = useState<string[]>([]);
  const [changeConnections, setChangeConnection] = useState<any>([]);

  const handleOpen = () => {
    if (control.selectedBuildingUid) {
      setOpen(!isOpen);
    }
  };

  const getConnections = async () => {
    try {
      dispatch(updateTaskBuildingsConnections(null));

      const buildingsConnection = await getConnectionsFromBuildings(
        control.selectedBuildingUid as string,
      );

      if (buildingsConnection && buildingsConnection.length) {
        dispatch(
          updateTaskBuildingsConnections(
            buildingsConnection as ConnectionFromBuilding[],
          ),
        );
      }
    } catch (e) {
      console.log('E', e);
    }
  };

  const prepareBuildingConnections = () => {
    // if (task.buildingConnections && task.buildingConnections.length) {
    const headerArr = [
      { key: 'number', value: '№', rowType: 'string' },
      {
        key: 'connectionSide',
        value: 'Сторона',
        rowType: 'dropdown',
      },
      { key: 'pointOffset', value: 'Отступ', rowType: 'input' },
      {
        key: 'connectionPointTypeId',
        value: 'Тип',
        rowType: 'dropdown',
      },
      {
        key: 'targetBuildingId',
        value: 'Связанное сооружение',
        rowType: 'search',
      },
      { key: 'connectionTypeId', value: 'Тип связи', rowType: 'search' },
      {
        key: 'connectionDirection',
        value: 'Способ прокладки',
        rowType: 'dropdown',
      },
    ];

    setTableHeader(headerArr);
  };

  const handleConfirm = (connectionId: string) => {
    fetchNewRow(connectionId);
  };

  const handleCancel = (connectionId: string) => {
    const connectionsIds = JSON.parse(JSON.stringify(newConnectionsIds));
    const newArr = connectionsIds.filter(
      (item: string) => item !== connectionId,
    );
    setNewConnectionId(newArr);

    let buildingConnections = JSON.parse(
      JSON.stringify(task.buildingConnections),
    ) as ConnectionFromBuilding[];

    buildingConnections = buildingConnections.filter(
      item => item.connectionId !== connectionId,
    );

    dispatch(
      updateTaskBuildingsConnections(
        buildingConnections as ConnectionFromBuilding[],
      ),
    );
  };

  const fetchNewRow = async (connectionId: string) => {
    try {
      const newConnection = task.buildingConnections?.find(
        item => item.connectionId === connectionId,
      );

      const sendObj: CreateConnectionBetweenBuildingsInput = {
        sourceBuildingId: control.selectedBuildingUid,
        sourceBuildingSide: newConnection?.connectionSide as Side,
        targetBuildingId: newConnection?.targetBuildingId,
        targetBuildingSide: newConnection?.connectionSide as Side,
        connectionPointTypeId: newConnection?.connectionPointTypeId,
        connectionTypeId: newConnection?.connectionTypeId,
        locationType: newConnection?.locationType as ConnectionLocationType,
        // pointOffset: newConnection?.pointOffset,
      };

      const result = await createConnectionBetweenBuildings(sendObj);
      if (result && result?.connection?.createBetweenBuildings?.ok) {
        const connectionsIds = JSON.parse(JSON.stringify(newConnectionsIds));
        const newArr = connectionsIds.filter(
          (item: string) => item !== connectionId,
        );
        setNewConnectionId(newArr);
        getConnections();
      }
    } catch (e) {
      console.log('E', e);
    }
  };

  const addRow = async () => {
    const buildingConnections = task.buildingConnections
      ? JSON.parse(JSON.stringify(task.buildingConnections))
      : [];

    const uid = uuid();
    const connectionsIds = JSON.parse(JSON.stringify(newConnectionsIds));
    connectionsIds.push(uid);
    setNewConnectionId(connectionsIds);

    const newPropsRow: ConnectionFromBuilding = {
      connectionId: uid,
      connectionSide: null,
      pointOffset: null,
      connectionPointTypeId: null,
      targetBuildingId: null,
      connectionTypeId: null,
      connectionDirection: null,
      locationType: null,
    };

    buildingConnections.push(newPropsRow);

    dispatch(
      updateTaskBuildingsConnections(
        buildingConnections as ConnectionFromBuilding[],
      ),
    );

    if (!isOpen) handleOpen();
  };

  const updateRow = async (
    connectionId: string,
    type: string,
    value: string | number,
  ) => {
    if (newConnectionsIds.includes(connectionId)) {
      let buildingConnections = JSON.parse(
        JSON.stringify(task.buildingConnections),
      ) as ConnectionFromBuilding[];

      buildingConnections = buildingConnections.map(item => {
        if (item.connectionId === connectionId) {
          item[type] = value;
        }
        return item;
      });

      dispatch(
        updateTaskBuildingsConnections(
          buildingConnections as ConnectionFromBuilding[],
        ),
      );
    }

    const changeConnectionsArr = JSON.parse(JSON.stringify(changeConnections));

    if (
      !changeConnectionsArr.some((a: any) => a.connectionId === connectionId) &&
      !newConnectionsIds.includes(connectionId)
    ) {
      const currentBuildingConnection = task?.buildingConnections?.find(
        item => item.connectionId === connectionId,
      );

      changeConnectionsArr.push(currentBuildingConnection);
      setChangeConnection(changeConnectionsArr);
    }

    if (
      changeConnectionsArr.some((a: any) => a.connectionId === connectionId)
    ) {
      const stateUpdateRow = changeConnectionsArr.map(item => {
        const newItem = JSON.parse(JSON.stringify(item));
        newItem[type] = value;
        return newItem;
      });
      setChangeConnection(stateUpdateRow);
    }
  };

  const confirmChangeConnection = async (connectionId: string) => {
    const currentBuildingConnection = changeConnections.find(
      item => item.connectionId === connectionId,
    );

    const newQueryRow: UpdateConnectionBetweenBuildingsInput = {
      connectionId: currentBuildingConnection?.connectionId,
      targetBuildingId: currentBuildingConnection?.targetBuildingId,
      connectionTypeId: currentBuildingConnection?.connectionTypeId,
      connectionSide: currentBuildingConnection?.connectionSide as Side,
      pointOffset: currentBuildingConnection?.pointOffset as number,
      connectionPointTypeId: currentBuildingConnection?.connectionPointTypeId,
      locationType: currentBuildingConnection?.locationType as ConnectionLocationType,
    };

    const response = await updateConnectionBetweenBuildings(newQueryRow);
    if (response && response?.connection?.updateBetweenBuildings?.ok) {
      let buildingConnections = JSON.parse(
        JSON.stringify(task.buildingConnections),
      ) as ConnectionFromBuilding[];

      buildingConnections = buildingConnections.map(item => {
        let newObj = JSON.parse(JSON.stringify(item));
        if (item.connectionId === connectionId) {
          newObj = { ...newObj, ...newQueryRow };
        }
        return newObj;
      });

      dispatch(
        updateTaskBuildingsConnections(
          buildingConnections as ConnectionFromBuilding[],
        ),
      );
    }

    let changeConnectionsArr = JSON.parse(JSON.stringify(changeConnections));
    changeConnectionsArr = changeConnectionsArr.filter(
      item => item.connectionId !== connectionId,
    );

    setChangeConnection(changeConnectionsArr);
  };

  const cancelChangeConnection = (connectionId: string) => {
    let changeConnectionsArr = JSON.parse(JSON.stringify(changeConnections));
    changeConnectionsArr = changeConnectionsArr.filter(
      item => item.cinnectionId !== connectionId,
    );

    setChangeConnection(changeConnectionsArr);
  };

  const deleteRow = async (connectionId: string) => {
    let buildingConnections = JSON.parse(
      JSON.stringify(task.buildingConnections),
    ) as ConnectionFromBuilding[];

    buildingConnections = buildingConnections.filter(
      item => item.connectionId !== connectionId,
    );

    await deleteConnection({ connectionId });

    dispatch(
      updateTaskBuildingsConnections(
        !buildingConnections.length
          ? null
          : (buildingConnections as ConnectionFromBuilding[]),
      ),
    );
  };

  useEffect(() => {
    if (control.selectedBuildingUid) {
      getConnections();
    }
    if (!control.selectedBuildingUid && isOpen) {
      setOpen(false);
      setTableHeader(null);
    }
  }, [control.selectedBuildingUid]);

  useEffect(() => {
    if (task.buildingConnections && task.buildingConnections?.length) {
      prepareBuildingConnections();
    }
  }, [task.buildingConnections]);

  return (
    <div className="map_buildings-connect">
      <div className="map_buildings-connect__row">
        <button
          type="button"
          onClick={handleOpen}
          className="map_buildings-connect__row-button"
        >
          <ChevronIcon />
        </button>
        <div style={{ flex: 1, marginLeft: '10px' }}>
          <p>Коммуникационные связи</p>
        </div>
        {task.status === 'DRAFT' ? (
          <button
            type="button"
            onClick={() => addRow()}
            className="map_buildings-connect__row-button"
          >
            <AddButton />
          </button>
        ) : null}
      </div>
      {isOpen &&
      tableHeader &&
      tableHeader.length &&
      control.selectedBuildingUid ? (
        <Table
          confirmChangeConnection={confirmChangeConnection}
          cancelChangeConnection={cancelChangeConnection}
          changeConnections={changeConnections}
          newConnectionsIds={newConnectionsIds}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          header={tableHeader}
          updateRow={updateRow}
          deleteRow={deleteRow}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  task: state.task,
  control: state.control,
  dictionaries: state.dictionaries,
  selectedBuildingUid: state.control.selectedBuildingUid,
});

export default connect(mapStateToProps)(BuildingsConnect);
