import React, { useContext, useEffect, useState } from 'react';
import { MapContext } from 'react-mapbox-gl';
import { connect } from 'react-redux';

import { GeoJSONSource } from 'mapbox-gl';
import { RootState } from 'store/configureStore';

import { IMapSlice, ITaskSlice } from '../../../store/types';
import { borderCoords, composeFeature } from './utils';

/**
 * Затемнение поверх карты. Область проекта вырезается из этого полигона.
 */

interface Props {
  coordinates: number[][];
  task: ITaskSlice;
  map: IMapSlice;
  refCoords: any;
}

function OverlayPolygon(props: Props) {
  const { task, map } = props;
  const mapContext = useContext(MapContext);
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (!init && task.developmentArea) {
      updateOverlayCoords();
    }

    if (task.status === 'SOLVED' || task.status === 'DRAFT') {
      updateOverlayCoords();
    }

    if (!init && !task.developmentArea && map.geometry) {
      updateOverlayCoords();
    }
  }, [task.status, map.geometry]);

  const updateOverlayCoords = () => {
    const mapCoords = map?.geometry?.coordinates[0];

    const developmentArea = task.developmentArea
      ? JSON.parse(task.developmentArea)
      : null;
    const coords =
      mapCoords ||
      (developmentArea ? developmentArea.coordinates[0] : [76.929, 65.975]);

    if (coords && !coords.length) return;
    const overlayData = composeFeature('Polygon')([
      borderCoords,
      coords,
    ]) as any;

    const overlaySource = mapContext?.getSource(
      'overlay-polygon',
    ) as GeoJSONSource;

    if (overlaySource) {
      overlaySource.setData({
        type: 'FeatureCollection',
        features: [overlayData],
      });
    }
    setInit(true);
  };

  return null;
}

const mapStateToProps = (state: RootState) => ({
  task: state.task,
  map: state.map,
});

export default connect(mapStateToProps)(OverlayPolygon);
