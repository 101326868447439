import { client } from 'apollo';
import {
  UpdateCoordinates,
  UpdateCoordinatesMutation,
} from 'generated/graphql';

export const updateCoordinates = input =>
  client.mutate<UpdateCoordinatesMutation>({
    mutation: UpdateCoordinates,
    variables: {
      input,
    },
  });
