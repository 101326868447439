import React, { useContext, useEffect, useRef, useState } from 'react';
import { MapContext } from 'react-mapbox-gl';
import { connect, useDispatch } from 'react-redux';

import { GeoJSONSource, MapLayerMouseEvent } from 'mapbox-gl';
import { RootState } from 'store/configureStore';
import { updateExternalPoints } from 'store/taskSlice';

import { setEditExternalPoints } from '../../../store/mapSlice';
import {
  IDictionariesSlice,
  IMapSlice,
  ITaskSlice,
} from '../../../store/types';
import { composeFeature } from '../DrawArea/utils';
import { updateCoordinates } from './services';
import { handleTypes, imagesArr } from './utils';

interface IExternalPointsProps {
  map: IMapSlice;
  task: ITaskSlice;
  dictionaries: IDictionariesSlice;
}

export interface IExternalPointsState {
  isMouseDown: boolean;
  pointsData: any;
  activePointId?: string | number | null;
  imagesInit: boolean;
}

function ExternalPoints(props: IExternalPointsProps) {
  const { map, task, dictionaries } = props;

  const dispatch = useDispatch();
  const mapContext = useContext(MapContext);

  const activePointIdRef = useRef<any>(null);
  const currentExternalPointsCoordsRef = useRef<any>(null);

  const [state, setState] = useState<IExternalPointsState>({
    isMouseDown: false,
    pointsData: null,
    imagesInit: false,
  });

  useEffect(() => {
    if (task.externalPoints) {
      updateOnMap();
      if (!state.imagesInit) {
        prepareImages();
        setState({ ...state, imagesInit: true });
      }
    }
  }, [task.externalPoints]);

  useEffect(() => {
    if (map.editExternalPoints) {
      currentExternalPointsCoordsRef.current = task.externalPoints;
      bindHandlers();
    } else {
      currentExternalPointsCoordsRef.current = null;
      setLayoutProperty(['get', 'type']);
      unbindHandlers();
    }
    () => {
      unbindHandlers();
    };
  }, [map.editExternalPoints]);

  const prepareImages = () => {
    imagesArr.forEach(obj => {
      Object.keys(obj).forEach(key => {
        mapContext?.addImage(key, obj[key]);
      });
    });
  };

  const updateOnMap = () => {
    const pointsData = handleData();

    const source = mapContext?.getSource('geojson') as GeoJSONSource;

    if (source) {
      const data = {
        type: 'FeatureCollection',
        features: pointsData,
      } as GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.Geometry>;

      source.setData(data);
    }
  };

  const handleData = () => {
    const pointsData = task?.externalPoints?.map((key, index: number) => {
      const { coordinates } = JSON.parse(key?.point);
      const name = dictionaries?.allConnectionPointTypes?.find(
        item => item.id === key?.typeId,
      )?.name;
      const type = handleTypes(name as string);
      const featurePoint = composeFeature('Point')(coordinates);
      const properties = {
        id: key?.id,
        name,
        type,
        index,
        drawType: 'external-point',
      };
      featurePoint.properties = properties;
      return featurePoint;
    });

    return pointsData;
  };

  const keysHandler = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      dispatch(setEditExternalPoints(false));
      document.removeEventListener('keyup', keysHandler);
    }
  };

  const setLayoutProperty = (value: any) => {
    mapContext?.setLayoutProperty('external-points', 'icon-image', value);
  };

  const bindHandlers = () => {
    const layer = mapContext?.getLayer('external-points');
    mapContext?.on('mousedown', 'external-points', mouseDown);
    mapContext?.on('mousedown', 'external-points-inner', mouseDown);
    mapContext?.on('mousemove', onMove);
    if (layer) {
      setLayoutProperty([
        'match',
        ['get', 'type'],
        'vl',
        'vlActive',
        'road',
        'roadActive',
        'gas',
        'gasActive',
        'cond',
        'condActive',
        'gs',
        'gsActive',
        'wwActive',
      ]);
    }
    document.addEventListener('keyup', keysHandler);
  };

  const unbindHandlers = () => {
    mapContext?.off('mousedown', 'external-points', mouseDown);
    mapContext?.off('mousedown', 'external-points-inner', mouseDown);
    mapContext?.off('mousemove', onMove);
    document.removeEventListener('keyup', keysHandler);
  };

  const updateExternalPointsCoords = (coords: any) => {
    if (currentExternalPointsCoordsRef.current) {
      let currentTaskExternalPoint = JSON.parse(
        JSON.stringify(currentExternalPointsCoordsRef.current),
      );

      currentTaskExternalPoint = currentTaskExternalPoint?.map((item: any) => {
        if (item && item.id === activePointIdRef.current) {
          const point = JSON.parse(item?.point);
          point.coordinates = coords;
          const newPoint = JSON.stringify(point);
          item.point = newPoint;
        }
        return item;
      });

      currentExternalPointsCoordsRef.current = currentTaskExternalPoint;

      dispatch(updateExternalPoints(currentTaskExternalPoint));
    }
  };

  const onMove = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const { lng, lat } = e.lngLat;
    if (typeof activePointIdRef.current === 'string') {
      const newCoordinates = [lng, lat];
      updateExternalPointsCoords(newCoordinates);
    }
  };

  const mouseDown = (e: MapLayerMouseEvent) => {
    const feature = e.features?.[0];

    e.preventDefault();
    activePointIdRef.current = feature?.properties?.id;
    mapContext?.once('mouseup', onUp);
  };

  const onUp = async (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const { lng, lat } = e.lngLat;
    if (typeof activePointIdRef.current === 'string') {
      const newCoordinates = [lng, lat];
      updateExternalPointsCoords(newCoordinates);
      await updateExternalPointCoords({
        lng,
        lat,
        externalPointId: activePointIdRef.current,
      });

      activePointIdRef.current = null;
    }
  };

  const updateExternalPointCoords = async ({
    lng,
    lat,
    externalPointId,
  }: {
    lng: number;
    lat: number;
    externalPointId: string;
  }) => {
    await updateCoordinates({
      externalPointId,
      newCoordinates: JSON.stringify({
        type: 'Point',
        coordinates: [lng, lat],
      }),
    });
  };

  return null;
}

const mapStateToProps = (state: RootState) => ({
  map: state.map,
  task: state.task,
  dictionaries: state.dictionaries,
});

export default connect(mapStateToProps)(ExternalPoints);
