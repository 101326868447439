import * as React from 'react';

import classnames from 'classnames';

import { ITaskListButton } from './types';

const TaskListButton: React.FC<ITaskListButton> = ({
  onClick,
  favorite,
  children,
  active,
}) => {
  const onCLickHandler = () => onClick(favorite);
  return (
    <button
      type="button"
      onClick={onCLickHandler}
      className={classnames('task-list__button', { active })}
    >
      {children}
    </button>
  );
};

export default TaskListButton;
