import gql from 'graphql-tag';

export const CONTENT_TYPE_FRAGMENT = gql`
  fragment ContentTypeFragment on ContentType {
    id
    name
  }
`;

export const EXTERNAL_POINTS = gql`
  fragment ExtrenalPointsFragment on ExternalPoint {
    id
    point
    typeId
  }
`;
