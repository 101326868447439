import { GetTaskQuery } from 'generated/graphql';

import { findDuplicates } from 'utils';
import {
  HandleErrorType,
  ResetState,
  SetActiveTab,
  SetBuildingsCountType,
  SetBuildingsType,
  SetDataType,
  SetErrorType,
  SetHiddenSidebar,
  SetInitialBuildingsCountType,
  SetResultType,
  // SetTaskTraceType,
  UpdateConnectionPointPositionType,
  UpdateStatus,
  UpdateTaskInputReducerType,
} from './types';

const setData: SetDataType = (state, action) => {
  Object.keys(action.payload).forEach(key => {
    state[key] = action.payload[key];
  });
};

const resetState: ResetState = state => {
  Object.assign(state, {
    buildings: [],
    buildingsCount: {},
    status: '',
    activeButton: 'PARAMETERS',
  });
  delete state.taskInput.taskId;
  delete state.result;
  delete state.taskTrace;
};

const setBuildingsCount: SetBuildingsCountType = (state, action) => {
  const { payload } = action;
  if (!state.buildingsCount[payload]) {
    state.buildingsCount[payload] = 1;
  } else {
    state.buildingsCount[payload] = (state.buildingsCount[payload] ?? 0) + 1;
  }
};

const setResult: SetResultType = (state, action) => {
  const data = action.payload as GetTaskQuery['task'];
  if (data) {
    const { buildings, externalPoints } = data;
    state.result = data;
    if (buildings) {
      state.buildings = buildings as any;
      const buildingTypes = buildings.map(el => el!.buildingTypeId!);
      buildingTypes.forEach(type =>
        setBuildingsCount(state, { payload: type, type: '' }),
      );
    }
    if (externalPoints) {
      state.allExternalPoints = externalPoints.map(el => ({
        ...el,
        point: JSON.parse(el?.point),
      }));
    }
  } else {
    state.result = action.payload;
  }
};

const setInitialBuildingsCount: SetInitialBuildingsCountType = (
  state,
  action,
) => {
  state.buildingsCount = action.payload;
};

const setError: SetErrorType = (state, action) => {
  state.error = action?.payload;
};

const handleError: HandleErrorType = (state, action) => {
  const error = action?.payload;
  let errorText = error?.message;
  const errors = error?.networkError?.result?.errors;

  if (errors && errors[0]) {
    errorText = errors[0].message;
  }

  state.error = {
    errorText,
    show: true,
    type: 'response',
  };
};

const setBuildings: SetBuildingsType = (state, action) => {
  if (action?.payload) state.buildings = action?.payload;
  const buildingsNames = state.buildings.map(({ name }) => name);
  const duplicatesName = findDuplicates(buildingsNames as string[])[0];
  if (duplicatesName) {
    state.error = {
      show: true,
      errorText: duplicatesName,
      type: 'title',
    };
  }
};

// const setTaskTrace: SetTaskTraceType = (state, action) => {
//   state.taskTrace = action.payload;
// };

const updateConnectionPointPosition: UpdateConnectionPointPositionType = (
  state,
  action,
) => {
  state.allExternalPoints[action.payload.index].point.coordinates =
    action.payload.coordinates;
};

const updateTaskInput: UpdateTaskInputReducerType = (state, action) => {
  Object.assign(state.taskInput, action.payload);
};

const updateStatus: UpdateStatus = (state, action) => {
  state.status = action.payload;
};

const setActiveTab: SetActiveTab = (state, action) => {
  state.activeButton = action.payload;
};

const setHiddenSidebar: SetHiddenSidebar = (state, action) => {
  state.hiddenSidebar = action.payload;
};

export default {
  setResult,
  setBuildingsCount,
  resetState,
  // setTaskTrace,
  setError,
  setBuildings,
  setData,
  setActiveTab,
  setInitialBuildingsCount,
  updateConnectionPointPosition,
  updateTaskInput,
  updateStatus,
  handleError,
  setHiddenSidebar,
};
