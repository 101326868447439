import gql from 'graphql-tag';

export default gql`
  mutation updateConnection($input: UpdateConnectionInput!) {
    connection {
      update(input: $input) {
        ok
        connectionPointId
      }
    }
  }
`;
