import { combineReducers, configureStore } from '@reduxjs/toolkit';

import socketMiddleware from '../socket/socketMiddleware';
import controlSlice from './controlSlice';
import dictionariesSlice from './dictionariesSlice';
import mapSlice from './mapSlice';
import sidebarSlice from './sidebarSlice';
import taskSlice from './taskSlice';
import tasksSlice from './tasksSlice';
import webSocketSlice from './webSocketSlice';

const rootReducer = combineReducers({
  map: mapSlice,
  task: taskSlice,
  dictionaries: dictionariesSlice,
  sidebar: sidebarSlice,
  control: controlSlice,
  websocket: webSocketSlice,
  tasks: tasksSlice,
});

export default configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENV !== 'production',
  middleware: [socketMiddleware],
});

export type RootState = ReturnType<typeof rootReducer>;

export type SidebarState = RootState['sidebar'];

export type MapState = RootState['map'];
