import * as React from 'react';
import { MapContext, Popup } from 'react-mapbox-gl';
import { connect, useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CancelIcon } from 'assets/images/cancel.svg';
import { SidebarTypes } from 'containers/Sidebar/types';
import { Position } from 'geojson';
import { EventData, MapMouseEvent } from 'mapbox-gl';
import { clearControl, updateControl } from 'store/controlSlice';

import { getBuildingCenterByTaskId } from '../../apollo/requests';
import { RootState } from '../../store/configureStore';
import { setActiveBuilding } from '../../store/mapSlice';
import { selectActiveButton, setActiveTab } from '../../store/sidebarSlice';
import { IControlSlice, ITaskSlice } from '../../store/types';
import { composePopupTitle, popupTitles } from './utils';

/**
 * Popup для всех слоёв.
 */

interface Props {
  task: ITaskSlice;
  control: IControlSlice;
}

function PopupComponent(props: Props) {
  const { control, task } = props;
  const [popupTitle, setPopupTitle] = React.useState<
    string | JSX.Element | null
  >(null);
  const [popupCoords, setPopupCoords] = React.useState<Position | null>(null);
  const lastBuildingId = React.useRef<string | null>();
  const activeTabRef = React.useRef<SidebarTypes>('PARAMETERS');

  const activeTab = useSelector(selectActiveButton);
  const dispatch = useDispatch();

  const updateActiveBuilding = (buildingUid: string) => {
    dispatch(setActiveBuilding(buildingUid));
    if (buildingUid) {
      dispatch(updateControl({ selectedBuildingUid: buildingUid }));
    } else {
      dispatch(clearControl());
    }
  };

  const map = React.useContext(MapContext);

  const setToFeatureState = (state: { [key: string]: any }) => {
    map?.setFeatureState(
      {
        id: control.selectedBuildingUid as string,
        source: 'vector-tiles',
        sourceLayer: 'buildings',
      },
      state,
    );
  };

  const handleBuilding = async () => {
    const taskObj = task?.buildings?.find(
      item => item?.id === control.selectedBuildingUid,
    );
    const name = taskObj?.name;
    const buildingCenter = await getBuildingCenterByTaskId(
      control?.selectedBuildingUid as string,
      task.id,
    );

    if (name !== popupTitle && buildingCenter) {
      const coords = JSON.parse(buildingCenter)?.coordinates;
      if (coords) {
        setPopupTitle(name as string);
        setPopupCoords(coords);

        map?.flyTo({ center: coords });
        setToFeatureState({ active: true });
      }
    }
  };

  React.useEffect(() => {
    activeTabRef.current = activeTab;
  }, [activeTab]);

  /**
   * Клик на здание в списке зданий, делает его активынм на карте и открывает для него Popup.
   * Убирает остальные Popup.
   */
  React.useEffect(() => {
    lastBuildingId.current = control.selectedBuildingUid;

    if (control.selectedBuildingUid) {
      handleBuilding();
    } else if (lastBuildingId.current) {
      setToFeatureState({ active: false });
      setPopupTitle(null);
      setPopupCoords(null);
    }
  }, [control.selectedBuildingUid]);

  /**
   * @param {MapMouseEvent & EventData} e
   * Т.к. байндится к карте мы можем использовать только мутированные значения.
   * Клик на здание или его центр, делает его активным в списке зданий.
   */
  const onClick = (e: MapMouseEvent & EventData) => {
    const features = map?.queryRenderedFeatures(e.point);
    if (!features) return;
    const sourceLayer = features[0]?.sourceLayer;
    const layerId = features[0]?.layer?.id;

    if (layerId === 'external-points') {
      const title = features[0]?.properties?.name;
      const { lat, lng } = e.lngLat;
      setPopupTitle(title);
      setPopupCoords([lng, lat]);
    } else if (sourceLayer) {
      const { lat, lng } = e.lngLat;
      let title = popupTitles[sourceLayer];
      if (['building_centers', 'buildings'].includes(sourceLayer)) {
        if (activeTabRef.current !== 'BUILDINGS') {
          dispatch(setActiveTab('BUILDINGS'));
        }
        title = task?.buildings?.find(
          item => item?.id === features[0]?.properties?.id,
        )?.name;
        updateActiveBuilding(features[0]?.properties?.id);
      }
      if (sourceLayer === 'affected_areas') {
        title = `ΔP [кПа] = ${features[0]?.properties?.pressure_kpa} `;
      }
      if (sourceLayer === 'thermal_radiation_areas') {
        const level = features[0]?.properties?.thermal_radiation_level;
        title = composePopupTitle(level);
      }
      if (title) {
        setPopupTitle(title);
        setPopupCoords([lng, lat]);
      } else {
        setPopupTitle(null);
        setPopupCoords(null);
      }
    } else if (lastBuildingId.current) {
      updateActiveBuilding('');
    }
    const currentZoom = map?.getZoom();
    map?.setZoom(currentZoom as number);
  };

  React.useEffect(() => {
    map?.on('click', onClick);

    return () => {
      map?.off('click', onClick);
    };
  }, [map]);

  const closePopup = () => setPopupCoords(null);

  if (!popupCoords) return null;

  return (
    <Popup className="main-popup" coordinates={popupCoords}>
      <div>
        <span className="text facility-popup__text facility-popup__value">
          {popupTitle}
        </span>
      </div>
      <button type="button" onClick={closePopup} className="main-popup__close">
        <CancelIcon />
      </button>
    </Popup>
  );
}

const mapStateToProps = (state: RootState) => ({
  task: state.task,
  control: state.control,
});

export default connect(mapStateToProps)(PopupComponent);
