import * as React from 'react';
import { MapContext } from 'react-mapbox-gl';

import { FeatureIdentifier } from 'mapbox-gl';

const BuildingsHandlers: React.FC = React.memo(() => {
  const activeBuilding = React.useRef<FeatureIdentifier | null>(null);

  const map = React.useContext(MapContext);

  const updateFeatureState = (state: { [key: string]: any }) => {
    if (activeBuilding.current) {
      map?.setFeatureState(activeBuilding.current, state);
    }
  };

  React.useEffect(() => {
    const onClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      const feature = map?.queryRenderedFeatures(e.point)?.[0];
      const source = feature?.source;
      const sourceLayer = feature?.sourceLayer;

      if (activeBuilding.current) {
        updateFeatureState({ active: false });
      }

      if (!sourceLayer || !source || !feature) return;

      if (['buildings', 'building_centers'].includes(sourceLayer)) {
        activeBuilding.current = {
          source,
          id: feature.id,
          sourceLayer: 'buildings',
        };
        updateFeatureState({ active: true });
      }
    };

    map?.on('click', onClick);

    return () => {
      map?.off('click', onClick);
    };
  }, []);

  return null;
});

export default BuildingsHandlers;
