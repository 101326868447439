import { client } from 'apollo';
import {
  CalculateTask,
  CalculateTaskMutation,
  CloneTask,
  CloneTaskMutation,
  CreateBuilding,
  CreateBuildingMutation,
  CreateTask,
  CreateTaskMutationResult,
  DeleteTask,
  DeleteTaskMutation,
  GetAllBuildingTypes,
  GetAllBuildingTypesQuery,
  GetAllConnectionsPointTypes,
  GetAllConnectionsPointTypesQuery,
  GetAllConnectionTypes,
  GetAllConnectionTypesQuery,
  GetConnectionsFromBuilding,
  GetConnectionsFromBuildingQuery,
  GetData,
  GetDataQuery,
  GetTask,
  GetTaskLogs,
  GetTaskLogsSubscription,
  GetTaskQuery,
  RemoveBuilding,
  RemoveBuildingMutation,
  RenameTask,
  RenameTaskMutation,
  UpdateBuilding,
  UpdateBuildingMutation,
  UpdateTask,
  UpdateTaskMutation,
} from 'generated/graphql';

export const getAllBuildingTypes = () =>
  client
    .query<GetAllBuildingTypesQuery>({
      query: GetAllBuildingTypes,
    })
    .then(({ data }) => data.allBuildingTypes);

export const getAllConnectionTypes = () =>
  client
    .query<GetAllConnectionTypesQuery>({
      query: GetAllConnectionTypes,
      fetchPolicy: 'no-cache',
    })
    .then(({ data }) => data.allConnectionTypes);

export const getAllConnectionPointTypes = () =>
  client
    .query<GetAllConnectionsPointTypesQuery>({
      query: GetAllConnectionsPointTypes,
      fetchPolicy: 'no-cache',
    })
    .then(({ data }) => data.allConnectionPointTypes);

export const getConnectionsFromBuildings = (uid: string) =>
  client
    .query<GetConnectionsFromBuildingQuery>({
      query: GetConnectionsFromBuilding,
      variables: { buildingId: uid },
      fetchPolicy: 'no-cache',
    })
    .then(({ data }) => data.connectionsFromBuilding);

export const getTask = (id: string) =>
  client
    .query<GetTaskQuery>({
      query: GetTask,
      variables: { id },
      fetchPolicy: 'no-cache',
    })
    .then(({ data }) => data?.task);

export const createTask = ({ taskInput }) =>
  client
    .mutate<CreateTaskMutationResult['data']>({
      mutation: CreateTask,
      variables: {
        taskInput,
      },
    })
    .then(({ data }) => data?.taskMutation?.createTask?.recordId);

export const renameTask = ({ input }) => {
  client
    .mutate<RenameTaskMutation>({
      mutation: RenameTask,
      variables: { input },
    })
    .then(({ data }) => data);
};

export const getData = () =>
  client
    .query<GetDataQuery>({ query: GetData })
    .then(({ data }) => data);

export const getLogs = (taskId: string) =>
  client.subscribe<GetTaskLogsSubscription>({
    query: GetTaskLogs,
    variables: {
      taskId,
    },
    fetchPolicy: 'no-cache',
  });

export const createBuilding = ({ buildingInput }) =>
  client
    .mutate<CreateBuildingMutation>({
      mutation: CreateBuilding,
      variables: {
        buildingInput,
      },
    })
    .then(({ data }) => data?.buildingMutation?.createBuilding?.recordId);

export const removeBuilding = ({ id }) =>
  client.mutate<RemoveBuildingMutation>({
    mutation: RemoveBuilding,
    variables: {
      id,
    },
  });

export const updateBuilding = ({ buildingInput }) =>
  client.mutate<UpdateBuildingMutation>({
    mutation: UpdateBuilding,
    variables: {
      buildingInput,
    },
  });

export const calculateTask = ({ taskId }) =>
  client.mutate<CalculateTaskMutation>({
    mutation: CalculateTask,
    variables: {
      taskId,
    },
  });

export const updateTask = ({ taskInput }) =>
  client.mutate<UpdateTaskMutation>({
    mutation: UpdateTask,
    variables: {
      taskInput,
    },
  });

export const cloneTask = ({ taskId }) =>
  client.mutate<CloneTaskMutation>({
    mutation: CloneTask,
    variables: {
      taskId,
    },
  });

export const deleteTask = ({ taskId }) =>
  client.mutate<DeleteTaskMutation>({
    mutation: DeleteTask,
    variables: {
      taskId,
    },
  });
