import { TaskStatus } from 'generated/graphql';

export const handleStatus = (status: TaskStatus) => {
  if (status === 'DRAFT') {
    return 'черновик';
  }
  if (status === 'FAILED') {
    return 'ошибка';
  }
  if (status === 'SOLVED') {
    return 'расчет завершен';
  }
  return 'идет расчет';
};

// export const calculationTypeOptions: Record<CalculationType, string> = {
//   STANDART: 'обычный расчет',
//   MULTIAGENT: 'мультиагентный расчет',
//   DERIVABLE: 'энергетический метод',
//   POSTOPTIMIZATION: 'дооптимизация',
//   EMERGENCY: 'аварийные сценарии',
//   FORCE: 'силовой метод',
// };
