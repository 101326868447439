import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { ReactComponent as ArrowDown } from 'assets/images/arrowDown.svg';
import { ReactComponent as EditIcon } from 'assets/images/edit-menu.svg';
import { ReactComponent as PlusIcon } from 'assets/images/plus.svg';
import { ReactComponent as ToggleArrowIcon } from 'assets/images/toggle-arrow.svg';
import SimpleCheckbox from 'elements/CheckBox/SimpleCheckbox';
import Divider from 'elements/Divider';
import EditDropdown from 'elements/Dropdowns/EditDropdown';
import SidebarDropdown from 'elements/Dropdowns/SidebarDropdown';
import BuildingsSearchCancelIcon from 'elements/Icons/BuildingsSearchCancelIcon';
import BuildingsSearchSearchIcon from 'elements/Icons/BuildingsSearchSearchIcon';
import SimpleInput from 'elements/Inputs/SimpleInput';
import SimpleLabel from 'elements/Labels/SimpleLabel';
import {
  updateTaskBuildings,
  updateTaskBuildingsConnections,
} from 'store/taskSlice';

import {
  createBuilding,
  removeBuilding,
  updateBuilding,
} from '../../../apollo/requests';
import {
  Building,
  ContentType,
  UpdateBuildingInput,
} from '../../../generated/graphql';
import { RootState } from '../../../store/configureStore';
import { clearControl, updateControl } from '../../../store/controlSlice';
import {
  IControlSlice,
  IDictionariesSlice,
  ITaskSlice,
} from '../../../store/types';
import {
  composeContentTypeOptions,
  constructionTypeOptions,
  fireDangerRatingOptions,
  fireResistanceRatingOptions,
  functionalAreaOptions,
  structuralFireHazardClassOptions,
  tankLocationTypes,
} from '../fieldsOptions';
import { defaultBuildingProps } from './utils';

interface Props {
  task: ITaskSlice;
  dictionaries: IDictionariesSlice;
  control: IControlSlice;
}

function prepareBuildingInput(
  building: Building,
  fields: any,
  buildingId: string,
) {
  let newObj = {};
  Object.keys(building).forEach(key => {
    if (
      ![
        'id',
        'contentType',
        'connectionPoints',
        'center',
        'affectedAreas',
        'connectedBuildings',
        'isCircle',
        'polygon',
        'thermalRadiationAreas',
      ].includes(key)
    ) {
      newObj[key] = building[key];
    }
  });

  newObj = { ...newObj, ...fields, buildingId };

  return newObj as UpdateBuildingInput;
}

function BuildingsTab(props: Props) {
  const { task, dictionaries, control } = props;

  const [openedBuildingId, setOpenedBuildingId] = useState<string | null>(null);
  const [showBuildingsList, setShowBuildingsList] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  const handleBuildingsListOpen = () => {
    setShowBuildingsList(!showBuildingsList);
  };

  const addBuilding = () => {
    return (
      <div className="sidebar__tabs_content__block_button">
        <button
          type="button"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            alignSelf: 'stretch',
            padding: '16px 0 8px 16px',
          }}
          onClick={handleBuildingsListOpen}
        >
          <PlusIcon />
          <Divider width="4px" />
          <p style={{ color: '#999999', fontSize: '14px' }}>Добавить...</p>
        </button>
        {showBuildingsList && (
          <BuildingsList
            task={task}
            buildingsTypes={
              dictionaries.allBuildingTypes as IDictionariesSlice['allBuildingTypes'][]
            }
            handleOpen={handleBuildingsListOpen}
          />
        )}
      </div>
    );
  };

  const handleSearchCancelClick = () => {
    setSearchText('');
  };

  const searchBuilding = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
          margin: '12px 16px 12px 16px',
          paddingRight: '12px',
          border: '1px solid #4A4A4A',
          borderRadius: '3px',
          background: '#242425',
        }}
      >
        <input
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          style={{
            width: '100%',
            background: 'transparent',
            border: 'none',
            fontSize: '14px',
            padding: '8px',
            color: '#F0F0F0',
          }}
          placeholder="Сооружение"
        />
        {searchText ? (
          <button type="button" onClick={handleSearchCancelClick}>
            <BuildingsSearchCancelIcon />
          </button>
        ) : (
          <BuildingsSearchSearchIcon />
        )}
      </div>
    );
  };

  let buildingParametersArr = JSON.parse(JSON.stringify(task.buildings));

  if (searchText) {
    buildingParametersArr = buildingParametersArr.filter(
      (item: any) =>
        item.label.toLowerCase().includes(searchText.toLowerCase()) ||
        item.name.toLowerCase().includes(searchText.toLowerCase()),
    );
  }

  const sortArr = buildingParametersArr.sort((a: any, b: any) => {
    if (a.label) {
      return a.label.localeCompare(b.label, undefined, { numeric: true });
    }

    if (a.name.split() < b.name.split()) return -1;
    if (a.name.split() > b.name.split()) return 1;

    return 0;
  }) as Building[];

  return (
    <>
      {task.status !== 'DRAFT' ? searchBuilding() : null}
      <Divider isLine />
      {task.status === 'DRAFT' && addBuilding()}
      <div className="sidebar__tabs_content__block_content">
        {sortArr.map((item, i) => (
          <div
            key={i}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              alignSelf: 'stretch',
              width: '100%',
            }}
          >
            <BuildingListHeader
              searchText={searchText}
              task={task}
              control={control}
              building={item as Building}
              openedBuildingId={openedBuildingId}
              setOpenedBuildingId={setOpenedBuildingId}
            />
            {openedBuildingId === item.id && (
              <TabsList
                task={task}
                building={item as Building}
                dictionaries={dictionaries}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
}

function BuildingListHeader(props: {
  searchText: string;
  task: ITaskSlice;
  control: IControlSlice;
  building: Building;
  openedBuildingId: string | null;
  setOpenedBuildingId: (e: any) => void;
}) {
  const {
    task,
    searchText,
    control,
    building,
    openedBuildingId,
    setOpenedBuildingId,
  } = props;

  const wrapperRef = useRef<any>(null);
  const executeRef = useRef<any>(null);

  const [isRenameBuilding, setRenameBuilding] = useState<boolean>(false);
  const [buildingName, setBuildingName] = useState<string>('');

  const dispatch = useDispatch();

  const executeScroll = () => {
    if (executeRef.current) {
      executeRef?.current.scrollIntoView({ behavior: 'smooth' });
      executeRef?.current.focus({ preventScroll: true });
    }
  };

  const handleListOpen = (buildingUid: string) => {
    if (openedBuildingId === buildingUid) {
      dispatch(clearControl());
      setOpenedBuildingId(null);
      dispatch(updateTaskBuildingsConnections(null));
    } else {
      executeScroll();
      dispatch(updateControl({ selectedBuildingUid: buildingUid }));
      setOpenedBuildingId(buildingUid);
    }
  };

  const renameBuilding = async (value: string) => {
    setBuildingName(value);
    const buildingInput = prepareBuildingInput(
      JSON.parse(JSON.stringify(building)),
      { name: value },
      building.id,
    );

    await updateBuilding(buildingInput);
  };

  const deleteBuilding = async (buildingId: any) => {
    await removeBuilding({ id: buildingId });
    const taskBuildingsParameters = JSON.parse(JSON.stringify(task.buildings));
    const newTaskBuildingsParameters = taskBuildingsParameters.filter(
      (item: any) => item.id !== buildingId,
    );
    dispatch(updateTaskBuildings(newTaskBuildingsParameters));
  };

  const handleKeyPress = (e: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setRenameBuilding(false);
    }
  };

  const getHighlightedText = (text: string) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${searchText})`, 'gi'));
    return (
      <span>
        {' '}
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === searchText.toLowerCase()
                ? { color: '#5F9BFA' }
                : {}
            }
          >
            {part}
          </span>
        ))}{' '}
      </span>
    );
  };

  useEffect(() => {
    setBuildingName(building.name as string);
  }, [building?.name]);

  useEffect(() => {
    document.addEventListener('mousedown', handleKeyPress);
    return () => {
      document.removeEventListener('mousedown', handleKeyPress);
    };
  }, [isRenameBuilding]);

  useEffect(() => {
    if (
      control.selectedBuildingUid === building.id &&
      openedBuildingId !== building.id
    ) {
      handleListOpen(building.id);
    }

    if (!control.selectedBuildingUid) {
      setOpenedBuildingId(null);
    }
  }, [control.selectedBuildingUid]);

  return (
    <div
      ref={executeRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        alignSelf: 'stretch',
        padding: '10px 20px',
        width: '100%',
        borderBottom: '1px solid #1d1d1f',
      }}
    >
      {!isRenameBuilding ? (
        <button
          type="button"
          onClick={() => handleListOpen(building.id)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            alignSelf: 'stretch',
            width: '100%',
            // padding: '8px 0',
          }}
        >
          {openedBuildingId === building.id ? (
            <ArrowDown />
          ) : (
            <ToggleArrowIcon />
          )}

          <Divider width="5px" />
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <p style={{ color: '#F0F0F0', fontSize: '14px' }}>
              {searchText
                ? getHighlightedText(building?.label as string)
                : building?.label}
            </p>
            <Divider width="7px" />
            <p
              style={{ color: '#F0F0F0', fontSize: '14px', textAlign: 'start' }}
            >
              {searchText
                ? getHighlightedText(buildingName as string)
                : buildingName}
            </p>
          </div>
        </button>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <button type="button" onClick={() => handleListOpen(building.id)}>
            <ToggleArrowIcon />
          </button>
          <div />
          <div
            ref={wrapperRef}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <p style={{ color: '#F0F0F0', fontSize: '14px' }}>
              {building?.label}
            </p>
            <Divider width="7px" />
            {isRenameBuilding ? (
              <input
                style={{
                  color: '#F0F0F0',
                  fontSize: '14px',
                  border: 'none',
                  backgroundColor: '#222',
                  padding: '5px',
                  width: '100%',
                  borderRadius: '4px',
                }}
                value={buildingName}
                onChange={e => renameBuilding(e.target.value)}
              />
            ) : (
              <p style={{ color: '#F0F0F0', fontSize: '14px' }}>
                {buildingName}
              </p>
            )}
          </div>
        </div>
      )}
      {task.status === 'DRAFT' && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            alignSelf: 'stretch',
          }}
        >
          <EditButton
            setRename={() => setRenameBuilding(!isRenameBuilding)}
            deleteBuilding={() => deleteBuilding(building.id)}
          />
        </div>
      )}
    </div>
  );
}

function EditButton(props: { setRename: any; deleteBuilding: any }) {
  const { setRename, deleteBuilding } = props;

  const [showEditDropdown, setShowEditDropdown] = useState<boolean>(false);

  const handleOpenEditDropdown = () => {
    setShowEditDropdown(!showEditDropdown);
  };

  return (
    <button
      type="button"
      style={{ padding: '10px 5px', position: 'relative' }}
      onClick={handleOpenEditDropdown}
    >
      <EditIcon />
      {showEditDropdown && (
        <EditDropdown
          handleOpen={handleOpenEditDropdown}
          actions={[
            {
              label: 'Переименовать',
              action: setRename,
            },
            {
              label: 'Удалить',
              action: deleteBuilding,
            },
          ]}
        />
      )}
    </button>
  );
}

function TabsList(props: {
  task: ITaskSlice;
  building: Building;
  dictionaries: IDictionariesSlice;
}) {
  const { building, dictionaries, task } = props;
  const dispatch = useDispatch();
  const contentTypeOptions = composeContentTypeOptions(
    dictionaries?.allContentTypes as ContentType[],
  );
  const [connectionsCount, setConnectionsCount] = useState(0);
  const [isSettingsOpen, setSettingsOpen] = useState(true);
  const [isConnectionsOpen, setConnectionsOpen] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [listState, setListState] = useState({
    label: '',
    isPermanentStaffPresence: false,
    contentTypeId: null,
    functionalArea: '',
    capacity: 0,
    workingPressureMpa: 0,
    buildingTypeId: '',
    length: 0,
    width: 0,
    radius: 0,
    fireDangerRating: '',
    structuralFireHazardClass: '',
    fireResistanceRating: '',
    constructionType: '',
    tankLocationType: null,
    isCircle: false,
    flareStackDiameter: 0,
    height: 0,
  });

  const handleFieldChange = async (
    type: string,
    value: any,
    isFloat?: boolean,
  ) => {
    setListState({ ...listState, [type]: value });
    const buildingInput = prepareBuildingInput(
      JSON.parse(JSON.stringify(building)),
      { [type]: isFloat ? parseFloat(value) : value },
      props.building.id,
    );

    await updateBuilding(buildingInput);

    let taskBuildingsParameters = JSON.parse(JSON.stringify(task.buildings));

    taskBuildingsParameters = taskBuildingsParameters.map((item: any) => {
      if (item.id === props.building.id) {
        item[type] = value;
      }
      return item;
    });

    dispatch(updateTaskBuildings(taskBuildingsParameters));
  };

  const handleShapeChange = async (type: string, label: string) => {
    if (
      (label === 'радиус' && listState.isCircle) ||
      (label === 'прямоугольник' && !listState.isCircle)
    )
      return;

    if (label === 'прямоугольник') {
      setListState({ ...listState, width: 10, length: 10, radius: 0 });
    } else {
      setListState({ ...listState, radius: 10, width: 0, length: 0 });
    }

    const buildingInput = prepareBuildingInput(
      JSON.parse(JSON.stringify(building)),
      {
        width: label === 'прямоугольник' ? 10 : 0,
        length: label === 'прямоугольник' ? 10 : 0,
        radius: label === 'радиус' ? 10 : 0,
      },
      props.building.id,
    );

    await updateBuilding(buildingInput);

    let taskBuildingsParameters = JSON.parse(JSON.stringify(task.buildings));

    taskBuildingsParameters = taskBuildingsParameters.map((item: any) => {
      if (item.id === props.building.id) {
        item.isCircle = !listState.isCircle;
        item.width = label === 'прямоугольник' ? 10 : 0;
        item.length = label === 'прямоугольник' ? 10 : 0;
        item.radius = label === 'радиус' ? 10 : 0;
      }
      return item;
    });
    setListState({
      ...listState,
      isCircle: !listState.isCircle,
      radius: label === 'радиус' ? 10 : 0,
      width: label === 'прямоугольник' ? 10 : 0,
      length: label === 'прямоугольник' ? 10 : 0,
    });
    dispatch(updateTaskBuildings(taskBuildingsParameters));
  };

  const renderBuildingConnections = () => {
    if (!task.buildingConnections) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'stretch',
            marginLeft: '50px',
            marginRight: '30px',
          }}
        >
          <Divider height="15px" />
          <p style={{ fontSize: '14px', color: '#808080', lineHeight: '16px' }}>
            Соединения отсутствуют
          </p>
        </div>
      );
    }

    const buildingConnectionsArr = task.buildingConnections;

    if (!connectionsCount && buildingConnectionsArr) {
      setConnectionsCount(buildingConnectionsArr?.length as number);
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'stretch',
        }}
      >
        {buildingConnectionsArr?.map((item, i) => (
          <div
            key={i}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'stretch',
              marginLeft: '50px',
              marginRight: '30px',
              paddingBottom: '20px',
              borderBottom: '.5px solid #505050',
            }}
          >
            <Divider height="15px" />
            <SimpleLabel text="Сооружение" />
            <Divider height="5px" />
            <p
              style={{ fontSize: '14px', color: '#f0f0f0', lineHeight: '16px' }}
            >
              {item.targetBuildingName}
            </p>
            <Divider height="15px" />
            <SimpleLabel text="Тип связи" />
            <Divider height="5px" />
            <p
              style={{ fontSize: '14px', color: '#f0f0f0', lineHeight: '16px' }}
            >
              {item.connectionTypeName}
            </p>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (building.id && !isLoaded) {
      const newObj: any = {};
      Object.keys(building).forEach(buildingKey => {
        Object.keys(listState).forEach(listStateKey => {
          if (buildingKey === listStateKey) {
            newObj[buildingKey] = building[buildingKey];
          }
        });
      });
      setListState(newObj);
      setIsLoaded(true);
    }
  }, [building.id]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch',
        width: '100%',
      }}
    >
      <button
        type="button"
        onClick={() => setSettingsOpen(!isSettingsOpen)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          alignSelf: 'stretch',
          width: '100%',
          padding: '18px 0 0 30px',
        }}
      >
        {isSettingsOpen ? <ArrowDown /> : <ToggleArrowIcon />}
        <Divider width="5px" />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          <p style={{ color: '#F0F0F0', fontSize: '14px' }}>Настройки</p>
        </div>
      </button>
      {isSettingsOpen && (
        <div
          style={{
            overflow: 'auto',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            alignSelf: 'stretch',
            padding: '0 40px 0 50px',
          }}
        >
          <Divider height="16px" />
          <SimpleLabel text="Обозначение на схеме" />
          <Divider height="6px" />
          <SimpleInput
            disabled={task.status !== 'DRAFT'}
            value={listState.label}
            handleChange={e => handleFieldChange('label', e.target.value)}
          />
          <Divider height="16px" />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <SimpleCheckbox
              disabled={task.status !== 'DRAFT'}
              active={listState.isPermanentStaffPresence}
              handleChange={() =>
                handleFieldChange(
                  'isPermanentStaffPresence',
                  !listState.isPermanentStaffPresence,
                )
              }
            />
            <Divider width="6px" />
            <SimpleLabel text="Постоянное присутствие персонала" />
          </div>
          <Divider height="16px" />
          <SimpleLabel text="Тип содержимого" />
          <Divider height="6px" />
          <SidebarDropdown
            disabled={task.status !== 'DRAFT'}
            selectedValue={listState.contentTypeId}
            list={contentTypeOptions}
            handleChange={handleFieldChange}
            type="contentTypeId"
          />
          <Divider height="16px" />
          <SimpleLabel text="Функциональная зона" />
          <Divider height="6px" />
          <SidebarDropdown
            disabled={task.status !== 'DRAFT'}
            selectedValue={listState.functionalArea}
            list={functionalAreaOptions}
            handleChange={handleFieldChange}
            type="functionalArea"
          />
          <Divider height="16px" />
          <SimpleLabel text="Объем" />
          <Divider height="6px" />
          <SimpleInput
            width="172px"
            mathValue="м"
            mathSymbol="3"
            disabled={task.status !== 'DRAFT'}
            value={listState.capacity ? listState.capacity.toString() : ''}
            handleChange={e =>
              handleFieldChange('capacity', e.target.value, true)
            }
          />
          <Divider height="16px" />
          <SimpleLabel text="Рабочее давление установки" />

          <Divider height="6px" />
          <SimpleInput
            width="172px"
            mathValue="МПа"
            disabled={task.status !== 'DRAFT'}
            value={
              listState.workingPressureMpa
                ? listState.workingPressureMpa.toString()
                : 0
            }
            handleChange={e =>
              handleFieldChange('workingPressureMpa', e.target.value, true)
            }
          />
          <Divider height="16px" />
          {building.buildingTypeId === '70fce44e-225f-11eb-9594-60f262df7a22' ||
          building.buildingTypeId === '743ae422-225f-11eb-9593-60f262df7a22' ? (
            <>
              <SimpleLabel text="Диаметр факельной трубы" />
              <Divider height="6px" />
              <SimpleInput
                width="172px"
                mathValue="м"
                disabled={task.status !== 'DRAFT'}
                value={
                  listState.flareStackDiameter
                    ? listState?.flareStackDiameter?.toString()
                    : 0
                }
                handleChange={e =>
                  handleFieldChange('flareStackDiameter', e.target.value, true)
                }
              />
              <Divider height="16px" />
            </>
          ) : null}
          {building.buildingTypeId === '70fce44e-225f-11eb-9594-60f262df7a22' ||
          building.buildingTypeId === '743ae422-225f-11eb-9593-60f262df7a22' ? (
            <>
              <SimpleLabel text="Высота факельного ствола" />
              <Divider height="6px" />
              <SimpleInput
                width="172px"
                mathValue="м"
                disabled={task.status !== 'DRAFT'}
                value={listState.height ? listState.height.toString() : 0}
                handleChange={e =>
                  handleFieldChange('height', e.target.value, true)
                }
              />
              <Divider height="16px" />
            </>
          ) : null}
          <SimpleLabel text="Тип фигуры" />
          <Divider height="6px" />
          <SidebarDropdown
            disabled={task.status !== 'DRAFT'}
            selectedValue={building.isCircle ? 'радиус' : 'прямоугольник'}
            list={[
              { value: 'радиус', label: 'Круг' },
              { value: 'прямоугольник', label: 'Прямоугольник' },
            ]}
            handleChange={handleShapeChange}
            type="isCircle"
          />
          <Divider height="16px" />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {listState.isCircle ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <SimpleLabel text="Радиус" />
                <Divider height="6px" />
                <SimpleInput
                  mathValue="м"
                  width="100px"
                  disabled={task.status !== 'DRAFT'}
                  value={listState.radius}
                  handleChange={e =>
                    handleFieldChange('radius', e.target.value, true)
                  }
                />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div>
                  <SimpleLabel text="Длина" />
                  <Divider height="6px" />
                  <SimpleInput
                    mathValue="м"
                    width="100px"
                    disabled={task.status !== 'DRAFT'}
                    value={listState.length}
                    handleChange={e =>
                      handleFieldChange('length', e.target.value, true)
                    }
                  />
                </div>
                <div>
                  <SimpleLabel text="Ширина" />
                  <Divider height="6px" />
                  <SimpleInput
                    mathValue="м"
                    width="100px"
                    disabled={task.status !== 'DRAFT'}
                    value={listState.width}
                    handleChange={e =>
                      handleFieldChange('width', e.target.value, true)
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <Divider height="16px" />
          <SimpleLabel text="Взрывопожарная и пожарная опасность" />
          <Divider height="6px" />
          <SidebarDropdown
            disabled={task.status !== 'DRAFT'}
            selectedValue={listState.fireDangerRating}
            list={fireDangerRatingOptions}
            handleChange={handleFieldChange}
            type="fireDangerRating"
          />
          <Divider height="16px" />
          <SimpleLabel text="Конструктивная пожарная опасность" />
          <Divider height="6px" />
          <SidebarDropdown
            disabled={task.status !== 'DRAFT'}
            selectedValue={listState.structuralFireHazardClass}
            list={structuralFireHazardClassOptions}
            handleChange={handleFieldChange}
            type="structuralFireHazardClass"
          />
          <Divider height="16px" />
          <SimpleLabel text="Огнестойкость" />
          <Divider height="6px" />
          <SidebarDropdown
            disabled={task.status !== 'DRAFT'}
            selectedValue={listState.fireResistanceRating}
            list={fireResistanceRatingOptions}
            handleChange={handleFieldChange}
            type="fireResistanceRating"
          />
          <Divider height="16px" />
          <SimpleLabel text="Тип конструкции" />
          <Divider height="6px" />
          <SidebarDropdown
            disabled={task.status !== 'DRAFT'}
            selectedValue={listState.constructionType}
            list={constructionTypeOptions}
            handleChange={handleFieldChange}
            type="constructionType"
          />
          <Divider height="16px" />
          <SimpleLabel text="Тип емкости" />
          <Divider height="6px" />
          <SidebarDropdown
            disabled={task.status !== 'DRAFT'}
            selectedValue={listState.tankLocationType}
            list={tankLocationTypes}
            handleChange={handleFieldChange}
            type="tankLocationType"
          />
          <Divider height="16px" />
        </div>
      )}
      <button
        type="button"
        onClick={() => setConnectionsOpen(!isConnectionsOpen)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          alignSelf: 'stretch',
          width: '100%',
          padding: '8px 0 0 30px',
        }}
      >
        {isConnectionsOpen ? <ArrowDown /> : <ToggleArrowIcon />}
        <Divider width="5px" />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          <p style={{ color: '#F0F0F0', fontSize: '14px' }}>
            {`Соединения (${connectionsCount})`}
          </p>
        </div>
      </button>
      {isConnectionsOpen && renderBuildingConnections()}
      <Divider height="26px" />
    </div>
  );
}

function BuildingsList(props: {
  task: ITaskSlice;
  handleOpen: any;
  buildingsTypes: IDictionariesSlice['allBuildingTypes'][];
}) {
  const wrapperRef = useRef<any>(null);
  const dispatch = useDispatch();

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      props.handleOpen();
    }
  };

  const handleClick = async (id: string, name: string) => {
    const taskBuildingParameters = props.task.buildings?.length
      ? JSON.parse(JSON.stringify(props.task.buildings))
      : [];

    const newBuilding: any = { ...defaultBuildingProps };

    newBuilding.taskId = props.task.id;
    newBuilding.name = name;
    newBuilding.radius = 0;
    newBuilding.buildingTypeId = id;

    const newBuildingResponse = await createBuilding({
      buildingInput: newBuilding,
    });
    if (newBuildingResponse && newBuildingResponse.record) {
      taskBuildingParameters.push(newBuildingResponse.record);
      dispatch(updateTaskBuildings(taskBuildingParameters));
    }
    // TODO доделать обработку ответа и отправки ее в стор
    props.handleOpen();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const buildingsTypes = [...props?.buildingsTypes];
  const sortedBuildingsTypes = buildingsTypes.sort((a: any, b: any) => {
    if (a.name.split() < b.name.split()) return -1;
    if (a.name.split() > b.name.split()) return 1;

    return 0;
  });

  return (
    <div
      ref={wrapperRef}
      style={{
        position: 'absolute',
        top: 45,
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        alignSelf: 'stretch',
        backgroundColor: '#1D1D1F',
        maxHeight: '500px',
        overflow: 'auto',
        borderRadius: '3px',
        zIndex: 12,
      }}
    >
      {sortedBuildingsTypes?.map((item: any, i: any) => (
        <button
          key={i}
          type="button"
          className="buildings_types_add_button"
          onClick={() => handleClick(item.id, item.name)}
        >
          <p style={{ fontSize: '14px', color: '#CCCDCD' }}>{item.name}</p>
        </button>
      ))}
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  task: state.task,
  dictionaries: state.dictionaries,
  control: state.control,
});

export default connect(mapStateToProps)(BuildingsTab);
