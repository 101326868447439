import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../store/configureStore';
import {
  IControlSlice,
  ITaskSlice,
  IWebSocketSlice,
} from '../../../store/types';
import SidebarChangeButton from '../SidebarChangeButton';
import BuildingsTab from './BuildingsTab';
import CalculationTab from './CalculationTab';
import ParametersTab from './ParametersTab';
import ResultTab from './ResultTab';

const tabs = [
  { key: 0, value: 'ПАРАМЕТРЫ' },
  { key: 1, value: 'СООРУЖЕНИЯ' },
  { key: 2, value: 'РАСЧЕТ' },
  { key: 3, value: 'РЕЗУЛЬТАТ' },
];

interface Props {
  task: ITaskSlice;
  websocket: IWebSocketSlice;
  control: IControlSlice;
}

function SidebarTabs(props: Props) {
  const { task, control, websocket } = props;
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const setTabsContent = (value: number) => {
    if (value === 0) {
      return <ParametersTab />;
    }
    if (value === 1 && task.id) {
      return <BuildingsTab />;
    }
    if (value === 2 && task.id) {
      return <CalculationTab />;
    }
    if (value === 3 && task.id && websocket.taskStatus === 'SOLVED') {
      return <ResultTab />;
    }

    return null;
  };

  useEffect(() => {
    if (websocket.taskStatus === 'SOLVING' && task.status === 'DRAFT') {
      setSelectedTab(2);
    }

    if (websocket.taskStatus === 'SOLVED' && task.status === 'DRAFT') {
      setSelectedTab(0);
    }
  }, [websocket.taskStatus]);

  useEffect(() => {
    if (websocket.taskStatus === 'SOLVED' && control.selectedBuildingUid) {
      setSelectedTab(1);
    }
  }, [control.selectedBuildingUid]);

  return (
    <div className="sidebar__tabs">
      <div className="sidebar__tabs_tabs">
        {tabs
          .filter(item =>
            !task.id
              ? item.key !== 1 && item.key !== 2 && item.key !== 3
              : task.status === 'DRAFT'
              ? item.key !== 3
              : item.key !== null,
          )
          .map((item: any, i: any) => (
            <button
              key={i}
              type="button"
              className="sidebar__tabs_tabs__button"
              onClick={() => setSelectedTab(item.key)}
              style={{
                backgroundColor:
                  item.key === selectedTab ? '#2F2F2F' : '#242425',
              }}
            >
              <p
                style={{
                  color:
                    item.key === selectedTab
                      ? '#F0F0F0'
                      : 'rgba(204, 205, 205, 0.6)',
                }}
              >
                {item.value}
              </p>
            </button>
          ))}
      </div>
      <div
        className={
          selectedTab === 2 || (selectedTab === 0 && !task.id)
            ? 'sidebar__tabs_content__with_button'
            : 'sidebar__tabs_content'
        }
      >
        {setTabsContent(selectedTab)}
      </div>
      {selectedTab === 0 && !task.id ? (
        <SidebarChangeButton control={control} />
      ) : null}
      {selectedTab === 2 ? <SidebarChangeButton control={control} /> : null}
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  task: state.task,
  websocket: state.websocket,
  control: state.control,
});

export default connect(mapStateToProps)(SidebarTabs);
