import gql from 'graphql-tag';

import { BUILDING_FRAGMENT } from './fragments/buildings';

export default gql`
  mutation createBuilding($buildingInput: CreateBuildingInput!) {
    buildingMutation {
      createBuilding(input: $buildingInput) {
        record {
          ...BuildingFragment
        }
        recordId
      }
    }
  }
  ${BUILDING_FRAGMENT}
`;
