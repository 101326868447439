import React, { useContext, useState } from 'react';
import { MapContext } from 'react-mapbox-gl';

import { ReactComponent as Accept } from 'assets/images/accept.svg';
import { ReactComponent as AffectedAreasIcon } from 'assets/images/bang.svg';
import { ReactComponent as Barriers } from 'assets/images/barriers.svg';
import { ReactComponent as CommunicationsIcon } from 'assets/images/communication-icon.svg';
import { ReactComponent as CorridorsIcon } from 'assets/images/corridors-icon.svg';
import { ReactComponent as GridIcon } from 'assets/images/grid-icon.svg';
import { ReactComponent as InterfaceNodes } from 'assets/images/interface-nodes.svg';
import { ReactComponent as Roads } from 'assets/images/roads.svg';
import { ReactComponent as ThermalIcon } from 'assets/images/thermal-areas.svg';

import { vectorLayers } from '../style';

function LayersToolbar(props: any) {
  const mapContext = useContext(MapContext);
  // const wrapperRef: any = useRef(null);
  const [selectedLayers, setLayersSelected] = useState<any>([]);

  const isVisible = (layerId: string) => {
    let visibility: any;
    if (mapContext) {
      visibility = mapContext.getLayoutProperty(layerId, 'visibility');
    }
    return visibility === 'visible';
  };

  const setLayerVisible = (layerId: any) => {
    if (mapContext) {
      if (
        selectedLayers.includes(
          typeof layerId === 'object' ? layerId[0] : layerId,
        )
      ) {
        const copy = [...selectedLayers];
        const layer = typeof layerId === 'object' ? layerId[0] : layerId;
        const newArr = copy.filter((item: any) => item !== layer);
        setLayersSelected(newArr);
      } else {
        const copy = [...selectedLayers];
        const layer = typeof layerId === 'object' ? layerId[0] : layerId;
        copy.push(layer);
        setLayersSelected(copy);
      }
      if (isVisible(typeof layerId === 'object' ? layerId[0] : layerId)) {
        if (typeof layerId === 'object') {
          layerId.forEach((item: any) => {
            mapContext.setLayoutProperty(item, 'visibility', 'none');
          });
        } else {
          mapContext.setLayoutProperty(layerId, 'visibility', 'none');
        }
      } else if (typeof layerId === 'object') {
        layerId.forEach((item: any) => {
          mapContext.setLayoutProperty(item, 'visibility', 'visible');
        });
      } else {
        mapContext.setLayoutProperty(layerId, 'visibility', 'visible');
      }
    }
  };

  const replaceLayerName = (layerId: string, i: any) => {
    if (isVisible(layerId) && !selectedLayers.includes(layerId)) {
      const copy = [...selectedLayers];
      copy.push(layerId);
      setLayersSelected(copy);
    }
    console.log('layerId', layerId);
    const layerNames = [
      { eng: 'development_area', ru: 'План застройки', icon: <Roads /> },
      {
        eng: 'roads',
        ru: 'Внутриплощадочные проезды',
        icon: <Roads />,
        as: ['roads', 'road_borders', 'road_lines'],
      },
      // { eng: 'road_borders', ru: 'Границы дорог', icon: <Roads /> },
      // { eng: 'road_lines', ru: 'Линии в центре дорог', icon: <Roads /> },
      {
        eng: 'utility_lines',
        ru: 'Коммуникации',
        icon: <CommunicationsIcon />,
      },
      // {
      //   eng: 'corridors',
      //   ru: 'Коридоры коммуникаций',
      //   icon: <CorridorsIcon />,
      // },
      {
        eng: 'corridors_overground',
        ru: 'Коридоры коммуникаций надземные',
        icon: <CorridorsIcon />,
      },
      {
        eng: 'corridors_underground',
        ru: 'Коридоры коммуникаций подземные',
        icon: <CorridorsIcon />,
      },
      {
        eng: 'barriers',
        ru: 'Ограждения',
        icon: <Barriers />,
        as: ['barriers', 'barriers-2'],
      },
      { eng: 'barriers-2', ru: 'Ограждение 2', icon: <Barriers /> },
      { eng: 'buildings', ru: 'Здания', icon: <Roads /> },
      { eng: 'site_plan', ru: 'Границы проекта', icon: <Roads /> },
      {
        eng: 'grid_lines',
        ru: 'Строительная Сетка',
        icon: <GridIcon />,
        as: ['grid_lines', 'grid_lines_ends'],
      },
      // { eng: 'grid_lines_ends', ru: 'Строительная Сетка', icon: <GridIcon /> },
      {
        eng: 'affected_areas',
        ru: 'Зоны поражения взрывной волны',
        icon: <AffectedAreasIcon />,
      },
      {
        eng: 'thermal_radiation_areas',
        ru: 'Зоны теплового излучения',
        icon: <ThermalIcon />,
      },
      { eng: 'buildings-centers', ru: 'Центры зданий', icon: <Roads /> },
      {
        eng: 'buildings-permanent-staff',
        ru: 'Постоянное присутствие людей',
        icon: <Roads />,
      },
      {
        eng: 'buildings-permanent-staff-2',
        ru: 'Постоянное присутствие людей 2',
        icon: <Roads />,
      },
      {
        eng: 'nodes',
        ru: 'Интерфейсные точки сооружений',
        icon: <InterfaceNodes />,
      },
    ];

    const currentName = layerNames.find((item: any) =>
      item.eng.includes(layerId),
    );

    return (
      <button
        key={i}
        type="button"
        className="layers-toolbar__layer-button"
        onClick={() =>
          setLayerVisible(currentName?.as ? currentName?.as : currentName?.eng)
        }
      >
        <div
          className={
            selectedLayers.includes(layerId)
              ? 'layers-toolbar__layer-button-icon-selected'
              : 'layers-toolbar__layer-button-icon-unselected'
          }
        >
          {currentName?.icon}
        </div>
        <p
          className={
            selectedLayers.includes(layerId)
              ? 'layers-toolbar__layer-button-text-selected'
              : 'layers-toolbar__layer-button-text-unselected'
          }
        >
          {currentName?.ru}
        </p>
      </button>
    );
  };

  // const changeMapStyle = () => {
  //   if (mapContext) {
  //     const mapStyle = mapContext.getStyle();
  //     console.log('mapStyle', mapStyle);
  //   }
  // };

  return (
    <>
      <div id="layers-toolbar" className="layers-toolbar">
        <div className="layers-toolbar__title">
          <p className="layers-toolbar__title-text">СЛОИ</p>
        </div>
        {vectorLayers
          .filter((layer: any) =>
            [
              'roads',
              'utility_lines',
              'corridors_overground',
              'corridors_underground',
              // 'corridors',
              'barriers',
              'nodes',
              'affected_areas',
              'thermal_radiation_areas',
              'grid_lines',
            ].includes(layer.id),
          )
          .map((layer: any, i: any) => {
            return replaceLayerName(layer.id, i);
          })}
        <div className="layers-toolbar__title">
          <p className="layers-toolbar__title-text">ПОДЛОЖКА</p>
        </div>
        <button
          type="button"
          className="layers-toolbar__layer-button"
          // onClick={changeMapStyle}
        >
          <div className="layers-toolbar__layer-button-icon-selected">
            <Accept />
          </div>
          <p className="layers-toolbar__layer-button-text-selected">
            Карта местности
          </p>
        </button>
        <button type="button" className="layers-toolbar__layer-button">
          <div className="layers-toolbar__layer-button-icon-selected-null" />
          <p className="layers-toolbar__layer-button-text-selected">
            Космоснимок
          </p>
        </button>
      </div>
      <div className="layers-toolbar__arrow" />
    </>
  );
}

export default LayersToolbar;
