import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Divider from 'elements/Divider';
import RowsHeaderButton from 'elements/HeaderButtons/RowsHeaderButton';
import RowsIcon from 'elements/Icons/RowsIcon';
import { updateControl } from 'store/controlSlice';

import { likeTask, unLikeTask } from '../../apollo/requests';
import Favorite from '../../elements/Favorite/Favorite';
import FavoriteIcon from '../../elements/Icons/FavoriteIcon';
import { RootState } from '../../store/configureStore';
import { updateTaskFavorite, updateTaskName } from '../../store/taskSlice';
import { ITaskSlice } from '../../store/types';
import { renameTask } from './services';

interface Props {
  task: ITaskSlice;
}

function SidebarHeader(props: Props) {
  const { task } = props;
  const dispatch = useDispatch();

  const [activeTaskName, setActiveTaskName] = useState(false);
  const [taskName, setTaskName] = useState<string>('');

  const handleChange = (value: string) => {
    setTaskName(value);
    if (task.id) {
      renameTask({
        input: { taskId: task.id, name: value },
      });
    }
  };

  const setTaskNameActive = () => {
    setActiveTaskName(!activeTaskName);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Escape') {
      if (activeTaskName) {
        setTaskNameActive();
      }
    }
  };

  const setLike = () => {
    if (!task.isFavorite) {
      likeTask(task.id);
      dispatch(updateTaskFavorite(true));
      return;
    }

    dispatch(updateTaskFavorite(false));
    unLikeTask(task.id);
  };

  const setDeleteModalData = (name: string, created: string, id: string) => {
    dispatch(updateControl({ deleteTaskData: { name, created, id } }));
  };

  useEffect(() => {
    if (task.id) {
      setTaskName(task.name as string);
    } else {
      setTaskName('Название проекта');
      dispatch(updateTaskName('Название проекта'));
    }
  }, [task.id]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [activeTaskName]);

  return (
    <div className="sidebar__header">
      <Divider width="10px" />
      <RowsHeaderButton
        task={task}
        setTaskNameActive={setTaskNameActive}
        setDeleteModalData={setDeleteModalData}
      />
      <div className="sidebar__header_input-container">
        <input
          disabled={!activeTaskName}
          style={{ backgroundColor: activeTaskName ? '#242425' : '#2F2F2F' }}
          placeholder="aaa"
          value={taskName}
          onChange={(e: any) => handleChange(e.target.value)}
        />
      </div>
      <button type="button" onClick={setLike}>
        {task.isFavorite ? <FavoriteIcon /> : <Favorite />}
      </button>
      <Divider width="12px" />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  task: state.task,
});

export default connect(mapStateToProps)(SidebarHeader);
