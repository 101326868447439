import * as React from 'react';

import { ReactComponent as ErrorIcon } from '../../assets/images/error-modal.svg';
import { ErrorModalViewType } from './types';

const ErrorModalTitleView: React.FC<
  Omit<ErrorModalViewType, 'copyCodeHandler'>
> = ({ onClickHandler, errorText }) => (
  <>
    <div
      aria-label="close"
      onClick={onClickHandler}
      className="error-modal"
      role="button"
      tabIndex={0}
      onKeyPress={onClickHandler}
    />
    <div className="error-modal__container">
      <div className="error-modal__sub-container">
        <div>
          <div className="error-modal__title-text">
            <ErrorIcon />
            <div className="error-modal__text-container">
              <p className="error-modal__text text">
                Название «<span className="text-bold">{errorText}</span>» уже
                используется. <br />
                Выберите другое название.
              </p>
            </div>
          </div>
          <div className="error-modal__button">
            <button
              type="button"
              onClick={onClickHandler}
              className="primary-button "
            >
              Закрыть
            </button>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default ErrorModalTitleView;
