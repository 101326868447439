import * as React from 'react';
import { Layer } from 'react-mapbox-gl';

import dangerousPattern from 'assets/images/dangerous-pattern.svg';
import deadlyPattern from 'assets/images/deadly-pattern.svg';
import safePattern from 'assets/images/safe-pattern.svg';
import staffPattern from 'assets/images/staff.svg';

const imagesObj = {
  deadlyPattern,
  dangerousPattern,
  safePattern,
  staffPattern,
};

const imagesArray = Object.keys(imagesObj).map(key => {
  const image = new Image(30, 30);
  image.src = imagesObj[key];
  return [key, image];
});

const Patterns: React.FC = () => (
  <>
    {imagesArray.map((images, index) => {
      return (
        <Layer
          key={index}
          type="symbol"
          layout={{
            'icon-image': images[0],
            'icon-allow-overlap': true,
          }}
          images={images}
        />
      );
    })}
  </>
);

export default Patterns;
