import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/configureStore';

import reducers from '../containers/Sidebar/sidebarReducers';
import { SidebarSliceStateType } from '../containers/Sidebar/types';

export const initialState: SidebarSliceStateType = {
  buildings: [],
  buildingsCount: {},
  allExternalPoints: [],
  taskInput: {},
  activeButton: 'PARAMETERS',
  hiddenSidebar: false,
};

export const sidebarSlice = createSlice({
  reducers,
  initialState,
  name: 'sidebar',
});

export const {
  updateStatus,
  resetState,
  setResult,
  setError,
  setBuildings,
  handleError,
  setBuildingsCount,
  setData,
  updateTaskInput,
  // setTaskTrace,
  setActiveTab,
  setInitialBuildingsCount,
  updateConnectionPointPosition,
  setHiddenSidebar,
} = sidebarSlice.actions;

const getRegionId = (state: RootState) => state.sidebar.taskInput.regionId;

const getDemId = (state: RootState) => state.sidebar.taskInput.demId;

const getLicenseAreaId = (state: RootState) =>
  state.sidebar.taskInput.licenseAreaId;

// const getCalculationType = (state: RootState) =>
//   state.sidebar.taskInput.calculationType;

const getWindRoseAngleDeg = (state: RootState) =>
  state.sidebar.taskInput.windRoseAngleDeg;

const getUser = (state: RootState) => state.sidebar.taskInput.userId;

const getName = (state: RootState) => state.sidebar.taskInput.name;

const getTaskId = (state: RootState) => state.sidebar.result?.id;

const getTaskFavorite = (state: RootState) => state.sidebar.result?.isFavorite;

export const selectTaskInput = (state: RootState) => state.sidebar.taskInput;

export const selectRegionId = createSelector(getRegionId, id => id);

export const selectDemId = createSelector(getDemId, id => id);

export const selectLicenseAreaId = createSelector(getLicenseAreaId, id => id);

// export const selectCalculationType = createSelector(
//   getCalculationType,
//   type => type,
// );

export const selectWindRoseAngleDeg = createSelector(
  getWindRoseAngleDeg,
  deg => deg,
);

export const selectuserId = createSelector(getUser, id => id);

export const selectName = createSelector(getName, name => name);

export const selectTaskId = createSelector(getTaskId, id => id);

export const selectTaskFavorite = createSelector(
  getTaskFavorite,
  favorite => !!favorite,
);

export const selectBuildings = (state: RootState) => state.sidebar.buildings;

export const selectBuildingsById = createSelector(selectBuildings, buildings =>
  buildings!.reduce((acc = {}, cur) => {
    acc[cur!.id] = cur;
    return acc;
  }, {}),
);

export const selectBuildingsCount = (state: RootState) =>
  state.sidebar.buildingsCount;

export const selectResult = (state: RootState) => state.sidebar.result;

export const selectError = (state: RootState) => state.sidebar.error;

export const selectDevelopmentArea = (state: RootState) =>
  state.sidebar.result?.developmentArea;

export const selectRegions = (state: RootState) => state.sidebar.allRegions;

export const selectDems = (state: RootState) => state.sidebar.allDems;

export const selectUsers = (state: RootState) => state.sidebar.allUsers;

export const selectLicenseAreas = (state: RootState) =>
  state.sidebar.allLicenseAreas;

export const selectExternalPoints = (state: RootState) =>
  state.sidebar.allExternalPoints;

export const selectTaskTrace = (state: RootState) => state.sidebar.taskTrace;

export const selectConnectionPointTypesById = (state: RootState) =>
  state.sidebar.allConnectionPointTypes?.reduce((acc: any = {}, cur) => {
    if (cur?.id) acc[cur.id] = cur;

    return acc;
  }, {});

export const selectStatus = (state: RootState) => state.sidebar.status;

export const selectContentTypes = (state: RootState) =>
  state.sidebar.allContentTypes;

export const selectBuildingTypes = (state: RootState) =>
  state.sidebar.allBuildingTypes;

export const selectBuildingTypesById = createSelector(
  selectBuildingTypes,
  types =>
    types!.reduce((acc: any = {}, cur) => {
      acc[cur!.id] = cur;
      return acc;
    }, {}),
);

export const selectActiveButton = (state: RootState) =>
  state.sidebar.activeButton;

export const selectTaskCenter = (state: RootState) =>
  state.sidebar.result?.center;

export const selectHiddenSidebar = (state: RootState) =>
  state.sidebar.hiddenSidebar;

export default sidebarSlice.reducer;
