import {
  setTaskCalculation,
  setTaskStatus,
  setTaskTrace,
} from '../store/webSocketSlice';
import Socket from './Socket';

const socketMiddleware = (store: any) => {
  const dispatch: any = store.dispatch;

  // HANDLE TASK TRACE
  const getTaskTrace = (data: any) => {
    dispatch(setTaskTrace(data));
  };

  const getTaskCalculation = (data: any) => {
    dispatch(setTaskCalculation(data));
  };

  const getTaskStatus = (data: any) => {
    dispatch(setTaskStatus(data));
  };

  // HENDLE ERRORS
  const handleError = (error: any) => {
    dispatch({ type: 'SOCKET_ERROR', payload: error });
  };

  const socket = new Socket(
    getTaskTrace,
    getTaskCalculation,
    getTaskStatus,
    handleError,
  );

  return (next: any) => async (action: any) => {
    switch (action.type) {
      case 'SOCKET_CONNECT': {
        const task = store.getState().task;
        await socket.connect(task.id);
        break;
      }
      case 'SOCKET_DISCONNECT':
        socket.disconnect();
        break;
      default:
        break;
    }

    return next(action);
  };
};

export default socketMiddleware;
