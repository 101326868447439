import gql from 'graphql-tag';

export const CONNECTION_TYPE_FRAGMENT = gql`
  fragment ConnectionTypeFragment on ConnectionType {
    id
    name
    widthM
    cost
  }
`;

export const CONNECTION_POINT_TYPE_FRAGMENT = gql`
  fragment ConnectionPointTypeFragment on ConnectionPointType {
    id
    name
    isExternal
  }
`;

export const CONNECTION_POINT_FRAGMENT = gql`
  fragment ConnectionPointFragment on ConnectionPoint {
    id
    pointM
    buildingId
    typeId
    pointType {
      ...ConnectionPointTypeFragment
    }
  }
  ${CONNECTION_POINT_TYPE_FRAGMENT}
`;

export const CONNECTED_BUILDING_FRAGMENT = gql`
  fragment ConnectedBuildingFragment on ConnectedBuilding {
    targetBuildingId
    connectionTypeId
    connectionPointTypeId
    offset
    side
    connectionId
    targetBuilding {
      id
    }
    connectionType {
      ...ConnectionTypeFragment
    }
  }
  ${CONNECTION_TYPE_FRAGMENT}
`;
