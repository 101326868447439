import gql from 'graphql-tag';

export default gql`
  query getConnectionsFromBuilding($buildingId: Uuid!) {
    connectionsFromBuilding(buildingId: $buildingId) {
      connectionPointId
      connectionTypeName
      targetBuildingName
      connectionPointIsExternal
      connectionPointTypeName
      targetBuildingId
      targetBuildingLabel
      connectionDirection
      connectionPointIsExists
      connectionTypeId
      connectedConnectionPointId
      connectionSide
      pointOffset
      connectionId
      connectionPointTypeId
      locationType
    }
  }
`;
