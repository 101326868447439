import * as React from 'react';

function BuildingsSearchCancelIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.94 8l2.867-2.86a.67.67 0 00-.947-.946L8 7.06 5.14 4.194a.67.67 0 10-.947.946L7.06 8l-2.867 2.86a.668.668 0 00.217 1.093.666.666 0 00.73-.146L8 8.94l2.86 2.867a.665.665 0 00.947 0 .666.666 0 000-.947L8.94 8z"
        fill="#999"
      />
    </svg>
  );
}

export default BuildingsSearchCancelIcon;
