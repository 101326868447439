import React from 'react';

interface Props {
  width?: string;
  height?: string;
  isLine?: boolean;
  lineColor?: string;
}

function Divider(props: Props) {
  return (
    <div
      style={{
        width: props.width || '100%',
        height: props.height || '1px',
        borderBottom: props.isLine ? '1px solid #1D1D1F' : 'transparent',
        borderColor: props.lineColor ? props.lineColor : '#1D1D1F',
      }}
    />
  );
}

export default Divider;
