import React from 'react';

interface Props {
  disabled?: boolean;
  width?: string;
  mathValue?: any;
  value: number;
  mathSymbol?: any;
  isToFixed?: boolean;
  toFixedValue?: number;
  handleChange: (e: any) => void;
}

function MathInput(props: Props) {
  const {
    disabled,
    isToFixed,
    toFixedValue,
    width,
    mathValue,
    value,
    mathSymbol,
    handleChange,
  } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'row nowrap',
        width: width || '100%',
        backgroundColor: disabled ? '#2F2F2F' : '#242425',
        padding: '6px 12px',
        border: '1px solid #4A4A4A',
        borderRadius: '2px',
      }}
    >
      <input
        value={isToFixed ? value.toFixed(toFixedValue) : value}
        onChange={handleChange}
        disabled={disabled}
        style={{
          minWidth: '20px',
          // maxWidth: width ? '100px' : '100%',
          backgroundColor: disabled ? '#2F2F2F' : '#242425',
          border: 'none',
          fontSize: '14px',
          color: disabled ? '#999999' : '#f0f0f0',
        }}
      />
      <p style={{ color: disabled ? '#999999' : '#f0f0f0' }}>
        {mathValue || ''}
        <sup>{mathSymbol}</sup>
      </p>
    </div>
  );
}

export default MathInput;
