import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as DropdownArrow } from 'assets/images/dropdown-arrow.svg';

interface Props {
  disabled?: boolean;
  type: string;
  selectedValue: string | number | null | boolean;
  list: any;
  handleChange: (e?: any, a?: any) => void;
}

function SidebarDropdown(props: Props) {
  const { selectedValue, list, handleChange, type, disabled } = props;
  const wrapperRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const getListValue = () => {
    let listValue = '';
    if (list) {
      if (Array.isArray(list)) {
        list.forEach((item: any) => {
          if (item.id === selectedValue) {
            // TODO поставить беку задачу на стандартизацию имен параметров
            if (item.name) {
              listValue = item.name;
            }

            if (item.demType) {
              listValue = item.demType;
            }
          }

          if (item.value === selectedValue) {
            listValue = item.label;
          }
        });
      } else {
        Object.keys(list).forEach((key: any) => {
          if (key === selectedValue) {
            listValue = list[key];
          }
        });
      }
    }

    return listValue;
  };

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <button
      disabled={disabled}
      ref={wrapperRef}
      type="button"
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
        backgroundColor: disabled ? '#2F2F2F' : '#242425',
        border: '1px solid #4A4A4A',
        borderRadius: '2px',
        padding: '8px 12px',
      }}
      onClick={() => setIsOpen(!isOpen)}
    >
      <p style={{ fontSize: '14px', color: '#f0f0f0' }}>
        {selectedValue ? getListValue() : 'Не выбран'}
      </p>
      <DropdownArrow />
      {list && isOpen ? (
        <div
          style={{
            position: 'absolute',
            top: '35px',
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignSelf: 'stretch',
            width: '100%',
            zIndex: 11,
            backgroundColor: '#242425',
            borderRadius: '3px',
          }}
        >
          {Array.isArray(list)
            ? list.map((item: any, i: any) => (
                <button
                  key={i}
                  type="button"
                  onClick={() =>
                    handleChange(type, item.id ? item.id : item.value)
                  }
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    padding: '12px',
                    width: '100%',
                  }}
                >
                  <p style={{ color: '#f0f0f0' }}>
                    {item.name
                      ? item.name
                      : item.demType
                      ? item.demType
                      : item.label
                      ? item.label
                      : null}
                  </p>
                </button>
              ))
            : Object.keys(list).map((key: any, i: any) => (
                <button
                  key={i}
                  type="button"
                  onClick={() => handleChange(type, key)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    padding: '12px',
                    width: '100%',
                  }}
                >
                  <p style={{ color: '#f0f0f0' }}>{list[key]}</p>
                </button>
              ))}
        </div>
      ) : null}
    </button>
  );
}

export default SidebarDropdown;
