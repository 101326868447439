import * as React from 'react';

import { ReactComponent as CancelIcon } from '../../assets/images/cancel.svg';
import { ReactComponent as ErrorIcon } from '../../assets/images/error-modal.svg';
import { ErrorModalViewType } from './types';

const ErrorModalResponseView = React.forwardRef<
  HTMLTextAreaElement,
  ErrorModalViewType
>(({ onClickHandler, copyCodeHandler, errorText }, textareaRef) => (
  <>
    <div
      aria-label="close"
      onClick={onClickHandler}
      className="error-modal"
      role="button"
      tabIndex={0}
      onKeyPress={onClickHandler}
    />
    <div className="error-modal__container">
      <div className="error-modal__sub-container">
        <button
          type="button"
          onClick={onClickHandler}
          className="error-modal__close"
        >
          <CancelIcon />
        </button>
        <ErrorIcon />
        <div className="error-modal__text-container">
          <p className="error-modal__text text">Ошибка! Что-то пошло не так.</p>
          <p className="error-modal__text text">
            Вы можете{' '}
            <button
              type="button"
              onClick={copyCodeHandler}
              className="error-modal__copy"
            >
              скопировать код ошибки.
            </button>
          </p>
        </div>
      </div>
    </div>
    <textarea
      className="error-modal__text-area"
      ref={textareaRef}
      value={errorText}
    />
  </>
));

export default ErrorModalResponseView;
