import gql from 'graphql-tag';

import { BUILDING_FRAGMENT } from './fragments/buildings';
import {
  CALCULATION_METHOD_FRAGMENT,
  CALCULATION_STAGE_FRAGMENT,
} from './fragments/scenarios';
import { EXTERNAL_POINTS } from './fragments/types';

export default gql`
  query getTask($id: Uuid!) {
    task(taskId: $id) {
      id
      developmentArea
      # calculationType
      windRoseAngleDeg
      licenseAreaId
      regionId
      userId
      demId
      isFavorite
      name
      status
      center
      scenarioId
      buildings {
        ...BuildingFragment
      }
      sitePlan {
        # id
        preparationCost
        buildingCoverageRatio
        totalUtilityLinesLength
        transportationDistance
        corridorTotalLengthM
        pipelineTotalLengthM
        powerlineTotalLengthM
        sitePlanArea
        buildingArea
        internalRoadsArea
        earthworkM3
        sandM3
        coverM2
        geotextileLayerM2
        slopeThermalLayerM2
        thermalLayerM3
        rollLawnLayerM2
        geogridMakingM2
        smallRockM3
        sandTransportationTon
      }
      externalPoints {
        ...ExtrenalPointsFragment
      }
      taskCalculations {
        taskCalculationId
        taskId
        calculationId
        stageId
        methodId
        isFinalResult
        status
      }
    }
  }
  ${BUILDING_FRAGMENT}
  ${EXTERNAL_POINTS}
  ${CALCULATION_METHOD_FRAGMENT}
  ${CALCULATION_STAGE_FRAGMENT}
`;
