import gql from 'graphql-tag';

export default gql`
  mutation updateTask($taskInput: UpdateTaskInput!) {
    taskMutation {
      updateTask(input: $taskInput) {
        recordId
      }
    }
  }
`;
