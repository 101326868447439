import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import ErrorModal from 'containers/ErrorModal';
import Map from 'containers/Map';
import Sidebar from 'containers/Sidebar';
import CalculationErrorModal from 'elements/Modals/CalculationErrorModal';
import { updateControl } from 'store/controlSlice';
import { setDictionaries } from 'store/dictionariesSlice';
import { setMapInitial } from 'store/mapSlice';
import { setTask, setTaskInitial } from 'store/taskSlice';
import { deleteTask } from 'store/tasksSlice';

import { deleteTask as removeTask, getData, getTask } from '../apollo/requests';
import { RootState } from '../store/configureStore';
import { setGeometry, setProjectArea } from '../store/mapSlice';
import {
  IControlSlice,
  IDictionariesSlice,
  ITaskSlice,
  IWebSocketSlice,
} from '../store/types';
import { calculateArea } from '../utils';
import SidebarHeader from './Sidebar/SidebarHeader';
import TaskListModal from './TaskList/TaskListModal';

interface Props {
  websocket: IWebSocketSlice;
  task: ITaskSlice;
  control: IControlSlice;
  dictionaries: IDictionariesSlice;
}

function RootContainer(props: Props) {
  const { task, websocket, dictionaries, control } = props;

  const dispatch = useDispatch();
  const { taskId } = useParams() as any;
  const location = useLocation();
  const history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [projectData, setProjectData] = useState({
    name: '',
    created: '',
    id: '',
  });

  const fetchTask = async () => {
    const data = await getTask(taskId);

    if (!data) return;
    dispatch(setTask(data));
    const { developmentArea } = data;
    const developmentAreaGeometry = JSON.parse(developmentArea);
    const areaInKm = calculateArea(developmentAreaGeometry.coordinates[0]);
    dispatch(setProjectArea(areaInKm));
    dispatch(setGeometry(developmentAreaGeometry));

    dispatch({ type: 'SOCKET_CONNECT' });
  };

  const fetchDictionaries = async () => {
    const data: any = await getData();
    if (!data) return;
    dispatch(setDictionaries(data));
  };

  const onRemoveHandler = async () => {
    const deleteTaskId = control.deleteTaskData?.id;
    if (!deleteTaskId) return;

    await removeTask({ taskId: deleteTaskId });
    dispatch(deleteTask(deleteTaskId as string));
    history.push('/task-list');
    setShowModal(false);
    dispatch(updateControl({ deleteTaskData: null }));
  };

  const onCloseHandler = () => {
    dispatch(updateControl({ deleteTaskData: null }));
    setShowModal(false);
  };

  useEffect(() => {
    if (control.deleteTaskData) {
      setShowModal(true);
    }
  }, [control.deleteTaskData]);

  useEffect(() => {
    if (location.pathname === '/create') {
      dispatch(setTaskInitial());
      dispatch(setMapInitial());
      if (!taskId && !task.isFetching && !task.regionId) {
        dispatch(
          setTask({
            regionId: 'be378786-ec63-49de-a298-1671a8173b4d',
            demId: '3c18de57-5627-4427-aeaa-fee1f474ef85',
            licenseAreaId: 'cbba63a0-1037-41cb-ae57-083550da03e7',
            windRoseAngleDeg: 0,
            status: 'DRAFT',
            name: 'Название проекта',
            scenarioId: '01010101-0101-0101-0101-010101010101',
          }),
        );
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (websocket.taskTrace?.status === 'SOLVED' && task.status === 'DRAFT') {
      fetchTask();
    }
  }, [websocket.taskTrace?.status]);

  useEffect(() => {
    if (taskId) {
      fetchTask();
    }

    if (!dictionaries.isFetching) {
      fetchDictionaries();
    }
  }, [taskId]);

  return (
    <div className="App">
      <SidebarHeader />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Sidebar />
        <Map />
      </div>
      {showModal && (
        <TaskListModal
          name={control.deleteTaskData?.name}
          created={control.deleteTaskData?.created}
          onClickHandler={onRemoveHandler}
          onCloseHandler={onCloseHandler}
        />
      )}
      <ErrorModal />
      <CalculationErrorModal />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  task: state.task,
  control: state.control,
  websocket: state.websocket,
  dictionaries: state.dictionaries,
});

export default connect(mapStateToProps)(RootContainer);
