import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import Divider from 'elements/Divider';

import { ReactComponent as ArrowDown } from '../../../assets/images/arrow-down.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/Delete.svg';
import CancelIcon from '../../../elements/Icons/CancelIcon';
import ConfirmIcon from '../../../elements/Icons/ConfirmIcon';
import { RootState } from '../../../store/configureStore';
import { IDictionariesSlice, ITaskSlice } from '../../../store/types';

import './style.scss';

const borders = [
  { id: 'TOP', name: 'Верх' },
  { id: 'BOTTOM', name: 'Низ' },
  { id: 'RIGHT', name: 'Право' },
  { id: 'LEFT', name: 'Лево' },
];

interface InputProps {
  type: string;
  inputText: string;
  connectionId: string;
  handleText: (e: any, a: any, с: any) => void;
  disabled: boolean;
}

function Input(props: InputProps) {
  const { inputText, handleText, type, connectionId, disabled } = props;

  return (
    <div className="map_table__rows__row-link">
      <input
        disabled={disabled}
        type="text"
        value={inputText}
        onChange={e => handleText(connectionId, type, e.target.value)}
        style={{ backgroundColor: disabled ? '#2F2F2F' : '#242425' }}
      />
    </div>
  );
}

interface DropDownProps {
  listValues: any;
  connectionId: string;
  selectedValue: string | number | null;
  setSelectedValue: (e: any, a: any, с: any) => void;
  index: number;
  type: string;
  disabled: boolean;
}

function DropDown(props: DropDownProps) {
  const {
    listValues,
    selectedValue,
    setSelectedValue,
    index,
    type,
    connectionId,
    disabled,
  } = props;

  const wrapperRef = useRef<any>(null);
  const [isOpen, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!isOpen);
  };

  const handleValue = (valueId: any) => {
    setSelectedValue(connectionId, type, valueId);
    setOpen(!isOpen);
  };

  const renderList = () => {
    const element = document.getElementById(`${`${index}relativeDropdownId`}`);
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = element?.getBoundingClientRect();
    const offset = elemRect ? bodyRect?.bottom - elemRect.bottom : null;

    if (isOpen && listValues) {
      return (
        <div
          ref={wrapperRef}
          className="map_table__dropdown-list"
          style={{ bottom: offset ? offset + 22 : 0 }}
        >
          {listValues.map((item: any, i: any) => (
            <button
              key={i}
              className="map_table__dropdown-list__row"
              type="button"
              onClick={() => handleValue(item.id)}
            >
              <p>{item.name}</p>
            </button>
          ))}
        </div>
      );
    }

    return null;
  };

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleScroll = () => {
    if (isOpen) setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const renderedSelectedValue = selectedValue
    ? listValues.find(
        (item: any) => item.id === selectedValue || item.name === selectedValue,
      )?.name
    : '--';

  return (
    <div key={index} className="map_table__rows__row-link">
      <div
        className="map_table__dropdown"
        id={`${`${index}relativeDropdownId`}`}
      >
        <button
          disabled={disabled}
          type="button"
          className="map_table__row__button"
          style={{ backgroundColor: disabled ? '#2F2F2F' : '#242425' }}
          onClick={handleClick}
        >
          <p>{renderedSelectedValue}</p>
          <div>
            <ArrowDown />
          </div>
        </button>
        {renderList()}
      </div>
    </div>
  );
}

interface SearchProps {
  connectionId: string;
  listValues: any;
  selectedValue: string | number | null;
  setSelectedValue: (e: any, a: any, с: any) => void;
  type: string;
  index: number;
  disabled: boolean;
}

function Search(props: SearchProps) {
  const {
    type,
    index,
    listValues,
    selectedValue,
    setSelectedValue,
    connectionId,
    disabled,
  } = props;

  const wrapperRef = useRef<any>(null);

  const [inputText, setInputText] = useState<string>('');
  const [isFocused, setFocusInput] = useState(false);

  const handleSelect = (valueId: any) => {
    setSelectedValue(connectionId, type, valueId);
    setFocusInput(false);
    setInputText('');
  };

  const renderList = () => {
    const element = document.getElementById(`${`${index}relativeSearchId`}`);
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = element?.getBoundingClientRect();
    const offset = elemRect ? bodyRect?.bottom - elemRect.bottom : null;

    if ((inputText || isFocused) && listValues) {
      let sortListValues: any;

      if (inputText) {
        sortListValues = listValues
          .filter(
            (item: any) =>
              item.name.toLowerCase().includes(inputText.toLowerCase()) ||
              item.label?.includes(inputText),
          )
          .sort();
      } else {
        sortListValues = listValues;
      }

      return (
        <div
          ref={wrapperRef}
          className="map_table__search-list"
          style={{ bottom: offset ? offset + 22 : 0 }}
        >
          {sortListValues
            // .sort((a: any, b: any) => a.name.includes(inputText))
            .map((item: any, i: any) => (
              <button
                key={i}
                className="map_table__search-list__row"
                type="button"
                onClick={() => handleSelect(item.id)}
              >
                {item.label ? (
                  <>
                    <p style={{ width: '30px' }}>{item.label}</p>
                    <Divider width="10px" />
                  </>
                ) : null}
                <p style={{ flex: 1 }}>{item.name}</p>
              </button>
            ))}
        </div>
      );
    }

    return null;
  };

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setInputText('');
      setFocusInput(false);
    }
  };

  const handleSelectedValue = () => {
    const selectedListItem = listValues.find(
      (item: any) => item.id === selectedValue,
    );

    let value: any;

    if (selectedListItem) {
      value = selectedListItem.label
        ? `${selectedListItem.label} ${selectedListItem.name}`
        : selectedListItem.name;
    } else {
      value = '';
    }

    return value;
  };

  const handleFocus = () => {
    setFocusInput(true);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      id={`${`${index}relativeSearchId`}`}
      className="map_table__rows__row-link"
    >
      <div className="map_table__search" id={`${`${index}relativeDropdownId`}`}>
        <input
          disabled={disabled}
          type="text"
          value={!inputText ? handleSelectedValue() : (inputText as string)}
          onChange={e => setInputText(e.target.value)}
          onFocus={handleFocus}
          style={{ backgroundColor: disabled ? '#2F2F2F' : '#242425' }}
        />
        {renderList()}
      </div>
    </div>
  );
}

interface TableProps {
  task: ITaskSlice;
  dictionaries: IDictionariesSlice;
  header: { key: string; value: string; rowType: string }[];
  updateRow: (e: any, a: any, c: any) => void;
  deleteRow: (e: any) => void;
  newConnectionsIds: string[];
  changeConnections: any;
  handleConfirm: (e?: any) => void;
  handleCancel: (e?: any) => void;
  confirmChangeConnection: (e?: any) => void;
  cancelChangeConnection: (e?: any) => void;
}

function Table(props: TableProps) {
  const {
    updateRow,
    deleteRow,
    dictionaries,
    task,
    changeConnections,
    newConnectionsIds,
    handleConfirm,
    handleCancel,
    confirmChangeConnection,
    cancelChangeConnection,
  } = props;

  let connections = task.buildingConnections;

  const connectionPointTypesNotExternal = dictionaries.allConnectionPointTypes?.filter(
    item => !item.isExternal,
  );

  const confirmDeleteButtons = (item: any) => {
    if (
      newConnectionsIds.includes(item.connectionId) ||
      (changeConnections.length &&
        changeConnections.some(
          (a: any) => a?.connectionId === item.connectionId,
        ))
    ) {
      return (
        <div className="map_table__row__confirm_delete">
          <button
            type="button"
            className="map_table__rows__confirm_button"
            onClick={() =>
              changeConnections.some(
                (a: any) => a.connectionId === item.connectionId,
              )
                ? confirmChangeConnection(item.connectionId)
                : handleConfirm(item.connectionId)
            }
          >
            <ConfirmIcon />
          </button>
          <Divider width="15px" />
          <button
            type="button"
            className="map_table__rows__cancel_button"
            onClick={() =>
              changeConnections.some(
                (a: any) => a.connectionId === item.connectionId,
              )
                ? cancelChangeConnection(item.connectionId)
                : handleCancel(item.connectionId)
            }
          >
            <CancelIcon />
          </button>
        </div>
      );
    }
    return (
      <button
        type="button"
        className="map_table__rows__cancel_button"
        onClick={() => deleteRow(item.connectionId)}
      >
        <DeleteIcon />
      </button>
    );
  };

  if (changeConnections && changeConnections.length) {
    const ids = changeConnections.map(item => {
      return item.connectionId;
    });

    const filterConnections = connections?.filter(
      item => !ids.includes(item.connectionId),
    );

    const newArr = filterConnections?.concat(changeConnections);

    connections = newArr;
  }

  return (
    <div className="map_table">
      <div className="map_table__header">
        {props.header.map((item: any, i: any) => (
          <div className="map_table__header-link">
            <p key={i}>{item.value}</p>
          </div>
        ))}
      </div>
      <div className="map_table__rows">
        {connections?.map((item, i) => {
          return (
            <div
              key={i}
              className="map_table__rows__row"
              style={{
                backgroundColor: i % 2 ? '#323232' : '#3A3A3A',
                gridTemplateColumns: newConnectionsIds.includes(
                  item.connectionId,
                )
                  ? '40px 100px 100px 100px auto auto 150px 70px'
                  : '40px 100px 100px 100px auto auto 150px 50px',
              }}
            >
              <div key={i} className="map_table__rows__row-link">
                <p>{i + 1}</p>
              </div>
              <DropDown
                disabled={task.status !== 'DRAFT'}
                connectionId={item.connectionId}
                type="connectionSide"
                selectedValue={item.connectionSide as string}
                listValues={borders}
                setSelectedValue={updateRow}
                index={i}
              />
              <Input
                disabled={task.status !== 'DRAFT'}
                connectionId={item.connectionId}
                type="pointOffset"
                inputText={item.pointOffset ? item.pointOffset?.toString() : ''}
                handleText={updateRow}
              />
              <DropDown
                disabled={task.status !== 'DRAFT'}
                connectionId={item.connectionId}
                type="connectionPointTypeId"
                selectedValue={item.connectionPointTypeId}
                listValues={connectionPointTypesNotExternal}
                setSelectedValue={updateRow}
                index={i}
              />
              <Search
                disabled={task.status !== 'DRAFT'}
                connectionId={item.connectionId}
                type="targetBuildingId"
                selectedValue={item.targetBuildingId}
                setSelectedValue={updateRow}
                index={i}
                listValues={task.buildings}
              />
              <Search
                disabled={task.status !== 'DRAFT'}
                connectionId={item.connectionId}
                type="connectionTypeId"
                selectedValue={item.connectionTypeId}
                setSelectedValue={updateRow}
                index={i}
                listValues={dictionaries.allConnectionTypes}
              />
              <DropDown
                disabled={task.status !== 'DRAFT'}
                connectionId={item.connectionId}
                type="locationType"
                selectedValue={item.locationType as string}
                listValues={[
                  { name: 'Подземные', id: 'UNDERGROUND' },
                  { name: 'Надземные', id: 'OVERGROUND' },
                ]}
                setSelectedValue={updateRow}
                index={i}
              />
              {task.status === 'DRAFT' ? confirmDeleteButtons(item) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  task: state.task,
  dictionaries: state.dictionaries,
});

export default connect(mapStateToProps)(Table);
