import cond from 'assets/images/cond.svg';
import condActive from 'assets/images/cond-active.svg';
import gas from 'assets/images/gas.svg';
import gasActive from 'assets/images/gas-active.svg';
import gs from 'assets/images/gs.svg';
import gsActive from 'assets/images/gs-active.svg';
import road from 'assets/images/road.svg';
import roadActive from 'assets/images/road-active.svg';
import vl from 'assets/images/vl.svg';
import vlActive from 'assets/images/vl-active.svg';
import ww from 'assets/images/ww.svg';
import wwActive from 'assets/images/ww-active.svg';

export const handleTypes = (type: string) => {
  if (type === 'ВЛ') return 'vl';
  if (type === 'Дорога') return 'road';
  if (type === 'Газопровод внешнего транспорта') return 'gas';
  if (type === 'Конденсатопровод внешнего транспорта') return 'cond';
  if (type === 'Газопровод-шлейф от куста скважин') return 'gs';
  if (type === 'Закачка производственных сточных вод в пласт') return 'ww';
  return '';
};

const imgsSrcs = [
  { cond, condActive },
  { gas, gasActive },
  { gs, gsActive },
  { road, roadActive },
  { vl, vlActive },
  { ww, wwActive },
];

const initSizes = {
  width: 16,
  height: 16,
};

export const imagesArr = imgsSrcs.map(srcs =>
  Object.keys(srcs).reduce((acc = {}, key) => {
    const img = new Image(initSizes.width * 4, initSizes.height * 4);
    img.src = srcs[key];
    acc[key] = img;
    return acc;
  }, {}),
);
