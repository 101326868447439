import * as React from 'react';

import { ReactComponent as ErrorIcon } from 'assets/images/error.svg';

const PopupErrorText: React.FC = () => (
  <span className="no-wrap">
    <span className="error-icon">
      <ErrorIcon />
    </span>
    Область не может превышать 10<span className="dem-text">км</span>
  </span>
);

export default PopupErrorText;
