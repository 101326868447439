import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IWebSocketSlice } from './types';

const initialState: IWebSocketSlice = {
  taskTrace: null,
  taskCalculation: null,
  taskStatus: null,
};

export const taskSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setTaskTrace: (
      state,
      action: PayloadAction<IWebSocketSlice['taskTrace']>,
    ) => {
      return {
        ...state,
        taskTrace: action.payload,
      };
    },
    setTaskCalculation: (
      state,
      action: PayloadAction<IWebSocketSlice['taskCalculation']>,
    ) => {
      return {
        ...state,
        taskCalculation: action.payload,
      };
    },
    setTaskStatus: (
      state,
      action: PayloadAction<IWebSocketSlice['taskStatus']>,
    ) => {
      return {
        ...state,
        taskStatus: action.payload,
      };
    },
  },
});

export const {
  setTaskTrace,
  setTaskCalculation,
  setTaskStatus,
} = taskSlice.actions;

export default taskSlice.reducer;
