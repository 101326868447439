import gql from 'graphql-tag';

import { BUILDING_FRAGMENT } from './fragments/buildings';
import { EXTERNAL_POINTS } from './fragments/types';

export default gql`
  mutation cloneTask($input: CloneTaskInput!) {
    taskMutation {
      cloneTask(input: $input) {
        record {
          id
          developmentArea
          # calculationType
          windRoseAngleDeg
          licenseAreaId
          regionId
          userId
          demId
          isFavorite
          name
          status
          center
          buildings {
            ...BuildingFragment
          }
          sitePlan {
            # id
            preparationCost
            buildingCoverageRatio
            totalUtilityLinesLength
            transportationDistance
            corridorTotalLengthM
            pipelineTotalLengthM
            powerlineTotalLengthM
            # sitePlanArea
            buildingArea
            # internalRoadsArea
            earthworkM3
            sandM3
            coverM2
            geotextileLayerM2
            slopeThermalLayerM2
            thermalLayerM3
            rollLawnLayerM2
            geogridMakingM2
            smallRockM3
            sandTransportationTon
          }
          externalPoints {
            ...ExtrenalPointsFragment
          }
          taskCalculations {
            taskCalculationId
            taskId
            calculationId
            stageId
            methodId
            isFinalResult
            status
          }
        }
        recordId
      }
    }
  }
  ${BUILDING_FRAGMENT}
  ${EXTERNAL_POINTS}
`;
