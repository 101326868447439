import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { ReactComponent as ErrorIcon } from '../../../assets/images/error-modal.svg';
import Divider from '../../../elements/Divider';
import { RootState } from '../../../store/configureStore';
import { ITaskSlice, IWebSocketSlice } from '../../../store/types';
import { formatter } from '../../../utils';

const fields = [
  {
    field: 'sitePlanArea',
    title: 'Площадь объекта',
    dem: 'га',
    multiply: 0.0001,
  },
  {
    field: 'buildingArea',
    title: 'Площадь застройки',
    dem: 'га',
    multiply: 0.0001,
  },
  {
    field: 'buildingCoverageRatio',
    title: 'Плотность застройки',
    dem: '%',
    multiply: 100,
  },
  {
    field: 'internalRoadsArea',
    title: 'Площадь внутриплощадных проездов',
    dem: <span className="dem-with-sq result-dem">м</span>,
    sup: 2,
  },
  {
    field: 'corridorTotalLengthM',
    title: 'Металлоемкость надземных эстакад',
    dem: <span className="dem-with-sq result-dem">т</span>,
  },
  {
    field: 'totalUtilityLinesLength',
    title: 'Общая длина коммуникаций',
    dem: 'м',
  },
  {
    field: 'pipelineTotalLengthM',
    title: 'Общая длина трубопроводов',
    dem: 'м',
  },
  {
    field: 'powerlineTotalLengthM',
    title: 'Общая длина кабельных коммуникаций',
    dem: 'м',
  },
  {
    field: 'earthworkM3',
    title: 'Земляные работы',
    dem: <span className="dem-with-cube result-dem">м</span>,
    sup: 3,
  },
  {
    field: 'sandM3',
    title: 'Песчанный грунт',
    dem: <span className="dem-with-cube result-dem">м</span>,
    sup: 3,
  },
  {
    field: 'coverM2',
    title: 'Лежневой настил',
    dem: <span className="dem-with-sq result-dem">м</span>,
  },
  {
    field: 'geotextileLayerM2',
    title: 'Прослойка из геотекстиля',
    dem: <span className="dem-with-sq result-dem">м</span>,
  },
  {
    field: 'slopeThermalLayerM2',
    title: 'Теплоизоляционные плиты под откосы (ММГ)',
    dem: <span className="dem-with-sq result-dem">м</span>,
  },
  {
    field: 'thermalLayerM3',
    title: 'Теплоизоляционные плиты (ММГ)',
    dem: <span className="dem-with-sq result-dem">м</span>,
  },
  {
    field: 'rollLawnLayerM2',
    title: 'Прослойка из биоматов для откосов',
    dem: <span className="dem-with-cube result-dem">м</span>,
  },
  {
    field: 'geogridMakingM2',
    title: 'Установка геотекстиля, георешетки и заполнение щебнем',
    dem: <span className="dem-with-sq result-dem">м</span>,
  },
  {
    field: 'smallRockM3',
    title: 'Щебень из природного камня для строительных работ',
    dem: <span className="dem-with-cube result-dem">м</span>,
  },
  {
    field: 'sandTransportationTon',
    title: 'Перевозка песка',
    dem: 'тн',
  },
  {
    field: 'preparationCost',
    title: 'Удельная стоимость инженерной подготовки',
    dem: 'тыс. руб',
    multiply: 0.001,
  },
];

interface Props {
  task: ITaskSlice;
  webSocket: IWebSocketSlice;
}

function ResultTab(props: Props) {
  const { task, webSocket } = props;
  const sitePlan = task.sitePlan;

  const textareaRef = useRef<any>();

  const [message, setMessage] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [status, setStatus] = useState('');

  // if (!sitePlan) return null;

  const copyCodeHandler = () => {
    textareaRef.current?.select();
    document.execCommand('copy');
  };

  useEffect(() => {
    if (webSocket.taskTrace?.message) {
      setMessage(webSocket.taskTrace?.message);
    }

    if (webSocket.taskTrace?.percentage) {
      setPercentage(webSocket.taskTrace?.percentage);
    }
  }, [webSocket.taskTrace?.message, webSocket.taskTrace?.percentage]);

  useEffect(() => {
    if (task.status) {
      setStatus(task.status);
    }
  }, [task.status]);

  if (status === 'FAILED') {
    const errorText = webSocket?.taskTrace?.debugInfo as string;

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          alignSelf: 'stretch',
          paddingLeft: '50px',
          paddingRight: '50px',
          height: '100%',
        }}
      >
        <ErrorIcon />
        <div className="error-modal__text-container">
          <p className="error-modal__text text">Ошибка! Что-то пошло не так.</p>
          <p className="error-modal__text text">
            Вы можете{' '}
            <button
              type="button"
              onClick={copyCodeHandler}
              className="error-modal__copy"
            >
              скопировать код ошибки.
            </button>
          </p>
        </div>
        <textarea
          className="error-modal__text-area"
          ref={textareaRef}
          value={errorText}
        />
      </div>
    );
  }

  if (status === 'SOLVING' || !sitePlan) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          alignSelf: 'stretch',
          paddingLeft: '50px',
          paddingRight: '50px',
          height: '100%',
        }}
      >
        <p style={{ fontSize: '14px', lineHeight: '16px', fontWeight: 400 }}>
          {message}
        </p>
        <Divider height="10px" />
        <p>{`${percentage}%`}</p>
      </div>
    );
  }

  return (
    <div className="sidebar__tabs_content__block_content">
      {fields.map((el, key) => {
        const { title, field, dem, multiply = 1, sup } = el;
        const value = sitePlan[field] * multiply;

        return (
          <div
            key={key}
            className="sidebar__result_tab"
            style={{ backgroundColor: key % 2 ? '#222' : '#2F2F2F' }}
          >
            <p className="text result__fields-text">{title}</p>
            <p className="heading-2 result__fields-value no-wrap">
              {formatter.format(value)} <span>{dem}</span>
              {sup && <sup>{sup}</sup>}
            </p>
          </div>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  task: state.task,
  webSocket: state.websocket,
});

export default connect(mapStateToProps)(ResultTab);
