import gql from 'graphql-tag';

export default gql`
  query getAllConnectionsPointTypes {
    allConnectionPointTypes {
      id
      name
      isExternal
    }
  }
`;
