import gql from 'graphql-tag';

export default gql`
  query getAllConnectionTypes {
    allConnectionTypes {
      id
      name
      widthM
      cost
    }
  }
`;
