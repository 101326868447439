import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { updateTask as updateMutation } from '../../../apollo/requests';
import { ReactComponent as EditAreaIcon } from '../../../assets/images/edit-area.svg';
import Divider from '../../../elements/Divider';
import SidebarDropdown from '../../../elements/Dropdowns/SidebarDropdown';
import MathInput from '../../../elements/Inputs/MathInput';
import SimpleLabel from '../../../elements/Labels/SimpleLabel';
import { RootState } from '../../../store/configureStore';
import { setCreateArea, setShowRuler } from '../../../store/mapSlice';
import { updateTaskFields } from '../../../store/taskSlice';
import {
  IDictionariesSlice,
  IMapSlice,
  ITaskSlice,
} from '../../../store/types';

interface Props {
  task: ITaskSlice;
  dictionaries: IDictionariesSlice;
  map: IMapSlice;
}

function ParametersTab(props: Props) {
  const { task, map, dictionaries } = props;
  const { taskId } = useParams<any>();
  const dispatch = useDispatch();
  // const [parametersObj, setParametersObj] = useState<any>({});
  const [projectArea, setProjectArea] = useState<number | null>(0);

  const setSelected = (type: string, value: string | number) => {
    const taskObj = JSON.parse(JSON.stringify(task));
    taskObj[type] = value;
    dispatch(updateTaskFields(taskObj));

    const sendObj = {
      taskId,
      regionId: task.regionId,
      licenseAreaId: task.licenseAreaId,
      demId: task.demId,
      userId: task.userId,
      developmentArea: task.developmentArea,
      name: task.name,
      windRoseAngleDeg: task.windRoseAngleDeg,
    };

    if (taskId) {
      updateMutation({
        taskInput: {
          ...sendObj,
          [type]: value,
        },
      });
    }
  };

  useEffect(() => {
    if (map.projectArea) {
      setProjectArea(map.projectArea);
    }
  }, [map.projectArea]);

  const handleEditArea = () => {
    dispatch(setCreateArea('toggle'));
    dispatch(setShowRuler(false));
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: '0 16px',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Divider height="8px" />
      <SimpleLabel text="Стоимостная модель" />
      <Divider height="8px" />
      <SidebarDropdown
        disabled={task.status !== 'DRAFT'}
        type="regionId"
        selectedValue={task.regionId}
        list={dictionaries.allRegions}
        handleChange={setSelected}
      />
      <Divider height="16px" />
      <SimpleLabel text="Цифровая модель рельефа" />
      <Divider height="8px" />
      <SidebarDropdown
        disabled={task.status !== 'DRAFT'}
        type="demId"
        selectedValue={task.demId}
        list={dictionaries.allDems}
        handleChange={setSelected}
      />
      <Divider height="16px" />
      <SimpleLabel text="Лицензионный участок" />
      <Divider height="8px" />
      <SidebarDropdown
        disabled={task.status !== 'DRAFT'}
        type="licenseAreaId"
        selectedValue={task.licenseAreaId}
        list={dictionaries.allLicenseAreas}
        handleChange={setSelected}
      />
      <Divider height="20px" />
      <SimpleLabel text="Азимут" />
      <Divider height="8px" />
      <MathInput
        width="132px"
        mathSymbol="o"
        disabled={task.status !== 'DRAFT'}
        value={task.windRoseAngleDeg as number}
        handleChange={(e: any) =>
          setSelected('windRoseAngleDeg', e.target.value)
        }
      />
      <Divider height="16px" />
      <SimpleLabel text="Допустимая область" />
      <Divider height="8px" />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <MathInput
          isToFixed
          toFixedValue={3}
          width="132px"
          disabled={task.status !== 'DRAFT'}
          mathValue="км"
          mathSymbol="2"
          value={projectArea as number}
          handleChange={(e: any) => setProjectArea(e.target.value)}
        />
        <button
          type="button"
          disabled={task.status !== 'DRAFT'}
          onClick={handleEditArea}
          className="control-button__area"
          style={{
            backgroundColor: !map.createArea
              ? 'transparent'
              : 'rgb(36, 36, 37)',
          }}
        >
          <EditAreaIcon className="d-inline align-middle" />
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  dictionaries: state.dictionaries,
  task: state.task,
  map: state.map,
});

export default connect(mapStateToProps)(ParametersTab);
