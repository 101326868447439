import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import {
  createTaskCalculation,
  createTaskCalculationByStage,
  deleteTaskCalculation,
  deleteTaskCalculationByStage,
  getScenario,
} from '../../../apollo/requests';
import { ReactComponent as ToggleArrowIcon } from '../../../assets/images/toggle-arrow.svg';
import CalculateCheckbox from '../../../elements/CheckBox/CalculateCheckbox';
import Divider from '../../../elements/Divider';
import CalculateRadioButton from '../../../elements/Radio/CalculateRadioButton';
import { Method, Stage } from '../../../generated/graphql';
import { RootState } from '../../../store/configureStore';
import { updateControl } from '../../../store/controlSlice';
import {
  updateTaskCalculations,
  updateTaskCalculationStatus,
} from '../../../store/taskSlice';
import {
  IControlSlice,
  ITaskSlice,
  IWebSocketSlice,
} from '../../../store/types';

interface Props {
  task: ITaskSlice;
  control: IControlSlice;
  websocket: IWebSocketSlice;
}

function CalculationTab(props: Props) {
  const { websocket, task, control } = props;

  const { taskId } = useParams<any>();
  const dispatch = useDispatch();

  const getData = async () => {
    // TODO временное решение
    const defaultScenarioId = '01010101-0101-0101-0101-010101010101';

    const result = await getScenario(defaultScenarioId);

    if (result) {
      dispatch(updateControl({ scenario: result }));
    }
  };

  const handleCalculationStatuses = () => {
    websocket.taskCalculation?.forEach(item => {
      dispatch(updateTaskCalculationStatus(item));
    });
  };

  useEffect(() => {
    getData();
  }, [taskId]);

  useEffect(() => {
    handleCalculationStatuses();
  }, [websocket.taskCalculation]);

  return (
    <div style={{ width: '100%', overflow: 'auto', flex: 1 }}>
      {control.scenario?.stages?.map((item, i) => (
        <StagesRow
          stage={item as Stage}
          key={i}
          task={task}
          control={control}
        />
      ))}
    </div>
  );
}

interface StageProps {
  task: ITaskSlice;
  stage: Stage;
  control: IControlSlice;
}

function StagesRow(props: StageProps) {
  const { task, stage, control } = props;

  const dispatch = useDispatch();

  const [isOpen, setOpen] = useState<boolean>(false);
  const [selectedMethodsIds, setMethodIdSelected] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState(0);

  const handleSelectAll = async (event: any) => {
    event.stopPropagation();
    if (stage.allowMultipleMethods) {
      if (stage && stage.methods && selectedStatus !== 2) {
        const response = await createTaskCalculationByStage({
          taskId: task.id,
          stageId: stage.id,
        });
        if (
          response &&
          response.taskCalculationMutation?.createTaskCalculationByStage?.record
        ) {
          const prevCalculations: any = JSON.parse(
            JSON.stringify(task.taskCalculations),
          );

          const records =
            response.taskCalculationMutation?.createTaskCalculationByStage
              ?.record;

          const filerCalculations = prevCalculations.filter(
            (item: any) => item.stageId !== stage.id,
          );

          const newArr = filerCalculations.concat(records);

          dispatch(updateTaskCalculations(newArr));
        }
      } else {
        const response = await deleteTaskCalculationByStage({
          taskId: task.id,
          stageId: stage.id,
        });

        if (
          response &&
          response.taskCalculationMutation?.deleteTaskCalculationByStage?.ok
        ) {
          const prevCalculations: any = JSON.parse(
            JSON.stringify(task.taskCalculations),
          );

          const filerCalculations = prevCalculations.filter(
            (item: any) => item.stageId !== stage.id,
          );

          dispatch(updateTaskCalculations(filerCalculations));
        }
      }
    }
  };

  const handleChangeMethod = async (event: any, value: string) => {
    if (stage.allowMultipleMethods) {
      const taskCalculationId = task?.taskCalculations?.find(
        item => item?.methodId === value && item.stageId === stage.id,
      );
      if (taskCalculationId) {
        const response = await deleteTaskCalculation(
          taskCalculationId?.taskCalculationId,
        );

        if (
          response &&
          response.taskCalculationMutation?.deleteTaskCalculation?.ok
        ) {
          const prevCalculations: any = JSON.parse(
            JSON.stringify(task.taskCalculations),
          );

          const findCalculation = prevCalculations.find(
            (item: any) => item.stageId === stage.id && item.methodId === value,
          );

          const filterCalculations = prevCalculations.filter(
            (item: any) => item.calculationId !== findCalculation.calculationId,
          );

          dispatch(updateTaskCalculations(filterCalculations));
        }
      } else {
        const response = await createTaskCalculation({
          taskId: task.id,
          stageId: stage.id,
          methodId: value,
        });

        if (
          response &&
          response.taskCalculationMutation?.createTaskCalculation?.record
        ) {
          const prevCalculations: any = JSON.parse(
            JSON.stringify(task.taskCalculations),
          );

          const record =
            response.taskCalculationMutation?.createTaskCalculation?.record;

          prevCalculations.push(record);

          dispatch(updateTaskCalculations(prevCalculations));
        }
      }
    } else {
      const radioStageMethods = task?.taskCalculations?.find(
        item => item?.stageId === stage.id,
      );

      if (!radioStageMethods) {
        const response = await createTaskCalculation({
          taskId: task.id,
          stageId: stage.id,
          methodId: value,
        });

        if (
          response &&
          response.taskCalculationMutation?.createTaskCalculation?.record
        ) {
          const prevCalculations: any = JSON.parse(
            JSON.stringify(task.taskCalculations),
          );

          const record =
            response.taskCalculationMutation?.createTaskCalculation?.record;

          prevCalculations.push(record);

          dispatch(updateTaskCalculations(prevCalculations));
        }
      } else {
        const taskCalculationId = task?.taskCalculations?.find(
          item => item?.stageId === stage.id,
        );

        const deleteResponse = await deleteTaskCalculation(
          taskCalculationId?.taskCalculationId,
        );

        if (
          deleteResponse &&
          deleteResponse.taskCalculationMutation?.deleteTaskCalculation?.ok
        ) {
          const prevDeleteCalculations: any = JSON.parse(
            JSON.stringify(task.taskCalculations),
          );

          const findCalculation = prevDeleteCalculations.find(
            (item: any) => item.stageId === stage.id,
          );

          const filterCalculations = prevDeleteCalculations.filter(
            (item: any) => item.calculationId !== findCalculation.calculationId,
          );

          dispatch(updateTaskCalculations(filterCalculations));

          const createResponse = await createTaskCalculation({
            taskId: task.id,
            stageId: stage.id,
            methodId: value,
          });

          if (
            createResponse &&
            createResponse.taskCalculationMutation?.createTaskCalculation
              ?.record
          ) {
            const record =
              createResponse.taskCalculationMutation?.createTaskCalculation
                ?.record;

            const newArr = JSON.parse(JSON.stringify(filterCalculations));
            newArr.push(record);

            dispatch(updateTaskCalculations(newArr));
          }
        }
      }
    }
  };

  const getStatusStageColor = () => {
    const methodsStatusArr: any = [];
    let color: any;

    task.taskCalculations?.forEach(item => {
      if (item?.stageId === stage.id) {
        methodsStatusArr.push(item?.status);
      }
    });

    color = 'green';

    if (methodsStatusArr.includes('FAILED')) {
      color = 'red';
    }

    if (methodsStatusArr.includes('RUNNING')) {
      color = 'blue';
    }

    if (methodsStatusArr.every((a: any) => a === 'NEW')) {
      color = 'gray';
    }

    return color;
  };

  const handleTaskMethods = () => {
    const newArr: string[] = [];
    task.taskCalculations?.forEach(item => {
      if (item?.stageId === stage.id) {
        newArr.push(item?.methodId);
      }
    });

    setMethodIdSelected(newArr);

    if (newArr.length) {
      if (newArr.length === stage.methods?.length) {
        setSelectedStatus(2);
      } else {
        setSelectedStatus(1);
      }
    } else {
      setSelectedStatus(0);
    }
  };

  const isRunning = () => {
    const statusesArr: any = [];

    task?.taskCalculations?.forEach(item => {
      if (item?.stageId === stage.id) {
        statusesArr.push(item?.status);
      }
    });

    if (statusesArr.includes('RUNNING')) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    handleTaskMethods();
  }, [task.taskCalculations]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          width: '100%',
          alignSelf: 'stretch',
        }}
      >
        <Status color={getStatusStageColor()} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            alignSelf: 'stretch',
          }}
        >
          <button
            type="button"
            onClick={() => setOpen(!isOpen)}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
              padding: '20px 20px 20px 0',
              // height: '60px',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
              }}
            >
              <Divider width="12px" />
              <CalculateCheckbox
                disabled={task.status !== 'DRAFT'}
                isRunning={isRunning()}
                selectAll={selectedStatus === 2}
                selectSome={selectedStatus === 1}
                handleChange={handleSelectAll}
              />
              <Divider width="12px" />
              <p
                style={{
                  textAlign: 'start',
                  fontSize: '14px',
                  color: '#F0F0F0',
                }}
              >
                {stage.name}
              </p>
            </div>
            <ToggleArrowIcon />
          </button>
        </div>
      </div>
      <Divider isLine />
      {isOpen &&
        stage.methods?.map((item, i) => (
          <MethodRow
            disabled={task.status !== 'DRAFT'}
            method={item as Method}
            key={i}
            task={task}
            methodId={item?.id}
            stage={stage}
            selectedMethodsIds={selectedMethodsIds}
            handleChangeMethod={handleChangeMethod}
          />
        ))}
    </div>
  );
}

interface MethodProps {
  task: ITaskSlice;
  disabled: boolean;
  method: Method;
  stage: Stage;
  selectedMethodsIds: string[];
  methodId: string;
  handleChangeMethod: (e?: any, a?: any) => void;
}

function MethodRow(props: MethodProps) {
  const {
    task,
    disabled,
    method,
    stage,
    selectedMethodsIds,
    methodId,
    handleChangeMethod,
  } = props;

  const getStatusMethodColor = () => {
    let color: any;

    const taskCalculation = task?.taskCalculations?.find(
      item => item?.methodId === method.id,
    );

    if (!taskCalculation) {
      color = 'gray';
    } else if (taskCalculation.status === 'FINISHED') {
      color = 'green';
    } else if (taskCalculation.status === 'NEW') {
      color = 'gray';
    } else if (taskCalculation.status === 'RUNNING') {
      color = 'blue';
    } else {
      color = 'red';
    }

    return color;
  };

  const isRunning = () => {
    const taskStatus = task?.taskCalculations?.find(
      item => item?.stageId === stage.id && item?.methodId === method.id,
    )?.status;

    return taskStatus;
  };

  const handleTextClick = () => {
    const calculationId = task?.taskCalculations?.find(
      item => item?.methodId === method.id && item?.stageId === stage.id,
    )?.calculationId;

    const textArea = document.createElement('textarea');
    textArea.value = calculationId;
    textArea.style.position = 'fixed'; // avoid scrolling to bottom
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');

    document.body.removeChild(textArea);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        alignSelf: 'stretch',
        marginLeft: '34px',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          alignSelf: 'stretch',
          width: '100%',
        }}
      >
        <Status color={getStatusMethodColor()} />
        <Divider width="12px" />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            padding: '20px 20px 20px 0',
          }}
        >
          {stage.allowMultipleMethods ? (
            <CalculateCheckbox
              isRunning={isRunning() === 'RUNNING'}
              disabled={disabled}
              selectAll={selectedMethodsIds.includes(methodId)}
              handleChange={handleChangeMethod}
              arg={methodId}
            />
          ) : (
            <CalculateRadioButton
              isRunning={isRunning() === 'RUNNING'}
              disabled={disabled}
              active={selectedMethodsIds.includes(methodId)}
              handleChange={() => handleChangeMethod(null, methodId)}
            />
          )}
          <Divider width="12px" />
          <p onDoubleClick={handleTextClick}>{method.name}</p>
        </div>
      </div>

      <Divider isLine />
    </div>
  );
}

interface StatusProps {
  color: string;
}

function Status(props: StatusProps) {
  const { color } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignSelf: 'stretch',
        width: '8px',
        // height: '60px',
        backgroundColor: color,
      }}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  task: state.task,
  control: state.control,
  websocket: state.websocket,
});

export default connect(mapStateToProps)(CalculationTab);
