import gql from 'graphql-tag';

export default gql`
  mutation removeBuilding($id: Uuid!) {
    buildingMutation {
      deleteBuilding(buildingId: $id) {
        ok
      }
    }
  }
`;
