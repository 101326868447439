import React, { useEffect, useRef, useState } from 'react';
import ReactMapboxGl from 'react-mapbox-gl';
import { Props as MapProps } from 'react-mapbox-gl/lib/map';
import { Events as MapEvents } from 'react-mapbox-gl/lib/map-events';
import { connect, useDispatch } from 'react-redux';

import { setGeometry } from 'store/mapSlice';

import { RootState } from '../../../store/configureStore';
import { IMapSlice, ITaskSlice } from '../../../store/types';
import { SidebarSliceStateType } from '../../Sidebar/types';
import { layers, sources } from '../style';

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoidW1pYm96dXJvIiwiYSI6ImNpdmh5MzRkZTAwNm8ydG55bHJycHVuNzEifQ.VYG8irr9z7lyzJGGoZQf0A',
});

const containerStyle: React.CSSProperties = {
  height: 'calc(100vh - 56px)',
  width: '100%',
};

interface Props {
  children: JSX.Element[];
  mapProps: Partial<MapProps & MapEvents>;
  task: ITaskSlice;
  sidebar: SidebarSliceStateType;
  map: IMapSlice;
}

function MapContainer(props: Props) {
  const { task, sidebar, map } = props;

  const dispatch = useDispatch();

  const ref = useRef<any>();

  const [mapCenter, setMapCenter] = useState<any>(null);
  const [init, setInit] = useState<boolean>(false);

  useEffect(() => {
    if (task.center) {
      const { coordinates } = JSON.parse(task.center);
      setMapCenter(coordinates);
    }

    if (!task.center && task.isFetching) {
      setMapCenter([76.929, 65.975]);
    }
  }, [task.center, task.isFetching]);

  useEffect(() => {
    if (task.id) {
      setInit(true);
    }
  }, [task.id]);

  /**
   * Пересчитываем размер карты, при скрытии левой панели.
   */
  useEffect(() => {
    ref.current?.state?.map?.resize();
  }, [sidebar.hiddenSidebar]);

  useEffect(() => {
    if (map.geometry) {
      const geometry = JSON.parse(JSON.stringify(map.geometry));
      dispatch(setGeometry(geometry));
    }
  }, [task.licenseAreaId, task.demId]);

  if (!task.licenseAreaId || !task.demId || !mapCenter) return null;

  return (
    <div className="map-container">
      <Map
        {...props.mapProps}
        center={mapCenter}
        zoom={[16]}
        style={{
          version: 8,
          sources: sources(
            task.licenseAreaId,
            task.demId,
            task.id,
            task.status,
          ),
          layers: layers(task.id, task.status),
          glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        }}
        ref={ref}
        containerStyle={containerStyle}
      >
        <>{props.children}</>
      </Map>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  task: state.task,
  sidebar: state.sidebar,
  map: state.map,
});

export default connect(mapStateToProps)(MapContainer);
