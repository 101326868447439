import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../store/configureStore';
import { ITaskSlice } from '../../../store/types';
import ExternalPointsButton from './ExternalPointsButton';
import GridAndAreasHandlers from './GridAndAreasHandlers';
import RulerButton from './RulerButton';

import './style.scss';

interface Props {
  task: ITaskSlice;
}

function ControlButtons(props: Props) {
  const { task } = props;

  return (
    <div className="control-buttons">
      {/* {status === 'SOLVED' && <GridAndAreasHandlers />} */}
      {task.status === 'DRAFT' && <ExternalPointsButton />}
      <RulerButton />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  task: state.task,
});

export default connect(mapStateToProps)(ControlButtons);
