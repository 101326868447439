import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client';
import { split } from '@apollo/client/link/core';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import apiUrl from 'constants/apiUrl';
import subsriptionsUrl from 'constants/subscriptionsUrl';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import calculateTask from './calculateTask';
import cancelTaskCalculation from './cancelTaskCalculation';
import cloneTask from './cloneTask';
import getConnectionsFromBuilding from './connectionsFromBuilding';
import createConnectionBetweenPoints from './createBetweenConnectionPoints';
import createBuilding from './createBuilding';
import createTask from './createTask';
import createTaskCalculation from './createTaskCalculation';
import createTaskCalculationByStage from './createTaskCalculationByStage';
import deleteBuilding from './deleteBuilding';
import deleteConnection from './deleteConnection';
import deleteTask from './deleteTask';
import deleteTaskCalculation from './deleteTaskCalculation';
import deleteTaskCalculationByStage from './deleteTaskCalculationByStage';
import getAllBuildingTypes from './getAllBuildingTypes';
import GetAllConnectionsPointTypes from './getAllConnectionsPointTypes';
import getAllConnectionTypes from './getAllConnectionTypes';
import getBuildingCenter from './getBuildingCenter';
import getBuildingParameters from './getBuildingParameters';
import getCostAreaType from './getCostAreaType';
import getData from './getData';
import getTask from './getTask';
import getTaskCalculation from './getTaskCalculation';
import getTaskLogs from './getTaskLogs';
import getTasks from './getTasks';
import likeTask from './likeTask';
import renameTask from './renameTask';
import getTaskStatus from './taskStatus';
import unlikeTask from './unlikeTask';
import updateBuilding from './updateBuilding';
import updateConnection from './updateConnection';
import updateCoordinates from './updateCoordinates';
import updateTask from './updateTask';

const wsclient = new SubscriptionClient(subsriptionsUrl);

console.log('PROCESS', subsriptionsUrl);

const httpLink = new HttpLink({
  uri: apiUrl,
});

const wsLink = new WebSocketLink(wsclient);

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward, response }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error: any) => {
        console.log('GraphQL error', error);
        console.log(
          `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`,
        );
      });
      // for (const err of graphQLErrors) {
      //   switch (err?.extensions?.code) {
      //     // Apollo Server sets code to UNAUTHENTICATED
      //     // when an AuthenticationError is thrown in a resolver
      //     case 'UNAUTHENTICATED':
      //       // Modify the operation context with a new token
      //       const oldHeaders = operation.getContext().headers;
      //       operation.setContext({
      //         headers: {
      //           ...oldHeaders,
      //           authorization: getNewToken(),
      //         },
      //       });
      //       // Retry the request, returning the new observable
      //       return forward(operation);
      //     default:
      //       break;
      //   }
      // }
    }

    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
    if (operation.operationName === 'IgnoreErrorsQuery' && response) {
      response.errors = undefined;
    }
  },
);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const cache = new InMemoryCache({
  addTypename: false,
});

export const client = new ApolloClient({
  link: from([errorLink, link]),
  cache,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'ignore',
    },
    query: {
      errorPolicy: 'ignore',
    },
    mutate: {
      errorPolicy: 'ignore',
    },
  },
});

export {
  getBuildingCenter,
  getTaskStatus,
  cancelTaskCalculation,
  getTaskCalculation,
  createTaskCalculationByStage,
  deleteTaskCalculationByStage,
  createTaskCalculation,
  deleteTaskCalculation,
  createConnectionBetweenPoints,
  updateConnection,
  cloneTask,
  getData,
  getTask,
  renameTask,
  deleteTask,
  deleteConnection,
  updateTask,
  createTask,
  getTaskLogs,
  getCostAreaType,
  getAllBuildingTypes,
  getAllConnectionTypes,
  GetAllConnectionsPointTypes,
  createBuilding,
  deleteBuilding,
  getTasks,
  updateBuilding,
  calculateTask,
  likeTask,
  unlikeTask,
  // getConnectedBuildings,
  updateCoordinates,
  getConnectionsFromBuilding,
  getBuildingParameters,
};
