import { GetDataQuery } from 'generated/graphql';

export const findLicenseArea = (
  allLicenseAreas: GetDataQuery['allLicenseAreas'],
) =>
  allLicenseAreas!.find(el => el?.name === 'shapefiles') ?? allLicenseAreas![0];

export const findDefaultDem = (allDems: GetDataQuery['allDems']) => {
  const id = allDems!.find(el => el?.demType === 'SRTM')?.id;
  if (id) return id;
  return allDems![0]!.id;
};

export const parseItemPolygon = item => ({
  ...item,
  polygon: JSON.parse(item?.polygon),
});
