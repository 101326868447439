import * as React from 'react';
import { Props as MapProps } from 'react-mapbox-gl/lib/map';

import { CostAreaType } from 'generated/graphql';

export const handleScale = (text: string): string =>
  text.replace('km', 'км').replace('m', 'м');

export const errorHandler = (area: number) => area > 10;

export const handleCostAreaType = (type: CostAreaType) => {
  switch (type) {
    case 'BOG_1_2':
      return 'Болото 1,2 категории';
    case 'BOG_3':
      return 'Болото 3 категории';
    case 'DRY_LAND':
      return 'Суходол';
    case 'FLOODLAND':
      return 'Пойма';
    case 'PERMAFROST':
      return 'ММГ';
    case 'RESTRICTED':
      return 'Запретная для размещения область (озеро, карст и т. д.).';
    default:
      return 'Неизвестный тип области';
  }
};

export const popupTitles = {
  development_area: 'Граница допустимой области',
  corridors: 'Эстакада инженерных коммуникаций',
  utility_lines: 'Инженерные и технологические коммуникации',
  roads: 'Дорога',
  road_lines: 'Дорога',
  barriers: 'Ограждение',
};

export const defaultCenter: [number, number] = [76.929, 65.975];

export const composePopupTitle = (level: string) => {
  if (level === 'SAFE') {
    return (
      <>
        Зона постоянного присутствия людей (1,4 кВт/м
        <sup className="power">2</sup>)
      </>
    );
  }

  if (level === 'DANGEROUS') {
    return (
      <>
        Зона ограждения (2,8 кВт/м<sup className="power">2</sup>)
      </>
    );
  }

  return (
    <>
      Стерильная зона (4,8 кВт/м<sup className="power">2</sup>)
    </>
  );
};
