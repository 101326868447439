import gql from 'graphql-tag';

export default gql`
  mutation renameTask($input: RenameTaskInput!) {
    taskMutation {
      renameTask(input: $input) {
        ok
      }
    }
  }
`;
